import { Flex, Tabs } from "antd";
import styles from "../Ticket/ticket.module.css";
import Header from "../Base/Header";
import LeadTrackingTable from "./LeadTrackingTable";

const LeadTracking = () => {

  const leadTrackingColumn = [
    {
      title: (
        <span style={{ whiteSpace: "nowrap", marginLeft: "10px" }}>
          Sr. No.
        </span>
      ),
      key: "srNo",
      width: "5%",
      render: (_, record) => (
        <span style={{ whiteSpace: "nowrap", marginLeft: "10px" }}>
          {record.srNo}
        </span>
      ),
    },
    {
      title: "Lead Source",
      dataIndex: "leadSource",
      key: "leadSource",
      width: "40%",
    },
    {
      title: "Google Ads",
      dataIndex: "google",
      key: "salesManager",
      width: "10%",
      sorter: {
        compare: (a, b) => a.google - b.google,
      },
    },
    {
      title: "Organic",
      dataIndex: "organic",
      width: "10%",
      sorter: {
        compare: (a, b) => a.organic - b.organic,
      },
    },
    {
      title: "Total",
      dataIndex: "total",
      width: "10%",
      sorter: {
        compare: (a, b) => a.total - b.total,
      },
    },
  ];
  const leadTrackingCountryWiseColumn = [
    {
      title: (
        <span style={{ whiteSpace: "nowrap", marginLeft: "10px" }}>
          Sr. No.
        </span>
      ),
      key: "srNo",
      width: "5%",
      render: (_, record) => (
        <span style={{ whiteSpace: "nowrap", marginLeft: "10px" }}>
          {record.srNo}
        </span>
      ),
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "leadSource",
      width: "40%",
    },
    {
      title: "Google Ads",
      dataIndex: "google",
      key: "salesManager",
      width: "10%",
      sorter: {
        compare: (a, b) => a.google - b.google,
      },
    },
    {
      title: "Organic",
      dataIndex: "organic",
      width: "10%",
      sorter: {
        compare: (a, b) => a.organic - b.organic,
      },
    },
    {
      title: "Total",
      dataIndex: "total",
      width: "10%",
      sorter: {
        compare: (a, b) => a.total - b.total,
      },
    },
  ];
  const items = [
    {
      key: '1',
      label: 'Lead Tracking',
      children: <LeadTrackingTable column={leadTrackingColumn} type="leadTracking" />,
    },
    {
      key: '2',
      label: 'Country wise',
      children: <LeadTrackingTable column={leadTrackingCountryWiseColumn} type="countryLeadTracking" />,
    },
  ];
  return (
    <>
      <Header />
      <section className={styles["blog-section-container"]}>
        <Flex
          style={{
            gap: "15px",
            marginTop: "16px",
            width: "100%",
            marginRight: "16px",
          }}
        >
          <Tabs defaultActiveKey="1" items={items} style={{ width: "100%" }} />
        </Flex>
      </section>
    </>
  );
};

export default LeadTracking;
