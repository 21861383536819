import React, { useState } from "react";
import { Modal, Button, Input, Divider, message, Flex } from "antd";
import axios from "axios";
import { connect } from "react-redux";
const { TextArea } = Input;

const AddSuggestedFeatureModal = ({
  isOpen,
  setIsOpen,
  getSuggestedFeatures,
  authToken,
}) => {
  const [formData, setFormData] = useState({
    name: "",
  });
  const handleCancel = () => {
    setIsOpen(false);
  };

  const onFinish = () => {
    axios
      .post("/suggestedFeature/createSuggestedFeatureSuggestion", formData, {
        headers: {
          Authorization: `Custom ${authToken}`,
        },
      })
      .then(() => {
        setFormData({
          name: "",
        });
        message.success("Feature added successfully");
        setIsOpen(false);
        getSuggestedFeatures();
      });
  };

  return (
    <>
      <Modal
        title="Add Suggested feature"
        visible={isOpen}
        onCancel={handleCancel}
        centered
        footer={[
          <Button key="submit" type="primary" onClick={onFinish}>
            Add
          </Button>,
        ]}
      >
        <Divider style={{ margin: "15px 0" }} />
        <Flex vertical gap={20}>
          <Flex vertical gap={5} label="Feature Name" name="feature">
            <label htmlFor="feature">Feature Name</label>
            <TextArea
              id="feature"
              placeholder="Enter Feature Name...."
              rows={2}
              value={formData.name}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  name: e.target.value,
                })
              }
            />
          </Flex>
        </Flex>
        <Divider style={{ margin: "10px 0" }} />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  authToken: state.auth.token,
});

export default connect(mapStateToProps)(AddSuggestedFeatureModal);
