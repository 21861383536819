import React from 'react'
import Header from '../Base/Header'
import { Flex } from 'antd'
import PaymentTable from './PaymentTable'

const Payment = () => {
  return (
    <>
      <Header />
      <Flex vertical gap={20} style={{ margin: " 40px 40px 40px 105px" }}>
        <PaymentTable />
      </Flex>
    </>
  )
}

export default Payment