import React, { useEffect, useState } from "react";
import { Table, Button, Card, Flex, Select, message, DatePicker, Popconfirm } from "antd";

import axios from "axios";
import { connect } from "react-redux";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const FeaturesRequest = ({ authToken }) => {
  const [featureRequest, setFeatureRequest] = useState([]);
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [filteredFeatureRequest, setFilteredFeatureRequest] = useState([]);
  const getFeatureRequests = async () => {
    setLoading(true);
    const { data } = await axios.get(
      `/suggestedFeature/getAllSuggestedFeaturesList`,
      {
        headers: {
          Authorization: `Custom ${authToken}`,
        },
      },
    );
    setLoading(false);

    setFeatureRequest(
      data?.map((item, index) => ({
        ...item,
        srNo: index + 1,
        labName: item?.lab?.name,
        plan: item?.lab?.plan?.name,
        planId: item?.lab?.plan?._id,
        feature: item?.feature,
      })),
    );
  };

  useEffect(() => {
    getFeatureRequests();
    fetchPlansData();
  }, []);

  useEffect(() => {
    if (selectedPlan === null || selectedPlan === undefined) {
      setFilteredFeatureRequest(featureRequest);
    } else {
      const filtered = featureRequest.filter(
        (feature) => feature.planId === selectedPlan,
      );
      setFilteredFeatureRequest(filtered);
    }
  }, [selectedPlan, featureRequest]);
  
  const handleArchive = async (id) => {
    await axios.post(
      `/suggestedFeature/archiveSuggestedFeature`,
      { id },
      {
        headers: {
          Authorization: `Custom ${authToken}`,
        },
      },
    );
    getFeatureRequests();
  };

  const fetchPlansData = async () => {
    try {
      const response = await axios.get("/admin-dashboard/plans", {
        headers: {
          Authorization: `Custom ${authToken}`,
        },
      });

      const options = response?.data?.map((plan) => ({
        planId: plan._id,
        value: plan.name,
        label: plan.name,
      }));

      setPlans(options);
    } catch (error) {
      message.error("Error fetching plan data", [3]);
    }
  };

  const columns = [
    { title: "Sr. No.", dataIndex: "srNo", width: "7%" },
    {
      title: "Date & Time",
      key: "createdAt",
      dataIndex: "createdAt",
      width: "9%",
      render: (text, record) => (
        <div>
          {dayjs(record?.createdAt).format("DD-MM-YYYY")}
          <br />
          {dayjs(record?.createdAt).format("hh:mm A")}
        </div>
      ),
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: "Lab Name",
      dataIndex: "labName",
      key: "labName",
      width: "10%",
    },
    {
      title: "Features",
      dataIndex: "feature",
      key: "salesManager",
      width: "40%",
    },
    {
      title: "Plans",
      dataIndex: "plan",
      width: "10%",
    },

    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (text, record) => (
        <Flex size="small" gap={10}>
          <Popconfirm
            title="Reject the feature?"
            description="Are you sure you want to reject this feature?"
            onConfirm={() => handleArchive(record._id)}
            onCancel={() => { }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              style={{
                borderRadius: "2px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                padding: "0px 16px 0px 16px",
                alignItems: "center",
              }}
            >
              Reject
            </Button>
          </Popconfirm>
        </Flex>
      ),
    },
  ];

  return (
    <Card
      bodyStyle={{
        padding: "16px 0",
        paddingBottom: "0px",
      }}
      style={{
        border: "1px solid #D9D9D9",
        borderRadius: "4px",
      }}
    >
      <Flex
        justify="space-between"
        style={{ padding: "0px 16px", marginBottom: "16px" }}
      >
        <p style={{ fontSize: "16px", fontWeight: "500" }}>Features List</p>
        <Flex style={{ gap: 10, justifyContent: "flex-start" }}>
          <Select
            placeholder="Select Plan"
            style={{ width: 200, textAlign: "left" }}
            options={plans.map((plan) => ({
              label: plan.label,
              value: plan.planId,
            }))}
            allowClear
            onChange={(value) => setSelectedPlan(value)}
          />
          <RangePicker style={{ width: 240 }} />
        </Flex>
      </Flex>
      <Table
        dataSource={filteredFeatureRequest}
        columns={columns}
        pagination={false}
        loading={loading}
      />
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token,
  };
};

export default connect(mapStateToProps)(FeaturesRequest);
