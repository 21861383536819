import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import axios from "axios";
import store from "./Redux/store";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";

const history = createBrowserHistory();
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.common["Authorization"] = `Custom ${
  localStorage.getItem("tokenv3") === null
    ? ``
    : `${localStorage.getItem("tokenv3")}`
}`;

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App history={history} />
  </Provider>,
);

// GET https://stagingapi.flabs.in/admin-dashboard `query params: plan = <plan id>`
// https://stagingapi.flabs.in/admin-dashboard/plans `gives plan name and its id`
