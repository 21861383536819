import { Select, message } from "antd";
import axios from "axios";
import React from "react";

const MachineIntegrator = ({ machineIntegrators, value, labId, authToken }) => {
    const updateMachineIntegrator = (value) => {
        const config = {
            headers: {
                Authorization: `Custom ${authToken}`,
            },
        };
        axios
            .post(
                "/admin-dashboard/updateMachineIntegrator",
                {
                    machineIntegrator: value,
                    labId: labId,
                },
                config
            )
            .then((res) => {
                message.success("Machine Integrator Updated");
            })
            .catch((err) => {
                message.error("Error Updating Machine Integrator");
            });
    };
    return (
        <div>
            <Select
                value={value}
                style={{ width: 100 }}
                options={machineIntegrators}
                showSearch
                onChange={(value) => updateMachineIntegrator(value)}
            ></Select>
        </div>
    );
};

export default MachineIntegrator;
