import { Select, message } from "antd";
import axios from "axios";
import React from "react";

const LeadSource = ({ leadSources, value, labId, authToken }) => {
  const updateLeadSource = (value) => {
    const config = {
      headers: {
        Authorization: `Custom ${authToken}`,
      },
    };
    axios
      .post(
        "/admin-dashboard/updateLeadSource",
        {
          leadSource: value,
          labId: labId,
        },
        config
      )
      .then((res) => {
        message.success("Lead Source Updated");
      })
      .catch((err) => {
        message.error("Error Updating Lead Source");
      });
  };
  return (
    <div>
      <Select
        value={value}
        style={{ width: 100 }}
        options={leadSources}
        showSearch
        onChange={(value) => updateLeadSource(value)}
      ></Select>
    </div>
  );
};

export default LeadSource;
