import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Flex,
  Input,
  message,
  Popconfirm,
  Popover,
  Table,
  DatePicker,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

import AddLabModal from "./AddLabModal";
import axios from "axios";
const { RangePicker } = DatePicker;
const LinkTable = () => {
  const [tableData, setTableData] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedLab, setSelectedLab] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("add");
  const [searchText, setSearchText] = useState("");
  const [dateRange, setDateRange] = useState([]);

  const dateFilter = async (date, dateString) => {
    if (dateString[0] === "" || dateString[1] === "") {
      setDateRange([]);
      return;
    }
    const localDate = [
      date[0],
      date[1]
    ];
    setDateRange(localDate);
  };


  useEffect(() => {
    handleGetLabLinks();
  }, [dateRange]);

  useEffect(() => {
    handleSearchByLabNameAndContact(searchText);
  }, [searchText, data]);

  const handleGetLabLinks = () => {
    setLoading(true);
    axios.get(`/customLogin/getAll?gte=${dateRange[0]}&lte=${dateRange[1]}`).then((res) => {
      setData(res.data);
      console.log(data)
      setLoading(false);
    }).catch((err) => {
      console.log(err)
    }).finally(() => setLoading(false))

  };

  const handleSearchByLabNameAndContact = (value) => {
    setSearchText(value);
    if (value) {
      const filteredData = data.filter(
        (item) =>
          item?.lab?.name.toLowerCase().includes(value.toLowerCase()) ||
          item?.lab?.contact?.toString().includes(value),
      );
      setTableData(filteredData);
    } else {
      setTableData(data);
    }
  };

  const handleDeleteLab = (id) => {
    setLoading(true)
    axios
      .delete(`/customLogin/delete?id=${id}`)
      .then((res) => {
        if (res.status === 200) {
          message.success("Lab Deleted Successfully");
          handleGetLabLinks();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const getPatientLoginURL = (id, record) => {
    if (!record?.isPatientLogin) {
      return "Patient Login is not enabled"
    }
    if (process.env.REACT_APP_ENVIRONMENT === "staging") {
      return `https://recordstaging.flabs.in/customLogin/${id}`
    } else {
      return `https://records.flabslis.com/customLogin/${id}`
    }
  }

  const getLabLoginURL = (id, record) => {
    if (!record?.isLabLogin) {
      return "Lab Login is not enabled"
    }
    if (process.env.REACT_APP_ENVIRONMENT === "staging") {
      return `https://labstaging.flabs.in/customLogin/${id}`
    } else {
      return `https://labs.flabslis.com/customLogin/${id}`
    }
  }

  // Table Columns
  const columns = [
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "12%",
      render: (value) => (
        <div style={{ whiteSpace: "nowrap" }}>
          <div>{dayjs(value).format("DD-MM-YYYY")}</div>
          <div>{dayjs(value).format("hh:mm A")}</div>
        </div>
      ),
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    },
    {
      title: "Lab Name",
      dataIndex: "lab",
      key: "lab",
      width: "13%",
      render: (value) => (
        <div style={{ whiteSpace: "nowrap" }}>
          <div>{value?.name}</div>
          <div>
            {value?.countryCode === "91" ? "+91" : value?.countryCode}{" "}
            {value.contact}
          </div>
        </div>
      ),
    },
    {
      title: "Patient's Login",
      dataIndex: "lab",
      key: "lab",
      width: "25%",
      render: (value, record) => (
        <CopyToClipboard
          text={getPatientLoginURL(value?._id, record)}
        />
      ),
    },
    {
      title: "Login",
      dataIndex: "lab",
      key: "lab",
      width: "25%",
      render: (value, record) => (
        <CopyToClipboard
          text={getLabLoginURL(value?._id, record)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      render: (_, record) => (
        <Flex gap={10}>
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setModalType("edit");
              setSelectedLab({
                ...record,
                id: record?._id,
              });
              setShowModal(true);
            }}
          >
            Edit
          </Button>
          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this task?"
            onConfirm={() => handleDeleteLab(record?._id)}
            onCancel={() => { }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              icon={<DeleteOutlined />}
              danger
            >
              Delete
            </Button>
          </Popconfirm>
        </Flex>
      ),
    },
  ];

  return (
    <>
      <Flex
        justify="space-between"
        style={{
          padding: "0 20px",
        }}
      >
        <Input
          prefix={<SearchOutlined style={{ color: "#8d8d8d" }} />}
          placeholder="Search Lab name & Contact"
          style={{
            maxWidth: 351,
          }}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <Flex gap={13}>
          <RangePicker
            value={dateRange}
            onChange={dateFilter}
            allowClear={true}
            disabled={loading}
            ranges={{
              Today: [dayjs(), dayjs()],
              "This Week": [dayjs().startOf("week"), dayjs().endOf("week")],
              "This Month": [dayjs().startOf("month"), dayjs().endOf("month")],
            }}
          />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setModalType("add");
              setShowModal(true);
            }}
          >
            Add
          </Button>
        </Flex>
      </Flex>
      <Table
        dataSource={tableData}
        columns={columns}
        style={{
          marginTop: 20,
          borderBottom: "none",
        }}
        rootClassName={`custom-table`}
        className={`custom-table`}
        tableLayout={{
          Headers: {
            borderRadius: 0,
          },
        }}
        pagination={false}
        loading={loading}
      />
      <AddLabModal
        isModalOpen={showModal}
        setIsModalOpen={setShowModal}
        selectedLab={selectedLab}
        type={modalType}
        handleGetLabLinks={handleGetLabLinks}
      />
    </>
  );
};

export default LinkTable;

const CopyToClipboard = ({ text }) => {
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    message.success("Copied to clipboard");
  };
  return (
    <Flex
      style={{
        position: "relative",
        border: "1px solid #D9D9D9",
        padding: "8px 4px",
        borderRadius: "4px",
        paddingLeft: 8,
        maxWidth: 360,
        minWidth: 200,
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
      }}
    >
      <span style={{ color: "#8C8C8C" }}>{text}</span>
      <div
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          background: "#F5F5F5",
          height: "100%",
          width: 28,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderTopRightRadius: "4px",
          borderBottomRightRadius: "4px",
        }}
      >
        <CopyOutlined
          style={{ color: "#8C8C8C" }}
          onClick={() => handleCopy(text)}
        />
      </div>
    </Flex>
  );
};
