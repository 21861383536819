import React, { useEffect, useState } from 'react';
import { Table, Button, DatePicker, Flex } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'
import Header from '../Base/Header';
import styles from './ticket.module.css';
import dayjs from 'dayjs';
import exportToCSV from './helper/ExportToCSV';
import { connect } from 'react-redux';

import { generateDownloadDataLeaderBoard, getLeaderBoard } from './helper/apiService'

const { RangePicker } = DatePicker;

const columns = [
  {
    title: 'Rank',
    dataIndex: 'rank',
    key: 'rank',
    sorter: (a, b) => a.rank - b.rank,
  },
  {
    title: 'Support Person',
    dataIndex: 'supportPerson',
    key: 'supportPerson',
  },
  {
    title: 'Pending',
    dataIndex: 'totalPending',
    key: 'pending',
    sorter: (a, b) => a.totalPending - b.totalPending,
  },
  {
    title: 'In Progress',
    dataIndex: 'totalInProgress',
    key: 'inProcess',
    sorter: (a, b) => a.totalInProgress - b.totalInProgress,
  },
  {
    title: 'Bug/Requirement',
    dataIndex: 'totalBug',
    key: 'bugRequirement',
    sorter: (a, b) => a.totalBug - b.totalBug,
  },
  {
    title: 'Closed',
    dataIndex: 'totalClosed',
    key: 'closed',
    sorter: (a, b) => a.totalClosed - b.totalClosed,
  },
];


const LeaderBoard = () => {
  const navigate = useNavigate()
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false)

  const currentDate = dayjs();
  const startOfDay = dayjs(currentDate)
    .set("hour", 0)
    .set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0);
  const endOfDay = dayjs(currentDate)
    .set("hour", 23)
    .set("minute", 59)
    .set("second", 59)
    .set("millisecond", 999);

  const [dateRange, setDateRange] = useState([startOfDay, endOfDay]);

  const dateFilter = async (date, dateString) => {
    if (dateString[0] === "" || dateString[1] === "") {
      setDateRange([]);
      return;
    }
    const localDate = [
      date[0],
      date[1]
    ];
    setDateRange(localDate);
  };

  useEffect(() => {
    handleGetLeaderBoard()
  }, [dateRange])

  const handleGetLeaderBoard = () => getLeaderBoard(dateRange[0], dateRange[1], setData, setLoading)

  return (
    <>
      <Header />
      <section className={styles['blog-section-container']}
        style={{
          position: 'relative',
        }}>
        <Button onClick={() => navigate('/ticket')}>
          <LeftOutlined /> Back
        </Button>
        <Flex style={{ justifyContent: "space-between", alignItems: "center", marginTop: 20 }}>
          <p style={{ fontSize: "28px", color: "rgba(0,0,0,0.75)", fontWeight: "600" }}>
            Leaderboard
          </p>
          <Flex gap={10}>
            <RangePicker
              value={dateRange}
              onChange={dateFilter}
              allowClear={true}
              ranges={{
                Today: [dayjs(), dayjs()],
                "This Week": [dayjs().startOf("week"), dayjs().endOf("week")],
                "This Month": [dayjs().startOf("month"), dayjs().endOf("month")],
              }}
            />
            <span>
              <Button onClick={() => exportToCSV(generateDownloadDataLeaderBoard(data), "Leaderboard")}>
                Export
              </Button>
            </span>
          </Flex>
        </Flex>
        <Table
          dataSource={data}
          columns={columns}
          pagination={false}
          loading={loading}
          style={{
            border: "1px solid #D9D9D9",
            borderRadius: "4px",
            marginTop: "20px",
          }}
        />
      </section >
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token,
    userRole: state.auth.userRole,
    userId: state.auth.id,
    name: state.auth.name,
    userMainRole: state.auth.userMainRole,
  };
};

export default connect(mapStateToProps)(LeaderBoard);