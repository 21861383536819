import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Input,
  Checkbox,
  Form,
  Flex,
  Divider,
  message,
  Upload,
  Image,
} from "antd";
import axios from "axios";
import { connect } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";

const roles = [
  {
    value: "ADMIN",
    label: "Admin",
  },
  {
    value: "RECEPTION",
    label: "Receptionist",
  },
  {
    value: "TECHNICIAN",
    label: "Technician",
  },
  {
    value: "DOCTOR",
    label: "Doctor",
  },
];

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
    return Upload.LIST_IGNORE; // Prevent upload
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must be smaller than 2MB!');
    return Upload.LIST_IGNORE; // Prevent upload
  }
  return true;
};

const AddUpdateModal = ({ openAddUpdate, setOpenAddUpdate, authToken, fetchUpdateHistory }) => {
  const [formData, setFormData] = useState({
    roles: [],
    units: [],
    plans: [],
  });
  const [plansOptions, setPlansOptions] = useState([]);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const [url, setUrl] = useState('');

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList.slice(-1));

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleUploadFile = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    try {
      // Fetch presigned URL
      const fileType = file.name.split(".").pop();
      const response = await axios.get("/s3Url", {
        params: { fileType, iswebasset: true },
      });

      const { data } = response;

      // Upload to S3 using PUT
      const uploadResponse = await fetch(data.url, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": fileType,
        },
      });

      if (uploadResponse.status === 200) {
        // Construct the URLs after successful upload
        const imageUrl = `https://cdn.flabs.in/webassets/${data.name}.${fileType}`;
        setUrl(imageUrl);
        setFormData({ ...formData, imageUrl });
        onSuccess({ url: imageUrl }, file);
        message.success(`Image uploaded successfully`);
      } else {
        throw new Error('Upload failed');
      }
    } catch (error) {
      console.error("Upload Error:", error);
      message.error(`Upload failed: ${error.message}`);
      onError(error);
    }
  };

  const fetchPlansData = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Custom ${authToken}`,
        },
      };

      const response = await axios.get("/admin-dashboard/plans", config);

      const options = response?.data?.map((plan) => ({
        planId: plan._id,
        value: plan._id,
        label: plan.name,
      }));

      setPlansOptions(options);
    } catch (error) {
      message.error("Error fetching plan data", [3]);
    }
  };

  useEffect(() => {
    fetchPlansData();
  }, []);

  const handleUpdateSubmit = async () => {
    try {
      await axios.post("/software-history/create", formData, {
        headers: {
          Authorization: `Custom ${authToken}`,
        },
      });
      message.success("Update created successfully");
      fetchUpdateHistory();
      setOpenAddUpdate(false);
      setFormData({
        roles: [],
        units: [],
        plans: [],
      });
      setFileList([]);
    } catch (error) {
      console.log("error", error);
      message.error("Error creating update");
    }
  };

  return (
    <>
      <Modal
        title="New Update"
        visible={openAddUpdate}
        onCancel={() => setOpenAddUpdate(false)}
        width={708}
        centered
        footer={[
          <Button key="done" type="primary" onClick={handleUpdateSubmit}>
            Done
          </Button>,
        ]}
      >
        <Divider />
        <Form layout="vertical">
          <Flex gap={20}>
            <Form.Item label="Title" style={{ width: "100%" }}>
              <Input
                placeholder="Title here..."
                style={{ borderRadius: "2px" }}
                value={formData?.title}
                onChange={(e) => {
                  setFormData({ ...formData, title: e.target.value });
                }}
              />
            </Form.Item>
            <Form.Item label="Message" style={{ width: "100%" }}>
              <Input.TextArea
                placeholder="Message here..."
                style={{ borderRadius: "2px" }}
                value={formData?.description}
                onChange={(e) => {
                  setFormData({ ...formData, description: e.target.value });
                }}
              />
            </Form.Item>
          </Flex>
          <Flex gap={20}>
            <Form.Item label="Video link" style={{ width: "100%" }}>
              <Input
                addonBefore="http://"
                placeholder="link here...."
                style={{ borderRadius: "2px" }}
                value={formData?.videoLink}
                onChange={(e) => {
                  setFormData({ ...formData, videoLink: e.target.value });
                }}
              />
            </Form.Item>
            <Form.Item label="Redirection link" style={{ width: "100%" }}>
              <Input
                addonBefore="http://"
                placeholder="link here...."
                style={{ borderRadius: "2px" }}
                value={formData?.redirectionLink}
                onChange={(e) => {
                  setFormData({ ...formData, redirectionLink: e.target.value });
                }}
              />
            </Form.Item>
          </Flex>
          <Flex gap={20} justify="space-between">
            <Form.Item label="Role-wise" style={{ width: "100%" }}>
              <Checkbox.Group
                onChange={(value) => setFormData({ ...formData, roles: value })}
                value={formData.roles}
              >
                {roles.map((role) => (
                  <Checkbox value={role.value} style={{ marginBottom: 8 }}>
                    {role.label}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
            <Form.Item label="Units" style={{ width: "100%" }}>
              <Checkbox.Group
                onChange={(value) => setFormData({ ...formData, units: value })}
                value={formData.units}
              >
                <Checkbox value="B2B">B2B</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Flex>
          <Form.Item label="Plans" style={{ width: "100%" }}>
            <Checkbox.Group
              onChange={(value) => setFormData({ ...formData, plans: value })}
              value={formData.plans}
            >
              {plansOptions.map((plan) => (
                <Checkbox value={plan.value} style={{ marginBottom: 8 }}>
                  {plan.label}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
          <Form.Item label="Upload Image" style={{ width: "100%" }}>
            <div>
              <Upload
                customRequest={handleUploadFile}
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                beforeUpload={beforeUpload}
                maxCount={1}
                accept='.jpg,.jpeg,.png'
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
              {previewImage && (
                <Image
                  wrapperStyle={{
                    display: 'none',
                  }}
                  preview={{
                    visible: previewOpen,
                    onVisibleChange: (visible) => setPreviewOpen(visible),
                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                  }}
                  src={previewImage}
                />
              )}
            </div>
          </Form.Item>
        </Form>
        <Divider style={{ marginBottom: "5px" }} />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token,
    userRole: state.auth.userRole,
  };
};

export default connect(mapStateToProps)(AddUpdateModal);
