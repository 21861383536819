import React, { useState } from "react";
import {
  Modal,
  Button,
  Input,
  Select,
  Form,
  Divider,
  message,
  Flex,
} from "antd";
import axios from "axios";
import { connect } from "react-redux";
const { TextArea } = Input;
const { Option } = Select;

const AddFeatureModal = ({
  onGoingFeaturesModal,
  setOnGoingFeaturesModal,
  plans,
  getSuggestedFeatures,
  authToken,
}) => {
  const [formData, setFormData] = useState({
    feature: "",
    plans: [],
  });
  const handleCancel = () => {
    setOnGoingFeaturesModal(false);
  };
  const [form] = Form.useForm();
  // console.log("plans", plans);
  const onFinish = () => {
    axios
      .post("/ongoingFeature/createOngoingFeature", formData, {
        headers: {
          Authorization: `Custom ${authToken}`,
        },
      })
      .then(() => {
        setFormData({});
        message.success("Feature added successfully");
        setOnGoingFeaturesModal(false);
        getSuggestedFeatures();
        form.resetFields();
      });
  };

  return (
    <>
      <Modal
        title="Add ongoing feature"
        visible={onGoingFeaturesModal}
        onCancel={handleCancel}
        centered
        footer={[
          <Form>
            <Button key="submit" type="primary" onClick={onFinish}>
              Add
            </Button>
          </Form>,
        ]}
      >
        <Divider style={{ margin: "15px 0" }} />
        <Flex vertical gap={20}>
          <Flex vertical gap={5} label="Feature Name" name="feature">
            <label htmlFor="feature">Feature</label>
            <TextArea
              id="feature"
              placeholder="Enter Feature Name...."
              rows={2}
              value={formData.feature}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  feature: e.target.value,
                })
              }
            />
          </Flex>
          <Flex vertical gap={5}>
            <label htmlFor="plan">Plans</label>
            <Select
              placeholder="Select a plan"
              mode="multiple"
              allowClear
              options={plans.map((plan) => ({
                label: plan.label,
                value: plan.planId,
              }))}
              value={formData.plans}
              onChange={(value) =>
                setFormData({
                  ...formData,
                  plans: value,
                })
              }
            />
          </Flex>
        </Flex>
        <Divider style={{ margin: "10px 0" }} />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  authToken: state.auth.token,
});

export default connect(mapStateToProps)(AddFeatureModal);
