import React, { useEffect, useState } from "react";
import {
  Input,
  Select,
  Table,
  Space,
  Button,
  message,
  Popconfirm,
  Modal,
  Form,
  Divider,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import styles from "./Blogs.module.css";
import axios from "axios";

const BlogCategory = () => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [currentCategories, setCurrentCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [search, setSearch] = useState("");
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (search.length === 0) setCurrentCategories(categories);
  }, [search]);

  // TABLE COLUMNS
  const columns = [
    {
      title: "Sr No.",
      dataIndex: "srNo",
      key: "srNo",
      width: "9%",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "40%",
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Space size="small">
            <Button
              size="small"
              style={{
                border: "1px solid #4678EE",
                fontWeight: "500",
                color: "#4678EE",
              }}
              onClick={() => fetchSingleCategory(record.key)}
            >
              <EditOutlined />
              Edit
            </Button>
            <Popconfirm
              description="Are you sure to delete this task?"
              onConfirm={() => handleBlogCategoryDelete(record.key)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                danger
                size="small"
                style={{
                  fontWeight: "500",
                }}
              >
                <DeleteOutlined />
                Delete
              </Button>
            </Popconfirm>
          </Space>
        );
      },
      width: "15%",
    },
  ];

  const fetchCategories = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`/blog-category/all`);
      setCategories(data);
      setCurrentCategories(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const categoriesTableData = currentCategories.map((item, i) => {
    return {
      key: item._id,
      srNo: i + 1,
      title: item?.title,
      position: item?.position,
      slug: item?.slug,
    };
  });

  const handleAddCategory = async (values) => {
    try {
      axios.post("/blog-category/add", values);
      setIsModalOpen(false);
      form.resetFields();
      message.success("Category Created!");
      setTimeout(() => {
        fetchCategories();
      }, "1000");
    } catch (error) {
      console.log(error);
    }
  };

  const handleBlogCategoryDelete = async (id) => {
    try {
      const res = await axios.delete(`blog-category/delete/${id}`);
      if (res.status === 200) {
        message.success("Category Deleted Successfully!");
      } else {
        message.error("Something went wrong!");
      }
      setTimeout(() => {
        fetchCategories();
      }, "500");
    } catch (error) {
      console.log(error);
    }
  };

  const handleCategorySearch = (e) => {
    try {
      setLoading(true);
      const result = categories?.filter((item) => {
        return item.title.toLowerCase().includes(search.toLowerCase());
      });
      setCurrentCategories(result);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSingleCategory = async (id) => {
    try {
      const res = await axios.get(`/blog-category/get/${id}`);
      setSelectedCategory(res?.data);
      editForm.setFieldsValue({
        title: res?.data?.title,
        slug: res?.data?.slug,
        position: res?.data?.position,
      });
      setEditModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditCategory = async (values) => {
    try {
      axios.put(`/blog-category/update/${selectedCategory?._id}`, values);
      setEditModal(false);
      editForm.resetFields();
      setSelectedCategory({});
      message.success("Category Edited!");
      setTimeout(() => {
        fetchCategories();
      }, "1000");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className={styles["blog-header-container"]}>
        <div>
          <Input.Search
            allowClear={true}
            placeholder="Search by Blog Category"
            style={{
              width: 350,
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={handleCategorySearch}
          />
        </div>
        <Button type="primary" onClick={() => setIsModalOpen(true)}>
          <PlusOutlined />
          Add Category
        </Button>
      </div>
      <div className={styles["blog-table-container"]}>
        <Table
          dataSource={categoriesTableData}
          columns={columns}
          loading={loading}
          pagination={false}
        />
      </div>

      {/* ADD CATEGORY MODAL */}
      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        title={<span style={{ fontWeight: "400" }}>Create Category</span>}
        footer={null}
      >
        <Divider />
        <Form
          form={form}
          onFinish={handleAddCategory}
          layout="vertical"
          preserve={false}
        >
          <Form.Item label="Title" name="title">
            <Input />
          </Form.Item>
          <Form.Item label="Slug" name="slug">
            <Input />
          </Form.Item>
          <Form.Item label="Position" name="position">
            <Input />
          </Form.Item>
          <Divider />
          <div style={{ textAlign: "right" }}>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </div>
        </Form>
      </Modal>

      {/* EDIT CATEGORY MODAL */}
      <Modal
        open={editModal}
        onCancel={() => setEditModal(false)}
        title={<span style={{ fontWeight: "400" }}>Edit Category</span>}
        footer={null}
      >
        <Divider />
        <Form
          form={editForm}
          onFinish={handleEditCategory}
          layout="vertical"
          preserve={false}
        >
          <Form.Item label="Title" name="title">
            <Input />
          </Form.Item>
          <Form.Item label="Slug" name="slug">
            <Input />
          </Form.Item>
          <Form.Item label="Position" name="position">
            <Input />
          </Form.Item>
          <Divider />
          <div style={{ textAlign: "right" }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default BlogCategory;
