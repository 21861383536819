import React from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const DateRangePicker = ({ dataRange, onDateChange, ranges, width }) => {
    const handleDateChange = (date, dateString) => {
        const localDate = [
            dayjs(new Date(dateString[0]).setHours(0, 0, 0)),
            dayjs(new Date(dateString[1]).setHours(23, 59, 59)),
        ];
        onDateChange(localDate);
    };

    return (
        <RangePicker
            value={dataRange}
            onChange={handleDateChange}
            allowClear={false}
            ranges={ranges}
            style={{
                width: `${width}`,
            }}
        />
    );
};

DateRangePicker.defaultProps = {
    ranges: {
        Today: [dayjs(), dayjs()],
        Yesterday: [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')],
        'This Week': [dayjs().startOf('week'), dayjs().endOf('week')],
        'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
    },
    width: '220px',
};

export default DateRangePicker;
