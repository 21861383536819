import React, { useEffect, useState } from 'react'
import {
    Button,
    Modal,
    Divider,
    Form,
    Input,
    Checkbox,
    message,
} from "antd";
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';

const IntegrationModal = ({ openInteg, setOpenInteg, authToken, data, fetchData }) => {
    const [form] = useForm();

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                accountId: data?.razorpayAccountId !== "acc_" ? data?.razorpayAccountId : "",
                transactionCharge: data?.deductRazorpayFee || "3",
                onHoldUntil: data?.onHoldUntil || "2",
                onHold: data?.onHold || false,
            });
        }
    }, [data, form]);

    const handleIntegration = (values) => {
        const obj = {
            lab: openInteg?.lab,
            razorpayAccountId: values?.accountId,
            onHold: values?.onHold,
            onHoldUntil: values?.onHoldUntil,
            deductRazorpayFee: values?.transactionCharge,
            id: openInteg?.id,
        }
        form
            .validateFields()
            .then(() => {
                axios.post('/razorpay/addRazorpayDetails', obj,
                    {
                        headers: {
                            Authorization: `Custom ${authToken}`,
                        },
                    },
                ).then((res) => {
                    setOpenInteg(null);
                    fetchData();
                    form.resetFields();
                    message.success('bank details Integrated Successfully');
                }).catch((err) => {
                    message.error('Failed to Integrate bank details');
                })
            })
            .catch((error) => console.log({ error }));
    };

    return (
        <Modal
            title={"Integration"}
            visible={openInteg}
            onCancel={() => setOpenInteg(null)}
            width={448}
            footer={false}
            centered
        >
            <Divider style={{ margin: "15px 0" }} />
            <Form
                layout="vertical"
                onFinish={handleIntegration}
                form={form}
            >
                <Form.Item
                    name="accountId"
                    label="Account Id"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Account Id!',
                        },
                    ]}
                >
                    <Input placeholder="Type here" />
                </Form.Item>
                <Form.Item
                    name="transactionCharge"
                    label="Transaction Charge(%)"
                    rules={[
                        {
                            validator: (_, value) =>
                                !value
                                    ? Promise.reject('Value can’t be less than 2.25.')
                                    : value >= 2.25
                                        ? Promise.resolve()
                                        : Promise.reject('Value can’t be less than 2.25.'),
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="onHoldUntil"
                    label="On Hold Until"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your On Hold Until!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="onHold"
                    valuePropName="checked"
                >
                    <Checkbox>On Hold</Checkbox>
                </Form.Item>
                <Divider style={{ margin: "10px 0" }} />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        type="primary"
                        size="medium"
                        htmlType="submit"
                    >
                        Submit
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export default IntegrationModal;
