import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Table,
  Select,
  DatePicker,
  Divider,
  Flex,
  Dropdown,
  Space,
  Menu,
} from "antd";
import { DownOutlined, FilePdfOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import axios from "axios";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
const { RangePicker } = DatePicker;

const columns = [
  {
    title: "Date & Time",
    dataIndex: "dateTime",
    key: "dateTime",
    render: (text, record) => (
      <div>{dayjs(text).format("DD-MM-YYYY hh:mm A")}</div>
    ),
  },
  {
    title: "Lab Name",
    dataIndex: "labName",
    key: "labName",
  },
  {
    title: "Plans",
    dataIndex: "plan",
    key: "plans",
  },
  {
    title: "Contact",
    dataIndex: "contact",
    key: "contact",
  },
  {
    title: "Income",
    dataIndex: "income",
    key: "income",
  },
  {
    title: "Bills",
    dataIndex: "bills",
    key: "bills",
  },
];

const OnGoingFeatureLabListModal = ({
  visible,
  setVisible,
  plans,
  authToken,
  selectedOnGoingFeature,
}) => {
  const [newData, setNewData] = useState([]);
  const [data, setData] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const currentDate = dayjs();
  const firstDayOfMonth = dayjs(currentDate)
    .startOf("month")
    .set("hour", 0)
    .set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0);
  const lastDayOfMonth = dayjs(currentDate)
    .set("hour", 23)
    .set("minute", 59)
    .set("second", 59)
    .set("millisecond", 999);

  const [dateRange, setDateRange] = useState([firstDayOfMonth, lastDayOfMonth]);

  const dateFilter = async (date, dateString) => {
    if (dateString[0] === "" || dateString[1] === "") {
      setDateRange([]);
      return;
    }
    const localDate = [
      date[0],
      date[1]
    ];
    setDateRange(localDate);
  };

  const getOngoingFeatureLabList = async () => {
    setLoading(true);
    const { data } = await axios.get(
      `/ongoingFeature/getAdminOngoingFeatureLabList?featureId=${selectedOnGoingFeature?._id}&lte=${dateRange[1]}&gte=${dateRange[0]}`,
      {
        headers: { Authorization: `Custom ${authToken}` },
      },
    );
    setLoading(false);
    const formattedData = data?.map((item, index) => {
      return {
        ...item,
        srNo: index + 1,
        labName: item?.lab?.name,
        plan: item?.lab?.planName,
        planId: item?.lab?.plan,
        contact: item?.lab?.labAdminID?.contact,
        createAt: item?.createAt,
        income: item?.lab?.income30Days,
        bills: item?.lab?.billsCount,
      };
    });
    setData(formattedData);
    setNewData(formattedData);
  };

  useEffect(() => {
    if (visible) {
      getOngoingFeatureLabList();
    }
  }, [selectedOnGoingFeature, dateRange, visible]);

  useEffect(() => {
    if (selectedPlan === null || selectedPlan === undefined) {
      setData(newData);
    } else {
      const filteredData = newData.filter(
        (item) => item?.lab?.plan === selectedPlan,
      );
      setData(filteredData);
    }
  }, [selectedPlan, data, newData]);

  const exportData = data?.map((item, index) => {
    return {
      "Sr No.": index + 1,
      "Date&Time": `${dayjs(item.createAt).format("DD-MM-YYYY hh:mm A")}`,
      "Lab Name": item.labName,
      Plans: item.plan,
      Contact: `${item.contact}`,
      "Income": item?.lab?.income30Days,
      "Bills": item?.lab?.billsCount,
    };
  });

  const exportToCSV = (csvData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <div>
      <Modal
        title={selectedOnGoingFeature?.feature}
        visible={visible}
        onCancel={() => setVisible(false)}
        width={704}
        footer={null}
        centered
      >
        <Divider style={{ margin: "15px 0" }} />
        <div
          style={{
            marginBottom: 16,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Flex gap={15}>
            <Select
              placeholder="Select Plan"
              style={{ width: 210 }}
              options={plans.map((item) => ({
                label: item.label,
                value: item.planId,
              }))}
              onChange={(value) => setSelectedPlan(value)}
              allowClear
            />
            <RangePicker
              style={{ width: 256 }}
              onChange={dateFilter}
              value={dateRange}
            />
          </Flex>
          <Button
            onClick={() =>
              exportToCSV(exportData, selectedOnGoingFeature?.feature)
            }
          >
            Export
          </Button>
        </div>
        <Table
          dataSource={data}
          columns={columns}
          pagination={false}
          loading={loading}
        />
      </Modal>
    </div>
  );
};

export default OnGoingFeatureLabListModal;
