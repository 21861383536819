import React, { useEffect, useState } from "react";
import Editor from "@monaco-editor/react";
import { Flex } from "antd";

const editorOptions = {
  acceptSuggestionOnCommitCharacter: true,
  acceptSuggestionOnEnter: "on",
  accessibilitySupport: "auto",
  autoIndent: false,
  automaticLayout: true,
  codeLens: true,
  colorDecorators: true,
  contextmenu: true,
  cursorBlinking: "blink",
  cursorSmoothCaretAnimation: false,
  cursorStyle: "line",
  disableLayerHinting: false,
  disableMonospaceOptimizations: false,
  dragAndDrop: false,
  fixedOverflowWidgets: false,
  folding: true,
  foldingStrategy: "auto",
  fontLigatures: false,
  formatOnPaste: false,
  formatOnType: false,
  hideCursorInOverviewRuler: false,
  highlightActiveIndentGuide: true,
  links: true,
  mouseWheelZoom: false,
  multiCursorMergeOverlapping: true,
  multiCursorModifier: "alt",
  overviewRulerBorder: true,
  overviewRulerLanes: 2,
  quickSuggestions: true,
  quickSuggestionsDelay: 100,
  readOnly: false,
  renderControlCharacters: false,
  renderFinalNewline: true,
  renderIndentGuides: true,
  renderLineHighlight: "all",
  renderWhitespace: "none",
  revealHorizontalRightPadding: 30,
  roundedSelection: true,
  rulers: [],
  scrollBeyondLastColumn: 5,
  scrollBeyondLastLine: true,
  selectOnLineNumbers: true,
  selectionClipboard: true,
  selectionHighlight: true,
  showFoldingControls: "mouseover",
  smoothScrolling: false,
  suggestOnTriggerCharacters: true,
  wordBasedSuggestions: true,
  wordSeparators: "~!@#$%^&*()-=+[{]}|;:'\",.<>/?",
  wordWrap: "off",
  wordWrapBreakAfterCharacters: "\t})]?|&,;",
  wordWrapBreakBeforeCharacters: "{([+",
  wordWrapBreakObtrusiveCharacters: ".",
  wordWrapColumn: 80,
  wordWrapMinified: true,
  wrappingIndent: "none",
};

const SmartReportEditor = ({ mappingData, setMappingData }) => {
  const [code, setCode] = useState("");

  useEffect(() => {
    if (mappingData && !code) {
      setCode(JSON.stringify(mappingData, null, 2));
    }
  }, [mappingData]);

  const handleEditorChange = (newValue) => {
    setCode(newValue);
    try {
      const parsedValue = JSON.parse(newValue);
      setMappingData(parsedValue);
    } catch (error) {
      console.error("Invalid JSON:", error);
    }
  };

  return (
    <Flex
      style={{
        border: "8px solid #F5F5F5",
        borderRadius: 2,
        padding: 10,
      }}
      gap={20}
      vertical
    >
      <Editor
        defaultLanguage="json"
        value={code}
        height="80vh"
        options={editorOptions}
        onChange={handleEditorChange}
      />
    </Flex>
  );
};

export default SmartReportEditor;
