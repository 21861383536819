import React, { useState } from "react";
import Header from "../Base/Header";
import styles from "./labsLink.module.css";
import { Card, Flex, Input, DatePicker, Button } from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import LinkTable from "./LinkTable";
import AddLabModal from "./AddLabModal";

const { RangePicker } = DatePicker;

const LabLink = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Header />
      <section
        className={styles["blog-section-container"]}
        style={{
          position: "relative",
        }}
      >
        <span className={styles["blog-section-title"]}>Labs Link</span>
        <Card
          bodyStyle={{
            padding: "20px 0",
            paddingBottom: 0,
          }}
          style={{
            marginTop: "20px",
            border: "1px solid  #D9D9D9",
            borderRadius: "4px",
          }}
        >
         
          <LinkTable />
          <AddLabModal
            isModalOpen={showModal}
            setIsModalOpen={setShowModal}
          />
        </Card>
      </section>
    </>
  );
};

export default LabLink;
