import React from "react";
import LabAnalytics from "./LabAnalytics";
import LabInfoTable from "./LabInfoTable";
import Header from "../Base/Header";
import { connect } from "react-redux";

const Dashboard = ({ userRole, userMainRole }) => {
  return (
    <>
      <Header />
      {userRole === "admin" || (userRole === "user" && userMainRole === "product") ? <LabAnalytics /> : null}
      <LabInfoTable />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userRole: state.auth.userRole,
    userMainRole: state.auth.userMainRole,
  };
};

export default connect(mapStateToProps)(Dashboard);
