import React from "react";
import { connect, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { defaultRoute } from "./defaultRoute";

const AdminRoute = ({ element: Element }) => {
  const userRole = useSelector((state) => state.auth.userRole);

  return userRole === "admin" || userRole === "seo" ? (
    <Outlet />
  ) : (
    <Navigate to={defaultRoute[userRole]} />
  );
};

const mapStateToProps = (state) => ({
  userRole: state.auth.userRole,
});

export default connect(mapStateToProps)(AdminRoute);
