import React, { useState, useEffect } from "react";
import "./../style.css";
import axios from "axios";
import { connect } from "react-redux";
import LabDataCards from "./LabDataCards";
import { Card } from "antd";

const labAnalyticsData = [
  {
    subscriptionPlan: "Basic",
    labQuantity: 0,
    percentage: 0,
    color: "#1C82E1",
  },
  {
    subscriptionPlan: "Pro",
    labQuantity: 0,
    percentage: 0,
    color: "#50DCAA",
  },
  {
    subscriptionPlan: "Enterprise",
    labQuantity: 0,
    percentage: 0,
    color: "#FAAD14",
  },
  {
    subscriptionPlan: "Free Trial",
    labQuantity: 0,
    percentage: 0,
    color: "#F5284A",
  },
  {
    subscriptionPlan: "Churn",
    labQuantity: 0,
    percentage: 0,
    color: "#753DC2",
  },
];

const LabAnalytics = ({ authToken }) => {
  const [labStats, setLabStats] = useState([]);

  useEffect(() => {
    fetchLabStats();
  }, []);

  const fetchLabStats = async () => {
    try {
      // const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Custom ${authToken}`,
        },
      };
      const response = await axios.get("/admin-dashboard/overAllStats", config);
      setLabStats(response.data);
      console.log(labStats);
    } catch (error) {
      console.log("Error fetching Lab stats data:", error);
    }
  };

  // Find the index and count of the "Total" element
  const totalIndex = labStats?.findIndex((item) => item.name === "Total");
  const totalPaidLabsIndex = labStats?.findIndex(
    (item) => item.name === "Total Paid",
  );
  const totalCount = labStats[totalIndex]?.count;
  const totalPaidLabs = labStats[totalPaidLabsIndex]?.count;

  labStats?.forEach((element, index) => {
    if (index !== totalIndex) {
      const matchingData = labAnalyticsData.find((data) => {
        if (data.subscriptionPlan === element.name) return true;
        return false;
      });
      if (matchingData) {
        const updatedPercentage = Math.round(
          (element.count * 100) / totalCount,
        );
        matchingData.percentage = updatedPercentage;
        matchingData.labQuantity = element.count;
      }
    }
  });

  return (
    <>
      <section className="lab_analytics_container">
        <div className="total__labs">
          <p className="bold_text">Total Labs</p>
          <h2 className="lab_quantity">{totalPaidLabs}</h2>
        </div>
        <div className="lab_subscription_container">
          <LabDataCards key={1} labData={labAnalyticsData[0]} />
          <div className="separation__line"></div>
          <LabDataCards key={2} labData={labAnalyticsData[1]} />
          <div className="separation__line"></div>
          <LabDataCards key={3} labData={labAnalyticsData[2]} />
          <div className="separation__line"></div>
          <LabDataCards key={4} labData={labAnalyticsData[3]} />
          <div className="separation__line"></div>
          <LabDataCards key={4} labData={labAnalyticsData[4]} />
          {/* <div className="separation__line"></div>
        <LabDataCards key={5} labData={labAnalyticsData[4]} /> */}
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token,
  };
};

export default connect(mapStateToProps)(LabAnalytics);
