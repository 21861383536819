import React, { useState } from 'react';
import { Button, Divider, Flex, Input, message, Modal } from 'antd';
import axios from 'axios';

const B2bPaymentModal = ({ isModalOpen, setIsModalOpen, authToken, labId }) => {
    const [formData, setFormData] = useState({
        numberOfB2bLogins: null,
        months: null,
        b2bPaidAmount: null
    });

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = () => {
        console.log(formData)
        let data = {
            labID: labId,
            ...formData,
        }
        if (!formData.numberOfB2bLogins || !formData.months || !formData.b2bPaidAmount) {
            return message.error('Please fill all the fields')
        }
        axios.post(`admin-dashboard/updateB2BSetting`, data,
            {
                headers: {
                    Authorization: `Custom ${authToken}`,
                }
            }
        ).then((res) => {
            setFormData({
                numberOfB2bLogins: null,
                months: null,
                b2bPaidAmount: null
            });
            message.success('B2B Payment updated successfully');
            setIsModalOpen(false);
        }).catch((err) => {
            message.error('Failed to update B2B Payment');
        })
    }

    return (
        <Modal
            title="B2B Payment"
            open={isModalOpen}
            onCancel={handleCancel}
            centered
            width={400}
            footer={[
                <Button key="submit" type="primary" onClick={() => handleSubmit()}>
                    Submit
                </Button>,
            ]}
        >
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            <Flex vertical gap={15}>
                <Flex vertical gap={8}>
                    <p><span style={{ color: "red" }}>*</span> Number of logins</p>
                    <Input
                        placeholder="Enter number of logins"
                        onChange={(e) => setFormData({ ...formData, numberOfB2bLogins: parseInt(e.target.value) })}
                        value={formData?.numberOfB2bLogins}
                        type='number'

                    />
                </Flex>
                <Flex vertical gap={8}>
                    <p><span style={{ color: "red" }}>*</span> Duration</p>
                    <Input
                        placeholder="Enter duration"
                        onChange={(e) => setFormData({ ...formData, months: parseInt(e.target.value) })}
                        value={formData?.months}
                        type='number'
                    />
                </Flex>
                <Flex vertical gap={8}>
                    <p><span style={{ color: "red" }}>*</span> Paid Amount</p>
                    <Input
                        placeholder="Enter amount"
                        onChange={(e) => setFormData({ ...formData, b2bPaidAmount: parseInt(e.target.value) })}
                        value={formData?.b2bPaidAmount}
                        type='number'
                    />
                </Flex>
            </Flex>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        </Modal>
    )
}

export default B2bPaymentModal