import React, { useRef, useState } from "react";
import Header from "../Base/Header";
import styles from "./Blogs.module.css";
import {
  Tabs,
  Button,
  Modal,
  Upload,
  Divider,
  message,
  Flex,
  Input,
} from "antd";
import BlogPost from "./BlogPost";
import { UploadOutlined, InboxOutlined } from "@ant-design/icons";
import BlogCategory from "./BlogCategory";
import axios from "axios";

const Blogs = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [url, setUrl] = useState(null);
  const [urlReport, setUrlReport] = useState(null);
  const inputRef = useRef();
  const inputRef2 = useRef();

  const tabItems = [
    {
      key: "1",
      label: "Blog Post",
      children: <BlogPost />,
    },
    {
      key: "2",
      label: "Blog Category",
      children: <BlogCategory />,
    },
  ];

  const handleUploadFile = async (file) => {
    const fileType = file?.name?.split(".")?.pop();
    const url = await axios.get(
      "/s3Url?fileType=" + fileType + "&iswebasset=true"
    );
    const { data } = url;
    await fetch(data.url, {
      method: "PUT",
      headers: {
        "Content-Type": file?.type,
      },
      body: file,
    });
    const imageUrl =
      "https://cdn.flabs.in/webassets/" + data.name + "." + fileType;
    setUrl(imageUrl);
    setUrlReport(
      "https://flabs-prod.s3.ap-south-1.amazonaws.com/webassets/" +
        data.name +
        "." +
        fileType
    );
  };

  const handleCopy = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand("copy");
      document.getSelection().removeAllRanges();
      message.success("Copied to clipboard");
    }
  };

  const handleCopy1 = () => {
    if (inputRef2.current) {
      inputRef2.current.select();
      document.execCommand("copy");
      document.getSelection().removeAllRanges();
      message.success("Copied to clipboard");
    }
  };

  const uploadProps = {
    name: "file",
    multiple: false,
    beforeUpload: (file) => {
      const isPNG =
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg";
      if (!isPNG) {
        setUploadedFile(null);
        message.error(`${file.name} is not an image`);
      } else {
        setUploadedFile(file);
      }
      return false;
    },
    onRemove: () => {
      setUploadedFile(null);
      setUrl(false);
    },
    maxCount: 1,
  };

  return (
    <>
      <Header />
      <section className={styles["blog-section-container"]}>
        <Button
          style={{
            border: "1px solid #4678EE",
            color: "#4678EE",
          }}
          className={styles["upload-image-button"]}
          onClick={() => setIsModalOpen(true)}
        >
          <UploadOutlined />
          Upload Image
        </Button>
        <Tabs
          defaultActiveKey="1"
          items={tabItems}
          indicatorSize={(origin) => origin + 20}
          tabBarStyle={{
            width: "220px",
          }}
          centered
        />
      </section>
      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        title={<span style={{ fontWeight: "400" }}>Upload Image to CDN</span>}
        footer={null}
      >
        <Divider />
        <Upload.Dragger
          {...uploadProps}
          style={{
            marginBottom: "12px",
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Upload.Dragger>
        <Divider />
        {!url ? (
          <div style={{ textAlign: "right" }}>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                if (uploadedFile) {
                  handleUploadFile(uploadedFile);
                } else {
                  message.warning("Please upload image first!");
                }
              }}
            >
              Upload
            </Button>
          </div>
        ) : (
          <div>
            <Flex justify="space-between" gap={8}>
              <Input ref={inputRef} readOnly={true} value={url} />
              <Button type="primary" onClick={handleCopy}>
                Copy URL
              </Button>
            </Flex>
            <Flex
              justify="space-between"
              gap={8}
              style={{
                marginTop: "12px",
              }}
            >
              <Input ref={inputRef2} readOnly={true} value={urlReport} />
              <Button type="primary" onClick={handleCopy1}>
                Copy URL for Report
              </Button>
            </Flex>
          </div>
        )}
      </Modal>
    </>
  );
};

export default Blogs;
