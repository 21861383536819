import React, { useEffect, useState } from "react";
import {
  Table,
  Card,
  Flex,
  DatePicker,
} from "antd";

import axios from "axios";
import { connect } from "react-redux";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

const LeadTrackingTable = ({ authToken, column, type = "leadTracking" }) => {
  const [featureRequest, setFeatureRequest] = useState([]);
  const [loading, setLoading] = useState(false);
  const currentDate = dayjs();
  const firstDayOfMonth = dayjs(currentDate)
    .startOf("month")
    .set("hour", 0)
    .set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0);
  const lastDayOfMonth = dayjs(currentDate)
    .set("hour", 23)
    .set("minute", 59)
    .set("second", 59)
    .set("millisecond", 999);

  const [dateRange, setDateRange] = useState([firstDayOfMonth, lastDayOfMonth]);

  const dateFilter = async (date, dateString) => {
    if (dateString[0] === "" || dateString[1] === "") {
      setDateRange([]);
      return;
    }
    const localDate = [
      date[0],
      date[1]
    ];
    setDateRange(localDate);
  };

  const getFeatureRequests = async () => {
    setLoading(true);
    if (type === "leadTracking") {
      const { data } = await axios.get(
        `/admin-dashboard/leadTracking?lte=${dateRange[1]}&gte=${dateRange[0]}`,
        {
          headers: {
            Authorization: `Custom ${authToken}`,
          },
        },
      );
      setFeatureRequest(
        data.map((item, index) => ({
          ...item,
          srNo: index + 1,
          leadSource: item.type,
          google: item.googleAds,
          organic: item.organic,
          total: item.total,
        })),
      );
    } else {
      const { data } = await axios.get(
        `/admin-dashboard/leadTrackingCountryWise?lte=${dateRange[1]}&gte=${dateRange[0]}`,
        {
          headers: {
            Authorization: `Custom ${authToken}`,
          },
        }
      );

      const transformedData = Object.entries(data).map(([country, sources], index) => ({
        srNo: index + 1,
        country: country,
        google: sources.google_ads,
        organic: sources.organic,
        total: sources.google_ads + sources.organic,
      }));
      setFeatureRequest(transformedData);
    }
    setLoading(false);
  };

  useEffect(() => {
    getFeatureRequests();
  }, [dateRange]);

  return (
    <Card
      bodyStyle={{
        padding: "16px 0",
        paddingBottom: "0px",
      }}
      style={{
        border: "1px solid #D9D9D9",
        borderRadius: "4px",
        width: "100%",
      }}
    >
      <Flex
        justify="space-between"
        style={{ padding: "0px 16px", marginBottom: "16px" }}
      >
        <p style={{ fontSize: "16px", fontWeight: "500" }}>Leads Tracking</p>
        <Flex style={{ gap: 10, justifyContent: "flex-start" }}>
          <RangePicker
            style={{ width: 256 }}
            onChange={dateFilter}
            value={dateRange}
            format="YYYY-MM-DD"
            presets={[
              {
                label: "Today",
                value: [dayjs().startOf("day"), dayjs().endOf("day")],
              },
              {
                label: "Yesterday",
                value: [
                  dayjs().subtract(1, "day").startOf("day"),
                  dayjs().subtract(1, "day").endOf("day"),
                ],
              },
              {
                label: "Last 5 days",
                value: [dayjs().subtract(5, "day"), dayjs()],
              },
              {
                label: "This Week",
                value: [dayjs().startOf("week"), dayjs().endOf("week")],
              },
              {
                label: "This Month",
                value: [dayjs().startOf("month"), dayjs().endOf("month")],
              },
              {
                label: "Last 30 Days",
                value: [dayjs().subtract(30, "day"), dayjs()],
              },
            ]}
          />
        </Flex>
      </Flex>
      <Table
        dataSource={featureRequest}
        columns={column}
        pagination={false}
        loading={loading}
        size="small"
        summary={
          (featureRequest) => {
            let totalGoogleAds = 0;
            let totalOrganic = 0;
            let totalTotal = 0;

            featureRequest.forEach(({ google, organic, total }) => {
              totalGoogleAds += google || 0;
              totalOrganic += organic || 0;
              totalTotal += total || 0;
            });

            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <span style={{ fontWeight: 500 }}>Total</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <p style={{ fontWeight: 500 }}>{totalGoogleAds}</p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <p style={{ fontWeight: 500 }}>{totalOrganic}</p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <p style={{ fontWeight: 500 }}>{totalTotal}</p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
      />
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token,
  };
};

export default connect(mapStateToProps)(LeadTrackingTable);
