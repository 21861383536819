import React, { useEffect } from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, message } from "antd";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { login } from "../Redux/UserAuth/authActions";
import { ReactComponent as Logo } from "../asssets/brand/Flabs.svg";
import "./LoginForm.css";
import { Helmet } from "react-helmet";

const LoginForm = ({ login, errorMessage, isAuthenticated, userRole }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const onFinish = (values) => {
    const { username, password } = values;
    login(username, password);
  };

  // useEffect(() => {
  //   if (errorMessage) error(errorMessage);
  // }, [errorMessage]);

  // const error = (msg) => {
  //   messageApi.open({
  //     type: "error",
  //     content: msg,
  //   });
  // };

  if (isAuthenticated) {
    if (userRole === "seo") return <Navigate to="/blogs" replace />;
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <>
      <Helmet>
        <title>Login | Flabs Admin</title>
      </Helmet>
      {contextHolder}

      <div id="components-form-demo-normal-login">
        <div className="flabs-logo-login-form">
          <Logo />
        </div>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your Username!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item className="give-margin-from-login_button">
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.error,
    isAuthenticated: state.auth.isAuthenticated,
    userRole: state.auth.userRole,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (username, password) => dispatch(login(username, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
