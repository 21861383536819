import React, { useEffect, useState } from "react";
import { Table, Button, Card, Flex, Select, Tag, message, Popconfirm } from "antd";
import {
  EyeOutlined,
  PlusOutlined,
  DeleteOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import OnGoingFeatureLabListModal from "./OnGoingFeatureLabListModal";
import AddFeatureModal from "./AddFeatureModal";
import axios from "axios";
import { connect } from "react-redux";
import "./style.css";
import dayjs from "dayjs";

const OngoingFeatures = ({ authToken }) => {
  const [visible, setVisible] = useState(false);
  const [onGoingFeaturesModal, setOnGoingFeaturesModal] = useState(false);
  const [onGoingFeatures, setOnGoingFeatures] = useState([]);
  const [filteredOnGoingFeatures, setFilteredOnGoingFeatures] = useState([]);
  const [selectedOnGoingFeature, setSelectedOnGoingFeature] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchPlansData = async () => {
    try {
      const response = await axios.get("/admin-dashboard/plans", {
        headers: {
          Authorization: `Custom ${authToken}`,
        },
      });

      const options = response?.data?.map((plan) => ({
        planId: plan._id,
        value: plan.name,
        label: plan.name,
      }));

      setPlans(options);
    } catch (error) {
      message.error("Error fetching plan data", [3]);
    }
  };
  const getSuggestedFeatures = async () => {
    setLoading(true);
    const { data } = await axios.get(
      "/ongoingFeature/getAdminOngoingFeatureList",
      {
        headers: { Authorization: `Custom ${authToken}` },
      },
    );
    console.log("featureRequest", data);
    setOnGoingFeatures(
      data.map((item, index) => ({
        ...item,
        srNo: index + 1,
        likes: item.labs.length,
      })),
    );
    setLoading(false);
  };

  useEffect(() => {
    getSuggestedFeatures();
    fetchPlansData();
  }, []);

  useEffect(() => {
    if (selectedPlan === null || selectedPlan === undefined) {
      setFilteredOnGoingFeatures(onGoingFeatures);
    } else {
      const filteredData = onGoingFeatures.filter((feature) =>
        feature.plans.some((plan) => plan.name === selectedPlan),
      );
      setFilteredOnGoingFeatures(filteredData);
    }
  }, [selectedPlan, onGoingFeatures]);

  const handleArchive = async (id, archiveStatus) => {
    await axios.post(
      "/ongoingFeature/archiveOngoingFeature",
      { id: id, archiveStatus: !archiveStatus },
      {
        headers: { Authorization: `Custom ${authToken}` },
      },
    );
    getSuggestedFeatures();
  };

  const handleDelete = async (id) => {
    await axios.post(
      "/ongoingFeature/deleteOngoingFeature",
      { id: id },
      {
        headers: { Authorization: `Custom ${authToken}` },
      },
    );
    getSuggestedFeatures();
  };

  const columns = [
    { title: "Sr. No.", dataIndex: "srNo", width: "7%" },
    { title: "Features", dataIndex: "feature", width: "40%" },
    {
      title: "Plans",
      dataIndex: "plans",
      width: "15%",
      render: (_, record) => (
        <p>{record.plans.map((plan) => plan.name).join(", ")}</p>
      ),
    },
    {
      title: "No. of Likes",
      dataIndex: "likes",
      width: "10%",
      sorter: (a, b) => b.likes - a.likes,
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => (
        <Flex size="small" gap={10}>
          <Button
            type="primary"
            style={{
              borderRadius: "2px",
              padding: "2px 12px",
              alignItems: "center",
            }}
            ghost
            onClick={() => {
              setSelectedOnGoingFeature(record);
              setVisible(true);
            }}
          >
            <EyeOutlined /> View lab list
          </Button>
          <Tag
            color="default"
            style={{
              borderRadius: "2px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              padding: "2px 12px",
              alignItems: "center",
              gap: "5px",
              cursor: "pointer",
              marginRight: "0px",
              width: 90,
            }}
            onClick={() => handleArchive(record._id, record.isArchive)}
          >
            <InboxOutlined />
            {record.isArchive ? "Unarchive" : "Archive"}
          </Tag>
          <Popconfirm
            title="Delete the feature?"
            description="Are you sure you want to delete this feature?"
            onConfirm={() => handleDelete(record._id)}
            onCancel={() => { }}
            okText="Yes"
            cancelText="No"
          >
            <Tag
              color="red"
              style={{
                borderRadius: "2px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                padding: "2px 12px",
                alignItems: "center",
                gap: "5px",
                cursor: "pointer",
                marginLeft: "0px",
              }}
            >
              <DeleteOutlined /> Delete
            </Tag>
          </Popconfirm>

        </Flex>
      ),
    },
  ];

  return (
    <Card
      bodyStyle={{ padding: "16px 0 0" }}
      style={{ border: "1px solid #D9D9D9", borderRadius: "4px" }}
    >
      <Flex
        justify="space-between"
        style={{ padding: "0 16px", marginBottom: "16px" }}
      >
        <p style={{ fontSize: "16px", fontWeight: "500" }}>Features List</p>
        <Flex style={{ gap: 10 }}>
          <Select
            placeholder="Select plan"
            style={{ width: 150, textAlign: "left" }}
            options={plans}
            allowClear
            onChange={(value) => setSelectedPlan(value)}
          />
          <Button
            type="primary"
            style={{ borderRadius: "2px" }}
            onClick={() => setOnGoingFeaturesModal(true)}
          >
            <PlusOutlined /> New Feature
          </Button>
        </Flex>
      </Flex>
      <Table
        dataSource={filteredOnGoingFeatures}
        columns={columns}
        pagination={false}
        loading={loading}
        rowClassName={(record) => (record.isArchive ? "archived-row" : "")}
      />
      <OnGoingFeatureLabListModal
        visible={visible}
        setVisible={setVisible}
        plans={plans}
        selectedOnGoingFeature={selectedOnGoingFeature}
        authToken={authToken}
      />
      <AddFeatureModal
        onGoingFeaturesModal={onGoingFeaturesModal}
        setOnGoingFeaturesModal={setOnGoingFeaturesModal}
        plans={plans}
        getSuggestedFeatures={getSuggestedFeatures}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  authToken: state.auth.token,
});

export default connect(mapStateToProps)(OngoingFeatures);
