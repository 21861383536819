import React from 'react';
import { Table, Tag, Space, Flex, Select, Input, Popover, DatePicker, Button } from 'antd';
import { CloudDownloadOutlined, EditOutlined, ExportOutlined, FundViewOutlined, LinkOutlined, MoreOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import exportToCSV from '../Ticket/helper/ExportToCSV';

const { Search } = Input
const { RangePicker } = DatePicker;

const content = (
  <Flex vertical gap={10}>
    <Flex gap={5}>
      <FundViewOutlined /> <span>View Invoices</span>
    </Flex>
    <Flex gap={5}>
      <EditOutlined />  <span>Edit subscription</span>
    </Flex>
    <Flex gap={5}>
      <EditOutlined />  <span>Edit payment</span>
    </Flex>
    <Flex gap={5}>
      <LinkOutlined /> <span>Share payment update link</span>
    </Flex>
  </Flex>
)

const columns = [
  {
    title: 'Invoice No.',
    dataIndex: 'invoiceNo',
    key: 'invoiceNo',
    render: (text) => <Flex gap={5}>{text} <span style={{
      color: '#4678EE',
      textDecoration: 'underline',
    }}>Details </span><ExportOutlined style={{ fontSize: 12, color: '#4678EE', }} /></Flex>,
  },
  {
    title: 'Plan',
    dataIndex: 'plan',
    key: 'plan',
    render: (plan) => {
      let color = 'geekblue';
      switch (plan) {
        case 'Basic':
          color = 'default';
          break;
        case 'Pro':
          color = 'blue';
          break;
        case 'Enterprise':
          color = 'orange';
          break;
        case 'Premium':
          color = 'gold';
          break;
        case 'RIS':
          color = 'purple';
          break;
        default:
          color = 'geekblue';
      }
      return <Tag color={color} bordered={false} style={{
        width: 75,
        padding: "1px 8px",
        textAlign: "center"
      }}>{plan}</Tag>;
    },
  },
  {
    title: 'Total Amount',
    dataIndex: 'totalAmount',
    key: 'totalAmount',
  },
  {
    title: 'Due amount',
    dataIndex: 'dueAmount',
    key: 'dueAmount',
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    key: 'duration',
  },
  {
    title: 'Start Date',
    dataIndex: 'startDate',
    key: 'startDate',
  },
  {
    title: 'Next Date',
    dataIndex: 'nextDate',
    key: 'nextDate',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status) => {
      let color;
      switch (status) {
        case 'Paid':
          color = 'green';
          break;
        case 'Due':
          color = 'volcano';
          break;
        case 'Upcoming':
          color = 'orange';
          break;
        case 'Created':
          color = 'blue';
          break;
        case 'Partial':
          color = 'gold';
          break;
        default:
          color = 'default';
      }
      return <Tag color={color} style={{
        width: 75,
        padding: "1px 8px",
        textAlign: "center"
      }}>{status}</Tag>;
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: () => (
      <Popover content={content} title="" trigger="click" placement="left">
        <MoreOutlined />
      </Popover>
    ),
  },
];

const data = [
  {
    key: '1',
    invoiceNo: 'inv_0000005009',
    plan: 'Pro',
    totalAmount: '$1000',
    dueAmount: '0.00',
    duration: '2 years',
    startDate: '18-01-2023',
    nextDate: '18-01-2023',
    status: 'Created',
  },
  {
    key: '2',
    invoiceNo: 'inv_0000005009',
    plan: 'Basic',
    totalAmount: '$1000',
    dueAmount: '$400',
    duration: '2 years',
    startDate: '18-01-2023',
    nextDate: '18-01-2023',
    status: 'Due',
  },
  {
    key: '3',
    invoiceNo: 'inv_0000005009',
    plan: 'RIS',
    totalAmount: '$1000',
    dueAmount: '0.00',
    duration: '2 years',
    startDate: '18-01-2023',
    nextDate: '18-01-2023',
    status: 'Paid',
  },
  {
    key: '4',
    invoiceNo: 'inv_0000005009',
    plan: 'Enterprise',
    totalAmount: '$1000',
    dueAmount: '$400',
    duration: '2 years',
    startDate: '18-01-2023',
    nextDate: '18-01-2023',
    status: 'Upcoming',
  },
  {
    key: '5',
    invoiceNo: 'inv_0000005009',
    plan: 'Pro',
    totalAmount: '$1000',
    dueAmount: '0.00',
    duration: '2 years',
    startDate: '18-01-2023',
    nextDate: '18-01-2023',
    status: 'Paid',
  },
  {
    key: '6',
    invoiceNo: 'inv_0000005009',
    plan: 'Premium',
    totalAmount: '$1000',
    dueAmount: '$400',
    duration: '2 years',
    startDate: '18-01-2023',
    nextDate: '18-01-2023',
    status: 'Partial',
  },
  {
    key: '1',
    invoiceNo: 'inv_0000005009',
    plan: 'Pro',
    totalAmount: '$1000',
    dueAmount: '0.00',
    duration: '2 years',
    startDate: '18-01-2023',
    nextDate: '18-01-2023',
    status: 'Created',
  },
  {
    key: '2',
    invoiceNo: 'inv_0000005009',
    plan: 'Basic',
    totalAmount: '$1000',
    dueAmount: '$400',
    duration: '2 years',
    startDate: '18-01-2023',
    nextDate: '18-01-2023',
    status: 'Due',
  },
  {
    key: '3',
    invoiceNo: 'inv_0000005009',
    plan: 'RIS',
    totalAmount: '$1000',
    dueAmount: '0.00',
    duration: '2 years',
    startDate: '18-01-2023',
    nextDate: '18-01-2023',
    status: 'Paid',
  },
];

function generateDownloadData(data) {

  const downloadData = data.map((ticket) => {
    return {
      "Invoice No.": ticket.invoiceNo,
      "Plan": ticket.plan,
      "Total Amount": ticket.totalAmount,
      "Due Amount": ticket.dueAmount,
      "Duration": ticket.duration,
      "Start Date": ticket.startDate,
      "Next Date": ticket.nextDate,
      "Status": ticket.status,
    };
  });
  return downloadData;
}

const PaymentTable = () => {
  return (
    <Flex vertical gap={15}>
      <Flex gap={20} justify='space-between'>
        <p style={{
          // color: "#707070",
          fontSize: 20,
          fontWeight: 500,
        }}>Payments</p>
        <Flex gap={15}>
          <Search placeholder="Search by Customer/Invoice no." style={{ width: 255 }} allowClear />
          <Select style={{ width: 150, textAlign: "left" }} placeholder="Status"
            options={[
              { label: 'Paid', value: 'Paid' },
              { label: 'Due', value: 'Due' },
              { label: 'Upcoming', value: 'Upcoming' },
              { label: 'Created', value: 'Created' },
              { label: 'Partial', value: 'Partial' },
            ]}
            allowClear
          />
          <RangePicker style={{ width: 220 }} allowClear />
          <Button color="primary" variant="outlined" onClick={() => exportToCSV(generateDownloadData(data), "Ongoing Ticket")}>
            <CloudDownloadOutlined />  Export
          </Button>
        </Flex>
      </Flex>
      <Table columns={columns} dataSource={data} pagination={false} style={{ border: '1px solid #d9d9d9', borderRadius: 4 }} />
    </Flex>
  )
};

export default PaymentTable;
