import React, { useState } from 'react';
import axios from 'axios';
import { Button, Modal, Input, message, Divider, Select } from 'antd';

import '../../styles/MenualPaymentModal.css';

const onSearch = (value) => {
	console.log('search:', value);
};

// Filter `option.label` match the user type `input`
const filterOption = (input, option) =>
	(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const MenualPaymentModal = ({
	menualPaymentOpen,
	setMenualPaymentOpen,
	plans,
	authToken,
	labId,
}) => {
	const [obj, setObj] = useState({
		labId: '',
		paidAmount: '',
		month: '',
		planId: '',
	});
	// console.log('formData', obj);
	const [loading, setLoading] = useState(false);
	const submit = async () => {
		setLoading(true);
		const request = {
			labId: labId,
			paidAmount: Number(obj.paidAmount),
			month: Number(obj.month),
			plan: obj.planId,
		};
		try {
			const response = await axios.post(
				'/lab-payment/manualPayment',
				request,
				{
					headers: {
						Authorization: `Custom ${authToken}`,
					},
				}
			);
			console.log('response', response);
			message.success('Lab updated successfully');
			setLoading(false);
			setObj({
				labId: '',
				paidAmount: '',
				month: '',
				planId: '',
			});
		} catch (error) {
			message.error('Error updating lab');
			setLoading(false);
		}
		setLoading(false);
	};

	return (
		<div>
			<Modal
				title="Manual Payment"
				open={menualPaymentOpen}
				onCancel={() => setMenualPaymentOpen(!menualPaymentOpen)}
				footer={[
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={submit}
					>
						Submit
					</Button>,
				]}
				width={430}
				centered
			>
				<Divider style={{ margin: 0 }} />
				<div className="manual-paymeny-main">
					<div className="manual-paymeny-item">
						<p>Select Plan</p>
						<Select
							showSearch
							placeholder="Select"
							optionFilterProp="children"
							value={
								plans.filter(
									(plan) => plan.planId === obj.planId
								)[0]?.value
							}
							onChange={(value) => {
								setObj({
									...obj,
									planId: plans.filter(
										(plan) => plan.value === value
									)[0].planId,
								});
							}}
							onSearch={onSearch}
							filterOption={filterOption}
							style={{ width: '100%' }}
							options={plans}
						/>
					</div>
					<div className="manual-paymeny-item">
						<p>Duration</p>
						<Input
							value={obj.month}
							onChange={(e) =>
								setObj({ ...obj, month: e.target.value })
							}
						/>
					</div>
					<div className="manual-paymeny-item">
						<p>Paid Amount</p>
						<Input
							value={obj.paidAmount}
							onChange={(e) =>
								setObj({ ...obj, paidAmount: e.target.value })
							}
						/>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default MenualPaymentModal;
