import { useEffect, useState } from 'react'
import Header from '../Base/Header'
import { Button, Flex, Input, message } from 'antd'
import Editor from './Editor'
import { connect } from 'react-redux'
import axios from 'axios'
import SmartReportEditor from './Editor'

const SmartReport = ({ authToken }) => {
    const [labID, setLabID] = useState("")
    const [mappingData, setMappingData] = useState(null)


    const getSmartReport = async () => {
        const response = await axios.get(`/smart-report/get?labId=${labID}`,
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
        if (response.status === 200) {
            message.success("Lab Get Successfully")
        }
        setMappingData(response.data)
    }

    const updateSmartReport = async () => {
        const response = await axios.post(`/smart-report/update`,
            {
                mapping: mappingData
            },
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
        if (response.status === 200) {
            message.success("Smart Report Updated Successfully")
            setMappingData(null)
            setLabID("")
        }
    }

    return (
        <>
            <Header />
            <Flex vertical gap={20} style={{ margin: " 40px 40px 40px 105px" }}>
                <h3 style={{ fontSize: 16, fontWeight: 700 }}>Smart Reports</h3>
                <Flex vertical style={{ maxWidth: 500 }}>
                    <p>Lab ID*</p>
                    <Flex gap={20} align='center' style={{ marginTop: 10 }}>
                        <Input placeholder="Enter Lab ID" value={labID} onChange={(e) => setLabID(e.target.value)} />
                        <Flex gap={10}>
                            <Button type='primary' ghost style={{ borderRadius: 2, }} onClick={getSmartReport}>
                                Lab Get
                            </Button>
                            {/* <Button type='primary' ghost style={{ borderRadius: 2 }}>
                                Global Get
                            </Button> */}
                        </Flex>
                    </Flex>
                </Flex>
                <SmartReportEditor mappingData={mappingData} setMappingData={setMappingData} />
                <div>
                    <Button type='primary' ghost style={{ borderRadius: 2, width: "fit-content", zIndex: 100 }} disabled={!mappingData} onClick={() => updateSmartReport()}>
                        Update
                    </Button>
                </div>
            </Flex>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        authToken: state.auth.token,
    };
};

export default connect(mapStateToProps)(SmartReport);