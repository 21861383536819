
import React, { useEffect, useState } from "react";
import { Button, DatePicker, Select, Table, Flex } from "antd";
import dayjs from "dayjs";
import { connect } from "react-redux";

import exportToCSV from "./helper/ExportToCSV";
import { generateDownloadData, generateDownloadDataClosed, getTicketData, languageOptions } from "./helper/apiService";

const { RangePicker } = DatePicker;

const TicketTable = (props) => {
  const {
    name,
    userMainRole,
    columns,
    isChanged,
    updateLoading,
    status,
    plans,
    salesPersonOptions,
    supportPersonOptions } = props;

  const [downloadData, setDownloadData] = useState([]);
  const [csvFileName, setCsvFileName] = useState("");
  const [tableData, setTableData] = useState([]);
  const [selectedSupportPerson, setSelectedSupportPerson] = useState(
    userMainRole === "support" ? name : "",
  );
  const [selectedSalesPerson, setSelectedSalesPerson] = useState(
    userMainRole === "sales" ? name : "",
  );
  const [selectedPlan, setSelectedPlan] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([]);

  const dateFilter = async (date, dateString) => {
    if (dateString[0] === "" || dateString[1] === "") {
      setDateRange([]);
      return;
    }
    const localDate = [
      date[0],
      date[1]
    ];
    setDateRange(localDate);
  };

  const handleFilters = () => {
    let filteredData = data;
    if (selectedPlan) {
      filteredData = filteredData.filter((item) => item?.lab && item.lab.plan.name === selectedPlan);
    }

    if (selectedLanguage) {
      filteredData = filteredData.filter((item) => item?.language && item.language === selectedLanguage);
    }

    setTableData(filteredData);
  };

  useEffect(() => {
    handleFilters();
  }, [data, selectedPlan, selectedLanguage]);

  useEffect(() => {
    if (status === "closed") {
      setCsvFileName("Closed Tickets");
      setDownloadData(generateDownloadDataClosed(tableData));
    } else {
      if (status === "pending") setCsvFileName("Pending Tickets");
      if (status === "in_progress") setCsvFileName("In Progress Tickets");
      if (status === "bug") setCsvFileName("Bug/Requirement Tickets");
      setDownloadData(generateDownloadData(tableData));
    }
  }, [tableData]);

  useEffect(() => {
    handleGetTicketData();
    if (status === 'closed' && dateRange?.length > 0) {
    }
  }, [status, isChanged, dateRange, selectedSupportPerson, selectedSalesPerson]);


  const handleGetTicketData = () => getTicketData(
    setData,
    setLoading,
    status,
    dateRange[0],
    dateRange[1],
    selectedSupportPerson,
    selectedSalesPerson
  );

  return (
    <div>
      <Flex justify="space-between" align="center">
        <div style={{ display: "flex", gap: "20px" }}>
          <Select
            allowClear={true}
            popupClassName="popup__menu"
            style={{ width: "210px" }}
            options={supportPersonOptions}
            placeholder="Support Person"
            value={selectedSupportPerson || undefined}
            onChange={(value) => setSelectedSupportPerson(value)}
            disabled={loading}
          />
          <Select
            allowClear={true}
            popupClassName="popup__menu"
            style={{ width: "210px" }}
            options={salesPersonOptions}
            placeholder="Sales Person"
            value={selectedSalesPerson || undefined}
            onChange={(value) => setSelectedSalesPerson(value)}
            disabled={loading}
          />
          <Select
            allowClear={true}
            popupClassName="popup__menu"
            style={{ width: "111px" }}
            options={plans}
            placeholder="Plan"
            value={selectedPlan || undefined}
            onChange={(value) => setSelectedPlan(value)}
            disabled={loading}
          />
          <Select
            allowClear={true}
            popupClassName="popup__menu"
            style={{ width: "111px" }}
            options={props?.language}
            placeholder="Language"
            value={selectedLanguage || undefined}
            onChange={(value) => setSelectedLanguage(value)}
            disabled={loading}
          />
          <RangePicker
            value={dateRange}
            onChange={dateFilter}
            allowClear={true}
            disabled={loading}
            ranges={{
              Today: [dayjs(), dayjs()],
              "This Week": [dayjs().startOf("week"), dayjs().endOf("week")],
              "This Month": [dayjs().startOf("month"), dayjs().endOf("month")],
            }}
          />
        </div>
        <span>
          <Button onClick={() => exportToCSV(downloadData, csvFileName)} disabled={loading}>
            Export
          </Button>
        </span>
      </Flex>
      <div
        style={{
          marginTop: "20px",
          border: "1px solid  #D9D9D9",
          borderRadius: "4px",
        }}
      >
        <Table
          columns={columns}
          dataSource={tableData}
          loading={loading || updateLoading}
          pagination={false}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userRole: state.auth.userRole,
    userId: state.auth.id,
    name: state.auth.name,
    userMainRole: state.auth.userMainRole,
  };
};

export default connect(mapStateToProps)(TicketTable);
