import React from "react";
import { Progress } from "antd";

const LabDataCards = ({labData}) => {
  return (
    <div className="subs_info_container">
      <div className="circular_border">
        <Progress
          type="circle"
          percent={labData.percentage}
          size={70}
          strokeWidth={16}
          strokeLinecap="butt"
          gapPosition="top"
          strokeColor={labData.color}
        />
      </div>
      <div>
        <p className="subs_plan">{labData.subscriptionPlan}</p>
        <h2 className="subs_lab_quantity">{labData.labQuantity}</h2>
      </div>
    </div>
  );
};

export default LabDataCards;
