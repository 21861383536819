import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Divider,
  Flex,
  Select,
  Checkbox,
  message,
  Typography,
} from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { createTicket, getLabNames } from "./helper/apiService";
const { Option } = Select;
const { Text } = Typography;

const SupportTicketModal = ({
  supportPersons,
  setOpenModal,
  openModal,
  setIsChanged,
  setIsStatusChanged,
}) => {
  const [ticketData, setTicketData] = useState({});
  const [search, setSearch] = useState("");
  const [labsOptions, setLabsOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!search || search === " ") {
      getLabsNamesData();
    } else {
      const timeOut = setTimeout(() => {
        getLabsNamesData(search);
      }, 800);
      return () => clearTimeout(timeOut);
    }
  }, [search]);

  const getLabsNamesData = async (labSearch = search) => {
    try {
      const res = await getLabNames(labSearch);
      if (res) {
        const options = res?.data?.map((item) => {
          return {
            label: item.name,
            desc: (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text>{item?.name}</Text>
                <Text style={{ fontSize: 10 }}>
                  {"+" + (item?.countryCode || "91") + "-" + item?.contact}
                </Text>
              </div>
            ),
            value: item._id,
          };
        });
        setLabsOptions(options);
      }
    } catch (err) {
      console.log({ err });
    }
  };

  const onSave = async () => {
    try {
      setLoading(true);
      const res = await createTicket({ ...ticketData });
      if (res.err) {
        return message.error(res.err);
      }
      setIsChanged((prev) => !prev);
      setIsStatusChanged((prev) => !prev);
      message.success("Ticket created successfully");
      setOpenModal(false);
    } catch (err) {
      console.log({ err });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Support Ticket"
      open={openModal}
      onCancel={() => setOpenModal(false)}
      centered
      width={571}
      footer={[
        <Button
          type="primary"
          key="add"
          onClick={() => {
            onSave();
          }}
          disabled={
            !ticketData.lab || !ticketData.query || !ticketData.supportPerson || loading
          }
        >
          Save
        </Button>,
      ]}
    >
      <Divider style={{ margin: "10px 0" }} />
      <Flex style={{ width: "100%" }} gap={20}>
        <Flex vertical gap={5} style={{ width: "100%" }}>
          <label>
            Add Lab <span style={{ color: "red" }}>*</span>
          </label>
          <Select
            onSearch={(e) => setSearch(e)}
            placeholder="Search by lab name or contact"
            onSelect={(e) => setTicketData({ ...ticketData, lab: e })}
            showSearch
            suffixIcon={<SearchOutlined />}
            value={ticketData?.lab}
            options={labsOptions}
            optionRender={(option) => option.data.desc}
            filterOption={false}
          />
        </Flex>
        <Flex vertical gap={5} style={{ width: "100%" }}>
          <label>
            Support Person{" "}
            <span style={{ color: "red", width: "100%" }}>*</span>
          </label>
          <Select
            showSearch
            options={supportPersons}
            placeholder="Select support person"
            suffixIcon={
              <SearchOutlined
                size={14}
                style={{ color: "#999999" }}
                color="#999999"
              />
            }
            value={ticketData.supportPerson}
            onChange={(e) => setTicketData({ ...ticketData, supportPerson: e })}
          />
        </Flex>
      </Flex>
      <Flex vertical gap={5} style={{ marginTop: 20 }}>
        <label>
          Write Query <span style={{ color: "red" }}>*</span>
        </label>
        <TextArea
          value={ticketData.query}
          onChange={(e) =>
            setTicketData({ ...ticketData, query: e.target.value })
          }
          placeholder="Write here..."
          style={{
            borderRadius: "4px",
          }}
          rows={3}
          variant={"outlined"}
        />
      </Flex>
      <Flex style={{ marginTop: 20 }}>
        <Checkbox
          checked={ticketData.urgent}
          onChange={(e) =>
            setTicketData({ ...ticketData, urgent: e.target.checked })
          }
        >
          Urgent
        </Checkbox>
      </Flex>
      <Divider style={{ margin: "10px 0" }} />
    </Modal>
  );
};

export default SupportTicketModal;
