import { Flex, Tabs } from "antd";
import styles from "../Ticket/ticket.module.css";
import Header from "../Base/Header";
import TransferComp from "./TransferComp";

const TestTransfer = () => {

    const items = [
        {
            key: '1',
            label: 'Test Transfer',
            children: <TransferComp text="test" />,
        },
        {
            key: '2',
            label: 'Package Transfer',
            children: <TransferComp text="Package" />,
        },
    ];
    return (
        <>
            <Header />
            <section className={styles["blog-section-container"]}>
                <Flex
                    style={{
                        gap: "15px",
                        marginTop: "16px",
                        width: "100%",
                        marginRight: "16px",
                    }}
                >
                    <Tabs defaultActiveKey="1" items={items} style={{ width: "100%" }} />
                </Flex>
            </section>
        </>
    );
};

export default TestTransfer;
