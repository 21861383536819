import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

const InitialScreen = ({ isAuthenticated, userRole }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      if (userRole === "seo") navigate("/blogs");
      else navigate("/dashboard");
    } else {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  console.log(isAuthenticated);

  return <div></div>;
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  userRole: state.auth.userRole,
});

export default connect(mapStateToProps)(InitialScreen);
