import { Card, Flex, Typography } from "antd";
import React from "react";

const { Text } = Typography;

const Stats = ({ stats }) => {
  return (
    <Flex gap={16} justify="space-between" style={{ width: "100%" }}>
      <Card
        bodyStyle={{ padding: 16 }}
        style={{ borderColor: "#d9d9d9", width: "100%", borderRadius: "2px" }}
      >
        <Flex vertical gap={4}>
          <span>Collected Amount</span>
          <span style={{ fontWeight: 700, fontSize: 24, color: "#4678EE" }}>
            ₹{stats?.collectedAmount}
          </span>
        </Flex>
      </Card>
      <Card
        bodyStyle={{ padding: 16 }}
        style={{ borderColor: "#d9d9d9", width: "100%", borderRadius: "2px" }}
      >
        <Flex vertical gap={4}>
          <span>Renew</span>
          <span style={{ fontWeight: 700, fontSize: 24, color: "#52C41A" }}>
            ₹{stats?.renew}
          </span>
        </Flex>
      </Card>
      <Card
        bodyStyle={{ padding: 16 }}
        style={{ borderColor: "#d9d9d9", width: "100%", borderRadius: "2px" }}
      >
        <Flex vertical gap={4}>
          <span>New</span>
          <span style={{ fontWeight: 700, fontSize: 24, color: "#FA8C16" }}>
            ₹{stats?.new}
          </span>
        </Flex>
      </Card>
    </Flex>
  );
};

export default Stats;
