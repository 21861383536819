import { Flex, Tabs } from "antd";
import styles from "../Ticket/ticket.module.css";
import OngoingFeatures from "./OngoingFeatures";
import FeaturesRequest from "./FeaturesRequest";
import Header from "../Base/Header";
import SuggestedFeatureTable from "./SuggestedFeatureTable";
const items = [
  {
    key: "1",
    label: "Ongoing features",
    children: <OngoingFeatures />,
  },
  {
    key: "2",
    label: "Features request",
    children: <FeaturesRequest />,
  },
  {
    key: "3",
    label: "Suggested feature",
    children: <SuggestedFeatureTable />,
  },
];
const Feature = () => {
  return (
    <>
      <Header />
      <section className={styles["blog-section-container"]}>
        <Flex
          style={{
            gap: "15px",
            marginTop: "16px",
            width: "100%",
            marginRight: "16px",
          }}
        >
          <Tabs
            defaultActiveKey="1"
            items={items}
            onChange={() => {}}
            style={{
              textAlign: "right",
              position: "relative",
              width: "100%",
              marginRight: "0px",
            }}
          />
        </Flex>
      </section>
    </>
  );
};

export default Feature;
