import React from 'react'
import {
    Button,
    Modal,
    Divider,
    Form,
    Input,
    message,
} from "antd";
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';

const EditBankDetailsModal = ({ openEdit, setOpenEdit, authToken, fetchData }) => {
    const [form] = useForm();

    const handleBankDetails = (values) => {
        console.log(values);
        // { id, accountNumber, accountHolderName, ifsc, email }
        const data = {
            id: openEdit?.id,
            accountNumber: values?.accountNo,
            accountHolderName: values?.holderName,
            ifsc: values?.ifscCode,
            email: values?.email,
        }
        console.log("data", data);
        form
            .validateFields()
            .then(() => {
                axios.post('/razorpay/updateData', data,
                    {
                        headers: {
                            Authorization: `Custom ${authToken}`,
                        },
                    },
                ).then((res) => {
                    setOpenEdit(null);
                    form.resetFields();
                    message.success('Bank details changed Successfully');
                    fetchData();
                }).catch((err) => {
                    message.error('Failed to update bank details');
                })
            })
            .catch((error) => console.log({ error }));
    };

    return (
        <Modal
            title={"Edit Details"}
            visible={openEdit}
            onCancel={() => setOpenEdit(null)}
            width={448}
            footer={false}
            centered
        >
            <Divider style={{ margin: "15px 0" }} />
            <Form
                layout="vertical"
                onFinish={handleBankDetails}
                form={form}
                initialValues={{
                    accountNo: openEdit?.accountNumber,
                    holderName: openEdit?.holderName,
                    ifscCode: openEdit?.ifsc,
                    email: openEdit?.email,
                }}
            >
                <Form.Item
                    name="accountNo"
                    label="Account Number"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your account number!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="holderName"
                    label="Holder Name"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your holder name!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="ifscCode"
                    label="IFSC Code"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your IFSC code!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="email"
                    label="Email Id"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your email!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        type="primary"
                        size="medium"
                        htmlType="submit"
                    >
                        Save
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export default EditBankDetailsModal;
