import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import { connect } from "react-redux";

import Header from "../Base/Header";
import { InboxOutlined, SendOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import styles from "./Messages.module.css";
import UpdateMessage from "./UpdateMessage";
const { Dragger } = Upload;

const BulkMessage = ({ authToken }) => {
  const [contactNumbers, setContactNumbers] = useState([]);

  // useEffect(() => {
  //   if (contactNumbers.length > 0) sendBulkMessages();
  // }, [contactNumbers]);

  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const numbers = parsedData
        .map((row) => row[0])
        .filter((value) => !isNaN(value));

      setContactNumbers(numbers);
    };
    reader.readAsArrayBuffer(file);
  };

  const deleteFileHandler = (files) => {
    if (files.length > 0) return;
    setContactNumbers([]);
  };

  const uploadConent = {
    name: "file",
    accept: ".xlsx, .xls, .csv",
    maxCount: 1,
    beforeUpload: (file) => {
      handleFileUpload(file);
      return false;
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        deleteFileHandler(info.fileList);
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const sendBulkMessages = async () => {
    try {
      const numbersParam = contactNumbers.join(",");
      const config = {
        headers: {
          Authorization: `Custom ${authToken}`,
        },
        params: {
          numbers: numbersParam,
        },
      };
      const res = await axios.get(`/admin-dashboard/whatsapp`, config);
      console.log(res);

      message.success("Messages succesfully sent!");
    } catch (error) {
      message.error(error);
    }
  };

  return (
    <>
      <Header />
      <section className={styles["bulk-messages-container"]}>
        <div className={styles["uploader-header"]}>Bulk Message</div>
        <div className={styles["left-side"]}>
          <Dragger style={{ borderRadius: "2px" }} {...uploadConent}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className={styles["ant-upload-text"]}>
              Click or drag file to this area to upload
            </p>
            <p className={styles["ant-upload-hint"]}>
              Select the file from your computer. Please note that only CSV
              files are supported for uploading.
            </p>
          </Dragger>
          <div className={styles.send_button}>
            <Button
              disabled={contactNumbers.length > 0 ? false : true}
              onClick={sendBulkMessages}
              type="primary"
            >
              <SendOutlined />  Send
            </Button>
          </div>
        </div>
        <div>
          <UpdateMessage authToken={authToken} />
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token,
  };
};

export default connect(mapStateToProps)(BulkMessage);
