import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Select,
    Table,
    Button,
    message,
    DatePicker,
} from "antd";
import dayjs from "dayjs";
import { connect } from "react-redux";
import "./customTable.css"
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import IntegrationModal from "./IntegrationModal";
import EditBankDetailsModal from "./EditBankDetailsModal";

const { RangePicker } = DatePicker;

const PendingStatus = ({ authToken }) => {
    const [loading, setLoading] = useState(false);
    const [pendingData, setPendingData] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState("all");
    const [openInteg, setOpenInteg] = useState(false);
    const [openEdit, setOpenEdit] = useState(null);
    const [add, setAdd] = useState("all");
    const currentDate = dayjs();
    const firstDayOfMonth = dayjs(currentDate)
        .startOf("month")
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .set("millisecond", 0);
    const lastDayOfMonth = dayjs(currentDate)
        .set("hour", 23)
        .set("minute", 59)
        .set("second", 59)
        .set("millisecond", 999);

    const [dateRange, setDateRange] = useState([firstDayOfMonth, lastDayOfMonth]);
    const [data, setData] = useState();

    const getIntegrationData = async (labId) => {
        try {
            const res = await axios.get(`/razorpay/getRazorpayLabData?lab=${labId}`, {
                headers: {
                    Authorization: `Custom ${authToken}`,
                },
            });
            setData(res?.data);
        } catch (error) {
            console.log(error);
        }
    };
    const dateFilter = async (date, dateString) => {
        const localDate = [
            dayjs(new Date(dateString[0]).setHours(0, 0, 0)),
            dayjs(new Date(dateString[1]).setHours(23, 59, 59)),
        ];
        setDateRange(localDate);
    };

    useEffect(() => {
        fetchData();
    }, [dateRange, selectedPlan, add]);

    // TABLE COLUMNS
    const columns = [
        {
            title: "Date & Time",
            dataIndex: "date",
            key: "date",
            width: "10%",
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
            render: (text) => {
                if (text) {
                    const date = new Date(text);
                    const formattedDate = date.toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                    }).replace(/\//g, "-");

                    const formattedTime = date
                        .toLocaleTimeString("en-GB", {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                        })
                        .toUpperCase();

                    return (
                        <>
                            {formattedDate} <br />
                            {formattedTime}
                        </>
                    );
                }
                return null;
            }
        },
        {
            title: "Lab Details",
            dataIndex: "labDetails",
            key: "labDetails",
            width: "10%",
            render: (_, record) => {
                return (
                    <div>
                        {record?.labDetails[0]}<br />+{record?.labDetails[1] + ' ' + record?.labDetails[2]}
                    </div>
                );
            },
        },
        {
            title: "Account Number",
            dataIndex: "accountNumber",
            key: "accountNumber",
            width: "10%",
        },
        {
            title: "Holder Name",
            dataIndex: "holderName",
            key: "holderName",
            width: "10%",
        },
        {
            title: "IFSC Code",
            dataIndex: "ifsc",
            key: "ifsc",
            width: "10%",
        },
        {
            title: "Email Id",
            dataIndex: "email",
            key: "email",
            width: "10%",
        },
        {
            title: "Request type",
            dataIndex: "reqType",
            key: "reqType",
            width: "10%",
            render: (_, record) => {
                return (
                    <div
                        style={{
                            border: record?.reqType === "change" ? "1px solid #FFD591" : "1px solid #C4DCFF",
                            fontWeight: "400",
                            fontSize: "14px",
                            background: record?.reqType === "change" ? "#FFF7E6" : "#F0F7FF",
                            borderRadius: "2px",
                            color: record?.reqType === "change" ? "#FA8C16" : "#4678EE",
                            width: "75px",
                            height: "27px",
                            textAlign: "center",
                            padding: "1px 8px",
                        }}
                    >
                        {record?.reqType === "change" ? "Change" : "New"}
                    </div>
                );
            },
        },
        {
            title: "Action",
            key: "action",
            width: "30%",
            render: (_, record) => {
                return (
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <Button
                            size="small"
                            style={{
                                border: "1px solid #4678EE",
                                fontWeight: "400",
                                color: "#4678EE",
                            }}
                            onClick={() => setOpenEdit(record)}
                        >
                            <EditOutlined />  Edit
                        </Button>
                        {record?.status === 'pending' &&
                            <Button
                                size="small"
                                style={{
                                    border: "1px solid #4678EE",
                                    fontWeight: "400",
                                    color: "#4678EE",
                                }}
                                onClick={() => handleAdd(record)}
                            >
                                <PlusOutlined />   Add
                            </Button>
                        }
                        {
                            record?.status === 'cool_down' &&
                            <Button
                                size="small"
                                type="primary"
                                style={{
                                    fontWeight: "400",
                                }}
                            >
                                Added
                            </Button>
                        }
                        <Button
                            size="small"
                            style={{
                                border: "1px solid #4678EE",
                                fontWeight: "400",
                                fontSize: '14px',
                                color: "#4678EE",
                            }}
                            onClick={() => {
                                getIntegrationData(record?.lab)
                                setOpenInteg(record)
                            }
                            }
                        >
                            Integration
                        </Button>
                        <Button
                            size="small"
                            type="primary"
                            disabled={record?.status !== "pre_approved"}
                            onClick={() => handleApprove(record)}
                            style={{
                                fontWeight: "400",
                                fontSize: '14px',
                            }}
                        >
                            Approve
                        </Button>
                    </div >
                );
            },
            width: "12%",
        },
    ];

    const reqTypeOptions = [
        {
            value: "all",
            label: "All",
        },
        {
            value: "add",
            label: "New",
        },
        {
            value: "change",
            label: "Change",
        }
    ]

    const addOptions = [
        {
            value: "all",
            label: "All",
        },
        {
            value: "added",
            label: "Added",
        },
        {
            value: "add",
            label: "Add",
        }
    ]

    const fetchData = async () => {
        try {
            setLoading(true);

            const baseUrl = '/razorpay/admin-get';
            const authHeader = {
                headers: {
                    Authorization: `Custom ${authToken}`,
                },
            };

            let status = '';
            switch (add) {
                case 'add':
                    status = 'pending';
                    break;
                case 'added':
                    status = 'cool_down';
                    break;
                case 'all':
                    status = 'pending,cool_down,pre_approved';
                    break;
                default:
                    break;
            }

            const params = {
                status,
                lte: dateRange[1],
                gte: dateRange[0],
            };

            if (selectedPlan !== 'all') {
                params.requestType = selectedPlan;
            }

            const res = await axios.get(baseUrl, { ...authHeader, params });
            setPendingData(res?.data);
        } catch (error) {
            message.error(error?.message || 'Something went wrong');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };


    const handleApprove = async (record) => {
        const { id, lab } = record;
        const data = {
            id,
            status: "approved",
            lab,
        }
        try {
            const res = await axios.post(`/razorpay/update`, data, {
                headers: {
                    Authorization: `Custom ${authToken}`,
                },
            });
            if (res.status === 200) {
                message.success("Approved Successfully!");
            } else {
                message.error("Something went wrong!");
            }
            setTimeout(() => {
                fetchData();
            }, "500");
        } catch (error) {
            console.log(error);
        }
    };

    const handleAdd = async (record) => {
        const { id, lab } = record;
        const data = {
            id,
            status: "cool_down",
            lab,
        }
        try {
            const res = await axios.post(`/razorpay/update`, data, {
                headers: {
                    Authorization: `Custom ${authToken}`,
                },
            });
            if (res.status === 200) {
                message.success("Added Successfully!");
            } else {
                message.error("Something went wrong!");
            }
            setTimeout(() => {
                fetchData();
            }, "500");
        } catch (error) {
            console.log(error);
        }
    };


    const tableData = pendingData?.map((data) => {
        return {
            date: data?.createdAt,
            labDetails: [data?.lab?.name, data?.lab?.countryCode, data?.lab?.contact],
            accountNumber: data?.accountNumber,
            holderName: data?.accountHolderName,
            ifsc: data?.ifsc,
            email: data?.email,
            reqType: data?.requestType,
            id: data?._id,
            lab: data?.lab?._id,
            status: data?.status,
        };
    });

    const exportData = pendingData?.map((data) => {
        return {
            "Date&Time": `${dayjs(data.createAt).format("DD-MM-YYYY hh:mm A")}`,
            "Lab Details": `${data?.lab?.name} + ${data?.lab?.countryCode} ${data?.lab?.contact}`,
            "Account Number": data?.accountNumber,
            "Holder Name": data?.accountHolderName,
            "IFSC Code": data?.ifsc,
            "Email Id": data?.email,
            "Request type": data?.requestType,
        };
    });

    const exportToCSV = (csvData, fileName) => {
        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";

        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
        <div style={{ borderRadius: "4px", border: "1px solid #D9D9D9", background: "#FFF" }}>
            <div style={{ padding: "16px", display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
                <div style={{ color: "rgba(0, 0, 0, 0.85)", fontWeight: "500", fontSize: "16px" }}>
                    Razorpay Integration
                </div>
                <div style={{ display: "flex", gap: "15px" }}>
                    <Select
                        popupClassName="popup__menu"
                        style={{ width: 100 }}
                        options={addOptions}
                        value={add}
                        onChange={(value) => setAdd(value)}
                    />
                    <Select
                        popupClassName="popup__menu"
                        style={{ width: 100 }}
                        options={reqTypeOptions}
                        value={selectedPlan}
                        onChange={(value) => setSelectedPlan(value)}
                    />
                    <RangePicker
                        style={{ width: 240 }}
                        value={dateRange}
                        onChange={dateFilter}
                        allowClear={false}
                        format="YYYY-MM-DD"
                        presets={[
                            {
                                label: "Today",
                                value: [dayjs().startOf("day"), dayjs().endOf("day")],
                            },
                            {
                                label: "Last 5 days",
                                value: [dayjs().add(-5, "d"), dayjs()],
                            },
                            {
                                label: "This Week",
                                value: [dayjs().startOf("week"), dayjs().endOf("week")],
                            },
                            {
                                label: "This Month",
                                value: [dayjs().startOf("month"), dayjs().endOf("month")],
                            },
                            {
                                label: "Last 30 Days",
                                value: [dayjs().add(-30, "d"), dayjs()],
                            },
                        ]}
                    />
                    <Button
                        popupClassName="popup__menu"
                        onClick={() =>
                            exportToCSV(exportData, "Razorpay Integration- Pending Status")
                        }
                    >
                        Export
                    </Button>
                </div>
            </div>
            <Table
                dataSource={tableData}
                columns={columns}
                loading={loading}
                pagination={false}
                className="custom-table"
            />
            {
                openInteg &&
                <IntegrationModal
                    openInteg={openInteg}
                    setOpenInteg={setOpenInteg}
                    authToken={authToken}
                    data={data}
                    fetchData={fetchData}
                />
            }
            {
                openEdit &&
                <EditBankDetailsModal
                    openEdit={openEdit}
                    setOpenEdit={setOpenEdit}
                    authToken={authToken}
                    fetchData={fetchData}
                />
            }
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        authToken: state.auth.token,
    };
};

export default connect(mapStateToProps)(PendingStatus);
