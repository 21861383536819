import axios from "axios";
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  CURRENT_USER_SUCCESS,
  CURRENT_USER_FAILURE,
  CURRENT_USER,
} from "./authTypes";
import { message } from "antd";

// Encode the token as base64
const encodeToken = (email, password) => {
  const token = `${email}:${password}`;
  const encodedToken = btoa(token);
  return encodedToken;
};

const setToken = (token) => {
  localStorage.setItem("tokenv3", token);
};

const getToken = () => {
  return localStorage.getItem("tokenv3");
};

const removeToken = () => {
  localStorage.clear();
};

export const login = (email, password) => {
  return (dispatch) => {
    const encodedToken = encodeToken(email, password);
    axios
      .post(
        "/admin-dashboard/login",
        { email, password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
      .then((response) => {
        if (response.status === 200) {
          setToken(response?.data?.token);
          dispatch({
            type: LOGIN_SUCCESS,
            payload: {
              token: response?.data?.token,
              userRole: response?.data?.user?.role,
            },
          });
          window.location.reload();
        } else {
          message.error("Authentication failed");
        }
      })
      .catch((error) => {
        message.error("Authentication failed");
        dispatch({
          type: CURRENT_USER_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const currentUser = () => {
  return (dispatch) => {
    // dispatch({
    //   type: CURRENT_USER,
    // });
    const token = getToken();
    const config = {
      headers: {
        Authorization: `Custom ${token}`,
      },
    };
    axios
      .get("/admin-dashboard/current_user", config)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: CURRENT_USER_SUCCESS,
            payload: {
              token: token,
              userRole: res.data.role,
              name: res.data.name,
              contact: res.data.contact,
              userMainRole: res.data.userRole,
              id: res.data._id,
            },
          });
        }
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          removeToken();
        }
        dispatch({
          type: CURRENT_USER_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const logout = () => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Custom ${token}`,
    },
  };
  axios
    .post(
      "/admin-dashboard/logout",
      {
        token: getToken(),
      },
      config,
    )
    .then((res) => {
      removeToken();
      window.location.reload();
    })
    .then((err) => {
      console.log(err);
      message.error("Something went wrong");
    });

  return {
    type: LOGOUT,
  };
};
