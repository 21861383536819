import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Select,
    Table,
    Button,
    message,
    DatePicker,
} from "antd";
import dayjs from "dayjs";
import { connect } from "react-redux";
import "./customTable.css"
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

const { RangePicker } = DatePicker;

const ApprovedStatus = ({ authToken }) => {
    const [loading, setLoading] = useState(false);
    const [pendingData, setPendingData] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState("all");
    const currentDate = dayjs();
    const firstDayOfMonth = dayjs(currentDate)
        .startOf("month")
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .set("millisecond", 0);
    const lastDayOfMonth = dayjs(currentDate)
        .set("hour", 23)
        .set("minute", 59)
        .set("second", 59)
        .set("millisecond", 999);

    const [dateRange, setDateRange] = useState([firstDayOfMonth, lastDayOfMonth]);

    const dateFilter = async (date, dateString) => {
        const localDate = [
            dayjs(new Date(dateString[0]).setHours(0, 0, 0)),
            dayjs(new Date(dateString[1]).setHours(23, 59, 59)),
        ];
        setDateRange(localDate);
    };

    useEffect(() => {
        fetchData();
    }, [dateRange, selectedPlan]);

    // TABLE COLUMNS
    const columns = [
        {
            title: "Date & Time",
            dataIndex: "date",
            key: "date",
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
            render: (text) => {
                if (text) {
                    const date = new Date(text);
                    const formattedDate = date.toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                    }).replace(/\//g, "-");

                    const formattedTime = date
                        .toLocaleTimeString("en-GB", {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                        })
                        .toUpperCase();

                    return (
                        <>
                            {formattedDate} <br />
                            {formattedTime}
                        </>
                    );
                }
                return null;
            }
        },
        {
            title: "Lab Details",
            dataIndex: "labDetails",
            key: "labDetails",
            render: (_, record) => {
                return (
                    <div>
                        {record?.labDetails[0]}<br />+{record?.labDetails[1] + ' ' + record?.labDetails[2]}
                    </div>
                );
            },
        },
        {
            title: "Account Number",
            dataIndex: "accountNumber",
            key: "accountNumber",
        },
        {
            title: "Holder Name",
            dataIndex: "holderName",
            key: "holderName",
        },
        {
            title: "IFSC Code",
            dataIndex: "ifsc",
            key: "ifsc",
        },
        {
            title: "Email Id",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Request type",
            dataIndex: "reqType",
            key: "reqType",
            render: (_, record) => {
                return (
                    <div
                        style={{
                            border: record?.reqType === "change" ? "1px solid #FFD591" : "1px solid #C4DCFF",
                            fontWeight: "400",
                            fontSize: "14px",
                            background: record?.reqType === "change" ? "#FFF7E6" : "#F0F7FF",
                            borderRadius: "2px",
                            color: record?.reqType === "change" ? "#FA8C16" : "#4678EE",
                            width: "75px",
                            height: "27px",
                            textAlign: "center",
                            padding: "1px 8px",
                        }}
                    >
                        {record?.reqType === "change" ? "Change" : "New"}
                    </div>
                );
            },
        },
        {
            title: "Approved Date & Time",
            key: "approvedDateAndTime",
            render: (_, record) => {
                if (record?.approvedDateAndTime) {
                    const date = new Date(record?.approvedDateAndTime);
                    const formattedDate = date.toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                    }).replace(/\//g, "-");

                    const formattedTime = date
                        .toLocaleTimeString("en-GB", {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                        })
                        .toUpperCase();

                    return (
                        <>
                            {formattedDate} <br />
                            {formattedTime}
                        </>
                    );
                }
                return null;
            }
        },
    ];

    const reqTypeOptions = [
        {
            value: "all",
            label: "All",
        },
        {
            value: "add",
            label: "New",
        },
        {
            value: "change",
            label: "Change",
        }
    ]

    const fetchData = async () => {
        try {

            setLoading(true);
            if (selectedPlan === 'all') {
                const res = await axios.get(
                    `/razorpay/admin-get?status=approved&lte=${dateRange[1]}&gte=${dateRange[0]}`,
                    {
                        headers: {
                            Authorization: `Custom ${authToken}`,
                        },
                    },
                );
                setPendingData(res?.data);
                setLoading(false);
            } else {
                const res = await axios.get(
                    `/razorpay/admin-get?status=approved&lte=${dateRange[1]}&gte=${dateRange[0]}&requestType=${selectedPlan}`,
                    {
                        headers: {
                            Authorization: `Custom ${authToken}`,
                        },
                    },
                );
                setPendingData(res?.data);
                setLoading(false);
            }

        } catch (error) {
            message.error(error);
            setLoading(false);
        }
    };

    const tableData = pendingData.map((data) => {
        return {
            date: data.createdAt,
            labDetails: [data?.lab?.name, data?.lab?.countryCode, data?.lab?.contact],
            accountNumber: data?.accountNumber,
            holderName: data?.accountHolderName,
            ifsc: data?.ifsc,
            email: data?.email,
            reqType: data?.requestType,
            id: data?._id,
            lab: data?.lab?._id,
            approvedDateAndTime: data?.updatedAt,
        };
    });

    const exportData = pendingData?.map((data) => {
        return {
            "Date&Time": `${dayjs(data.createAt).format("DD-MM-YYYY hh:mm A")}`,
            "Lab Details": `${data?.lab?.name}  +${data?.lab?.countryCode} ${data?.lab?.contact}`,
            "Account Number": data?.accountNumber,
            "Holder Name": data?.accountHolderName,
            "IFSC Code": data?.ifsc,
            "Email Id": data?.email,
            "Request type": data?.requestType,
            "Approved Date & Time": `${dayjs(data.updatedAt).format("DD-MM-YYYY hh:mm A")}`,
        };
    });

    const exportToCSV = (csvData, fileName) => {
        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";

        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
        <div style={{ borderRadius: "4px", border: "1px solid #D9D9D9", background: "#FFF" }}>
            <div style={{ padding: "16px", display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
                <div style={{ color: "rgba(0, 0, 0, 0.85)", fontWeight: "500", fontSize: "16px" }}>
                    Razorpay Integration
                </div>
                <div style={{ display: "flex", gap: "15px" }}>
                    <Select
                        allowClear={true}
                        popupClassName="popup__menu"
                        style={{ width: 200 }}
                        options={reqTypeOptions}
                        placeholder="Request type"
                        value={selectedPlan}
                        onChange={(value) => setSelectedPlan(value)}
                    />
                    <RangePicker
                        style={{ width: 240 }}
                        value={dateRange}
                        onChange={dateFilter}
                        allowClear={false}
                        format="YYYY-MM-DD"
                        presets={[
                            {
                                label: "Today",
                                value: [dayjs().startOf("day"), dayjs().endOf("day")],
                            },
                            {
                                label: "Last 5 days",
                                value: [dayjs().add(-5, "d"), dayjs()],
                            },
                            {
                                label: "This Week",
                                value: [dayjs().startOf("week"), dayjs().endOf("week")],
                            },
                            {
                                label: "This Month",
                                value: [dayjs().startOf("month"), dayjs().endOf("month")],
                            },
                            {
                                label: "Last 30 Days",
                                value: [dayjs().add(-30, "d"), dayjs()],
                            },
                        ]}
                    />
                    <Button
                        popupClassName="popup__menu"
                        onClick={() =>
                            exportToCSV(exportData, "Razorpay Integration - Approved Status")
                        }
                    >
                        Export
                    </Button>
                </div>
            </div>
            <Table
                dataSource={tableData}
                columns={columns}
                loading={loading}
                pagination={false}
                className="custom-table"
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        authToken: state.auth.token,
    };
};

export default connect(mapStateToProps)(ApprovedStatus);
