import React, { useEffect, useState } from 'react';
import { Modal, Button, Input, Badge, Empty, Divider } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { updateSupportPerson } from './helper/apiService';
import dayjs from 'dayjs';

const { TextArea } = Input;

const RemarkModal = ({ userId, remark, setIsChange, ticketID }) => {
  const [visible, setVisible] = useState(false);
  const [remarks, setRemarks] = useState([]);
  const [newRemark, setNewRemark] = useState('');
  const [loading, setLoading] = useState(false);

  const handleOpen = () => setVisible(true);
  const handleClose = () => setVisible(false);

  useEffect(() => {
    if (remark) {
      setRemarks(remark);
    }
  }, [remark]);

  const handleAddRemark = () => {
    if (newRemark.trim() !== '') {
      const data = {
        remarks: [...remarks, {
          message: newRemark,
          createdBy: userId,
          createdAt: new Date()
        }],
      }
      handleAddRemarkAPI({ id: ticketID, data: data });
      setNewRemark('');
    }
  };
  const handleAddRemarkAPI = (value) => updateSupportPerson(value, setLoading, setIsChange)

  return (
    <div>
      <Badge size="small" count={remarks.length} color="blue">
        <Button style={{
          height: 32,
          width: 32,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "16px",
        }}
          onClick={handleOpen} >
          <MessageOutlined style={{
            fontSize: "16px",
          }} />
        </Button>
      </Badge>
      <Modal
        title="Remarks"
        visible={visible}
        onCancel={handleClose}
        centered
        width={410}
        footer={[
          <Button key="add" type="primary" onClick={handleAddRemark}>
            Add
          </Button>,
        ]}
      >
        <Divider style={{ margin: "10px 0" }} />
        <div style={{ minHeight: '300px', overflowY: 'auto', display: "flex", flexDirection: "column", gap: 10 }}>
          {remarks.length === 0 && <div style={{ minHeight: '300px', display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}> <Empty description="Opps! no any remarks yet." style={{ color: "red" }} /></div>}
          {remarks.map((remark, index) => (
            <div key={index} style={{ width: "100%" }}>
              <p style={{ fontSize: 12, color: "rgba(0,0,0,0.4)" }}>{remark?.createdBy?.name ? remark?.createdBy?.name : remark?.createdBy?.email} - {dayjs(remark.createdAt).format('MMMM D [at] HH:mm')}</p>
              <div style={{ padding: "4px 12px", borderRadius: 4, background: "#00000014", fontSize: 14 }}>
                <p>{remark.message}</p>
              </div>
            </div>
          ))}
        </div>
        <TextArea
          value={newRemark}
          onChange={(e) => setNewRemark(e.target.value)}
          placeholder="Write here..."
          style={{
            borderRadius: "4px",
            marginTop: "20px",
          }}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.id,
    userRole: state.auth.userRole,
    name: state.auth.name,
  };
};

export default connect(mapStateToProps)(RemarkModal);