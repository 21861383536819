import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "./../../asssets/brand/Flabs.svg";
import { logout } from "./../../Redux/UserAuth/authActions";
import razorpayLogo from "../../asssets/razorpayLogo.svg";
import razorpayActive from "../../asssets/razorpayActive.svg";
import Icon, {
  DashboardOutlined,
  MessageOutlined,
  IdcardOutlined,
  BarsOutlined,
  PullRequestOutlined,
  CloudUploadOutlined,
  CustomerServiceOutlined,
  BulbOutlined,
  DingtalkOutlined,
  FileOutlined,
  LinkOutlined,
} from "@ant-design/icons";

import { ArrowLeftRight } from "lucide-react"
import "./Header.css";
import { connect } from "react-redux";
import { Layout, Menu, Dropdown } from "antd";

const { Sider } = Layout;

const allUserMainRoles = ["sales", "support", "integration", "product", "support-admin"]

const navigationMenu = [
  {
    key: "1",
    id: "dashboard",
    name: "Dashboard",
    url: "/dashboard",
    itemClass: "bx bxs-inbox inbox-icon",
    roles: ["admin", "user"],
    userMainRole: allUserMainRoles,
    icon: <DashboardOutlined style={{ fontSize: "18px" }} />,
  },
  {
    key: "2",
    id: "blogs",
    name: "Blogs",
    url: "/blogs",
    itemClass: "bx bx-file-blank",
    roles: ["admin", "seo"],
    icon: <IdcardOutlined style={{ fontSize: "18px" }} />,
  },
  {
    key: "12",
    id: "transaction",
    name: "Transaction",
    url: "/transaction",
    itemClass: "bx bx-star",
    roles: ["admin"],
    icon: <Icon component={ArrowLeftRight} style={{ fontSize: "18px" }} />,
  },
  {
    key: "3",
    id: "subscription",
    name: "Subscriptions",
    url: "/subscription",
    itemClass: "bx bx-star",
    roles: ["admin"],
    icon: <BarsOutlined style={{ fontSize: "18px" }} />,
  },
  {
    key: "4",
    id: "bulk-message",
    name: "Bulk Message",
    url: "/bulk-message",
    itemClass: "bx bx-star",
    roles: ["admin"],
    icon: <MessageOutlined style={{ fontSize: "18px" }} />,
  },
  {
    key: "5",
    id: "ticket",
    name: "Ticket",
    url: "/ticket",
    itemClass: "bx bx-star",
    roles: ["admin", "user"],
    userMainRole: allUserMainRoles,
    icon: <CustomerServiceOutlined style={{ fontSize: "18px" }} />,
  },
  {
    key: "6",
    id: "update",
    name: "Update History",
    url: "/update-history",
    itemClass: "bx bx-star",
    roles: ["admin", "user"],
    userMainRole: ["product"],
    icon: <CloudUploadOutlined style={{ fontSize: "18px" }} />,
  },
  {
    key: "7",
    id: "features",
    name: "Ongoing Features",
    url: "/ongoing-feature",
    itemClass: "bx bx-star",
    roles: ["admin", "user"],
    userMainRole: ["product"],
    icon: <PullRequestOutlined style={{ fontSize: "18px" }} />,
  },
  {
    key: "8",
    id: "leadTracking",
    name: "Lead Tracking",
    url: "/lead-tracking",
    itemClass: "bx bx-star",
    roles: ["admin", "seo"],
    icon: <BulbOutlined style={{ fontSize: "18px" }} />,
  },
  {
    key: "9",
    id: "razorpayIntegration",
    name: "Razorpay",
    url: "/razorpay-integration",
    itemClass: "bx bx-star",
    roles: ["admin"],
    // icon: <DingtalkOutlined style={{ fontSize: "18px" }} />,
    icon: <img src={razorpayLogo} style={{ width: "20px", height: "20px" }} />,
    selectedIcon: <img src={razorpayActive} style={{ width: "20px", height: "20px" }} />,
  },
  {
    key: "10",
    id: "smartReport",
    name: "Smart Report",
    url: "/smart-report",
    itemClass: "bx bx-star",
    roles: ["admin"],
    icon: <FileOutlined style={{ fontSize: "18px" }} />,
  },
  {
    key: "11",
    id: "labsLink",
    name: "Labs Link",
    url: "/labs-link",
    itemClass: "bx bx-star",
    roles: ["admin"],
    icon: <LinkOutlined style={{ fontSize: "18px" }} />,
  },
  // {
  //   key: "5",
  //   id: "manual-payment",
  //   name: "Manual Payment",
  //   url: "/manual-payment",
  //   itemClass: "bx bx-star",
  //   roles: ["admin"],
  //   icon: <EditOutlined style={{ fontSize: "18px" }} />,
  // },

];

const Header = ({ userRole, name, contact, userMainRole }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const [selectedItem, setSelectedItem] = useState();
  const [isCollapsed, setIsCollapsed] = useState(
    sessionStorage.getItem("isCollapsed") || true,
  );
  const [selectedOption, setSelectedOption] = useState(currentPath);
  const containerRef = useRef(null);

  useEffect(() => {
    // Update the selected item based on the current location
    const selectedItemFromLocation = navigationMenu.find(
      (item) => item.url === location.pathname,
    );
    if (selectedItemFromLocation) {
      setSelectedItem(selectedItemFromLocation.key);
    }
  }, [location]);

  useEffect(() => {
    sessionStorage.setItem("isCollapsed", isCollapsed);
  }, [isCollapsed]);

  useEffect(() => {
    const baseRoute = currentPath.split("/")[1];
    setSelectedOption(`/${baseRoute}`);
  }, [currentPath]);

  const handleSidebarClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  const logoutHandler = () => {
    dispatch(logout());
    // navigate("/login");
  };

  const items = [
    {
      key: "1",
      label: "Log Out",
      onClick: logoutHandler,
    },
  ];

  return (
    <>
      <Helmet>
        <link
          href="https://unpkg.com/boxicons@2.0.9/css/boxicons.min.css"
          rel="stylesheet"
        />
      </Helmet>
      <Sider
        trigger={null}
        collapsible
        collapsed={isCollapsed}
        className={`side-bar ${isCollapsed ? "collapse" : ""}`}
      >
        <div className="logo-name-wrapper">
          <div className="logo-name">
            <Logo />
          </div>
          <button className="logo-name__button" onClick={handleSidebarClick}>
            <i
              className={`bx ${isCollapsed ? "bx-arrow-from-left" : "bx-arrow-from-right"
                } logo-name__icon`}
              id="logo-name__icon"
            ></i>
          </button>
        </div>

        <Menu
          className="menu"
          theme="light"
          mode="inline"
          selectedKeys={[selectedItem]}
          style={{
            border: "none",
          }}
        >
          {navigationMenu.map((item) => {
            if (!item.roles.includes(userRole)) return null;

            if (userRole === "admin" || userRole === "seo" || (userRole === "user" && item?.userMainRole?.includes(userMainRole))) {
            } else {
              return null;
            }

            const isSelected = item.key === selectedItem;
            return (
              <Menu.Item
                key={item.key}
                onClick={() => setSelectedItem(item.key)}
              >
                <Link to={item.url} style={{ display: 'flex', alignItems: 'center' }}>
                  {isSelected && item.selectedIcon ? item.selectedIcon : item.icon}
                  <span style={{ marginLeft: "23px" }}>
                    {item.name}
                  </span>
                </Link>
              </Menu.Item>
            );
          })}
        </Menu>

        <div className="chat-list bottom-profile">
          <span>
            <img
              src="/user-img.png"
              alt="avatar"
              className="chat-item-avatar"
            />
          </span>
          <span className="chat-item-name">
            <div className="user-icon-name-numbrt">
              <span className="user-icon-name">{name || "Flabs"}</span>
              <span className="user-icon-name-number">
                {contact || "9876XXXXXX"}
              </span>
            </div>
            <Dropdown menu={{ items }} className="three-dots-wrap">
              <span>&#8230;</span>
            </Dropdown>
          </span>
        </div>
      </Sider>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userRole: state.auth.userRole,
    name: state.auth.name,
    contact: state.auth.contact,
    userMainRole: state.auth.userMainRole,
  };
};

export default connect(mapStateToProps)(Header);
