import { CloudUploadOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Flex,
  Input,
  message,
  Radio,
  Upload,
} from "antd";
import React, { useState } from "react";

const { TextArea } = Input;

const AddBanner = () => {
  const [checked, setChecked] = useState(false);
  const props = {
    name: "file",
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  return (
    <div style={{ marginTop: 10 }}>
      {/* <Checkbox
        style={{ margin: "15px 0" }}
        onChange={(e) => setChecked(e.target.checked)}
      >
        Banner
      </Checkbox> */}
      {checked && (
        <>
          <Flex style={{ width: "100%" }}>
            <Card
              title="Desktop"
              size="small"
              style={{
                width: "100%",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                borderColor: "#d9d9d9",
                borderBottomLeftRadius: 0,
                borderRight: "none",
              }}
              bodyStyle={{
                borderTopLeftRadius: 0,
              }}
              actions={[]}
            >
              <Flex vertical gap={8}>
                <Flex vertical gap={3}>
                  <span>Content Line 1</span>
                  <TextArea />
                </Flex>
                <Flex vertical gap={3}>
                  <span>Content Line 2</span>
                  <TextArea />
                </Flex>
                <Flex vertical gap={3}>
                  <span>Add Image</span>
                  <Upload {...props}>
                    <Button icon={<CloudUploadOutlined />}>Upload Image</Button>
                  </Upload>
                </Flex>
              </Flex>
            </Card>
            <Card
              title="Mobile"
              size="small"
              style={{
                width: "100%",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderColor: "#d9d9d9",
              }}
              actions={[]}
            >
              {" "}
              <Flex vertical gap={8}>
                <Flex vertical gap={3}>
                  <span>Content</span>
                  <TextArea />
                </Flex>
              </Flex>
            </Card>
          </Flex>
          <Flex
            vertical
            gap={8}
            justify="flex-start"
            align="flex-start"
            style={{
              width: "100%",
              padding: 12,
              border: "1px solid #d9d9d9",
              borderTop: 0,
              borderBottomLeftRadius: 8,
              borderBottomRightRadius: 8,
            }}
          >
            <Radio.Group onChange={() => { }} value={1}>
              <Radio value={1}>Download</Radio>
              <Radio value={2}>Start Free Trail</Radio>
            </Radio.Group>
            <Flex
              vertical
              gap={3}
              justify="flex-start"
              align="flex-start"
              style={{ width: "50%" }}
            >
              <span style={{ color: "black" }}>File Upload</span>
              <Upload {...props} style={{ width: "100%" }}>
                <Button icon={<CloudUploadOutlined />}>Upload Image</Button>
              </Upload>
            </Flex>
          </Flex>
        </>
      )}
    </div>
  );
};

export default AddBanner;
