import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Space,
  Card,
  Input,
  Checkbox,
  Flex,
  DatePicker,
  message,
  Divider,
} from "antd";

import { Select } from "antd";
import "../../styles/EditLab.css";
import axios from "axios";
import moment from "moment";
import dayjs from "dayjs";
import { connect } from "react-redux";

const EditLab = ({
  editLabOpen,
  setEditLabOpen,
  plans,
  labId,
  authToken,
  userRole,
}) => {
  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Custom ${authToken}`,
      },
    };
    (async () => {
      const response = await axios.get(
        `/admin-dashboard/getLabData?labID=${labId}`,
        config,
      );
      setFormData(response.data);
    })();
  }, [labId, authToken]);

  // Function to handle form field changes
  const handleChange = (event) => {
    const { name, checked, type } = event.target;
    console.log("name", name, "checked", checked, "type", type);
    if (type === "checkbox") {
      const [nestedKey, nestedProperty] = name.split(".");
      console.log("nestedKey", nestedKey, nestedProperty);
      if (nestedProperty) {
        setFormData((prevData) => ({
          ...prevData,
          [nestedKey]: {
            ...prevData[nestedKey],
            [nestedProperty]: checked,
          },
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: checked,
        }));
      }
    }

    if (type === "text") {
      const [nestedKey, nestedProperty] = name.split(".");

      if (nestedProperty) {
        setFormData((prevData) => ({
          ...prevData,
          [nestedKey]: {
            ...prevData[nestedKey],
            [nestedProperty]: event.target.value,
          },
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: event.target.value,
        }));
      }
    }
  };

  console.log("formdata", formData);

  const submit = async () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Custom ${authToken}`,
      },
    };
    const upDatedFormData = {
      ...formData,
      billTotalLimit: parseInt(formData.billTotalLimit, 10),
      labAdminID: {
        ...formData.labAdminID,
        contact: parseInt(formData.labAdminID.contact, 10),
      },
    };
    try {
      const response = await axios.post(
        "/admin-dashboard/updateLab",
        upDatedFormData,
        config,
      );
      if (response.data === true) {
        const labData = await axios.get(
          `/admin-dashboard/getLabData?labID=${labId}`,
          config,
        );
        setFormData(labData.data);
      }
      console.log("response", response);
      message.success("Lab updated successfully");
      setLoading(false);
    } catch (error) {
      message.error("Error updating lab");
      setLoading(false);
    }
    setLoading(false);
  };

  const handleCancel = () => {
    setEditLabOpen(!editLabOpen);
  };
  return (
    <div>
      <Modal
        title="Edit Lab"
        open={editLabOpen}
        onOk={submit}
        onCancel={handleCancel}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={submit}
          >
            Save
          </Button>,
        ]}
        width={450}
        centered
      >
        <Divider style={{ margin: 0 }} />
        <div className="edit-lab-main">
          <Space
            direction="vertical"
            size={16}
            style={{
              width: "100%",
              height: `${userRole !== "admin" ? "auto" : "533px"}`,
            }}
            id="edit-lab"
          >
            <SubscriptionDetails
              plans={plans}
              formData={formData}
              handleChange={handleChange}
              setFormData={setFormData}
              userRole={userRole}
            />
            {userRole === "admin" && (
              <BillLimit formData={formData} handleChange={handleChange} />
            )}
            {userRole === "admin" && (
              <SendBillAndReport
                formData={formData}
                handleChange={handleChange}
              />
            )}
            {userRole === "admin" && (
              <AddOns formData={formData} setFormData={setFormData} />
            )}
          </Space>
        </div>
      </Modal>
    </div>
  );
};

const SubscriptionDetails = ({
  plans,
  formData,
  handleChange,
  setFormData,
  userRole,
}) => {
  const onChange = (value) => {
    console.log(value);
    setFormData((prevData) => ({
      ...prevData,
      plan: plans.filter((plan) => plan.value === value)[0]?.planId,
    }));
  };

  useEffect(() => {
    if (userRole === "user") {
      setFormData((prevData) => ({
        ...prevData,
        plan: plans.filter((plan) => plan.label === "Extended Trial")[0]
          ?.planId,
      }));
    }
  }, [userRole, plans, formData.plan]);

  if (userRole !== "admin") {
    plans = plans.filter((plan) => plan.label === "Extended Trial");
  }

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Card
      title="Subscription details"
      style={{
        padding: "0px",
        border: "2px solid #f0f0f0",
        marginTop: "20px",
      }}
    >
      <div className="edit-lab-item">
        <p>Subscription End Date</p>
        <DatePicker
          value={dayjs(formData?.subEndDate)}
          style={{ width: "100%" }}
          format="YYYY-MM-DD hh:mm A"
          allowClear={false}
          showTime={{
            defaultValue: dayjs("00:00:00", "hh:mm"),
          }}
          onChange={(date, dateString) => {
            setFormData({
              ...formData,
              subEndDate: new Date(date.toDate()),
            });
          }}
        />
      </div>
      <div className="edit-lab-item">
        <p>Select Plan</p>
        <Select
          showSearch
          placeholder={
            plans.filter((plan) => plan.planId === formData.plan)[0]?.value
          }
          optionFilterProp="children"
          onChange={onChange}
          filterOption={filterOption}
          style={{ width: "100%" }}
          options={plans}
        />
      </div>
      <div className="edit-lab-item">
        <p>Admin Phone Number</p>
        <Input
          type="phone"
          name="labAdminID.contact"
          value={formData.labAdminID?.contact}
          onChange={handleChange}
        />
      </div>
    </Card>
  );
};

const BillLimit = ({ formData, handleChange }) => {
  return (
    <Card
      title="Bill Limit"
      style={{
        width: "100%",
        border: "2px solid #f0f0f0",
      }}
    >
      <div className="edit-lab-item">
        <p>Bill Limit</p>
        <Input
          name="billTotalLimit"
          value={formData.billTotalLimit}
          onChange={handleChange}
        />
      </div>
      <div>
        <Flex justify="space-between" style={{ marginTop: "15px" }}>
          <Checkbox
            name="billLimitAlert"
            checked={formData.billLimitAlert}
            onChange={handleChange}
          >
            Bill Limit Alert
          </Checkbox>
        </Flex>
      </div>
    </Card>
  );
};

const SendBillAndReport = ({ formData, handleChange, setFormData }) => {
  return (
    <Card
      title="Send Bill and Report"
      style={{
        width: "100%",
        border: "2px solid #f0f0f0",
      }}
    >
      <div className="edit-lab-item">
        <p>Send Bill</p>
        <Flex justify="space-between" style={{ marginTop: "15px" }}>
          <Checkbox
            name="bill_settings.bill_sms"
            checked={formData.bill_settings.bill_sms}
            onChange={handleChange}
          >
            SMS
          </Checkbox>
          <Checkbox
            name="bill_settings.bill_email"
            checked={formData.bill_settings.bill_email}
            onChange={handleChange}
          >
            Email
          </Checkbox>
          <Checkbox
            name="bill_settings.bill_whatsapp"
            checked={formData.bill_settings.bill_whatsapp}
            onChange={handleChange}
          >
            WhatsApp
          </Checkbox>
        </Flex>
      </div>
      <div className="edit-lab-item">
        <p>Send Report</p>
        <Flex justify="space-between" style={{ marginTop: "15px" }}>
          <Checkbox
            name="report_settings.sms"
            checked={formData.report_settings.sms}
            onChange={handleChange}
          >
            SMS
          </Checkbox>
          <Checkbox
            name="report_settings.email"
            checked={formData.report_settings.email}
            onChange={handleChange}
          >
            Email
          </Checkbox>
          <Checkbox
            name="report_settings.whatsapp"
            checked={formData.report_settings.whatsapp}
            onChange={handleChange}
          >
            WhatsApp
          </Checkbox>
        </Flex>
      </div>
    </Card>
  );
};

const AddOns = ({ formData, handleChange, setFormData }) => {
  return (
    <Card
      title="Add Ons"
      style={{
        width: "100%",
        boxShadow: "0px 0px 0px 0px",
        border: "2px solid #f0f0f0",
      }}
    >
      <Flex justify="start" gap={30}>
        <Checkbox
          name="isRadiologyLab"
          checked={formData.isRadiologyLab}
          onChange={() =>
            setFormData((prevData) => ({
              ...prevData,
              isRadiologyLab: !prevData.isRadiologyLab,
            }))
          }
        >
          Radiology
        </Checkbox>
        <Checkbox
          name="isInventoryFeature"
          checked={formData.isInventoryFeature}
          onChange={() =>
            setFormData((prevData) => ({
              ...prevData,
              isInventoryFeature: !prevData.isInventoryFeature,
            }))
          }
        >
          Inventory
        </Checkbox>
        <Checkbox
          name="isOutSourceFeature"
          checked={formData.isOutSourceFeature}
          onChange={() =>
            setFormData((prevData) => ({
              ...prevData,
              isOutSourceFeature: !prevData.isOutSourceFeature,
            }))
          }
        >
          Outsource
        </Checkbox>
      </Flex>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    userRole: state.auth.userRole,
  };
};

export default connect(mapStateToProps)(EditLab);
