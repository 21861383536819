import React, { useEffect, useState } from "react";
import Header from "../Base/Header";
import styles from "../Ticket/ticket.module.css";
import { Button, Flex, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import AddUpdateModal from "./AddUpdateModal";
import dayjs from "dayjs";
import { connect } from "react-redux";

const UpdateHistory = ({ userRole, userMainRole }) => {
  const [openAddUpdate, setOpenAddUpdate] = useState(false);
  const [updateHistory, setUpdateHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchUpdateHistory = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/software-history/getAll");
      if (response.data) {
        response.data = response.data.reverse();
      }
      setUpdateHistory(response.data);
    } catch (error) {
      console.log("Error fetching update history", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUpdateHistory();
  }, []);

  const columns = [
    {
      title: (<span style={{ whiteSpace: 'nowrap' }}>Date & Time</span>),
      dataIndex: "createdAt",
      key: "dateTime",
      render: (value) => (
        <div style={{ whiteSpace: 'nowrap' }}>
          <div>{dayjs(value).format('DD-MM-YYYY')}</div>
          <div>{dayjs(value).format('hh:mm A')}</div>
        </div>
      ),
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Message",
      dataIndex: "description",
      key: "message",
    },
    {
      title: "Plans",
      dataIndex: "plans",
      key: "plans",
      render: (value) => (<div>{value?.map((item) => item?.name).join(", ")}</div>)
    },
    {
      title: "Units",
      dataIndex: "units",
      key: "units",
      render: (value) => (<div>{value?.join(", ")}</div>)
    },
    {
      title: "Role",
      dataIndex: "roles",
      key: "role",
      render: (value) => (<div>{value?.join(", ")}</div>)
    },
  ];

  return (
    <>
      <Header />
      <section className={styles["blog-section-container"]}>
        <Flex style={{ justifyContent: "space-between", alignItems: "center" }}>
          <p style={{ fontSize: "16px", color: "#707070", fontWeight: "500" }}>
            Update History
          </p>
          {userRole === "admin" ? (
            <Button
              type="primary"
              style={{ borderRadius: "2px" }}
              onClick={() => setOpenAddUpdate(true)}
            >
              <PlusOutlined />
              New Update
            </Button>
          ) : null}

        </Flex>
        <Table
          dataSource={updateHistory}
          columns={columns}
          pagination={false}
          loading={loading}
          style={{
            border: "1px solid #D9D9D9",
            borderRadius: "4px",
            marginTop: "20px",
          }}
        />
        <AddUpdateModal
          openAddUpdate={openAddUpdate}
          setOpenAddUpdate={setOpenAddUpdate}
          setLoading={setLoading}
          fetchUpdateHistory={fetchUpdateHistory}
        />
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userRole: state.auth.userRole,
    userMainRole: state.auth.userMainRole,
  };
};


export default connect(mapStateToProps)(UpdateHistory);
