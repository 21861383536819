import { Select, message } from "antd";
import axios from "axios";
import React from "react";

const LeadOwner = ({ leadOwners, value, labId, authToken }) => {
  const updateLeadOwner = (value) => {
    const config = {
      headers: {
        Authorization: `Custom ${authToken}`,
      },
    };
    axios
      .post(
        "/admin-dashboard/updateLeadOwner",
        {
          leadOwner: value,
          labId: labId,
        },
        config
      )
      .then((res) => {
        message.success("Lead Owner Updated");
      })
      .catch((err) => {
        message.error("Error Updating Lead Owner");
      });
  };
  return (
    <div>
      <Select
        value={value}
        style={{ width: 120 }}
        options={leadOwners}
        showSearch
        onChange={(value) => updateLeadOwner(value)}
      ></Select>
    </div>
  );
};

export default LeadOwner;
