import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  CURRENT_USER_SUCCESS,
  CURRENT_USER_FAILURE,
  CURRENT_USER,
} from "./authTypes";

const tokenFromLocalStorage = localStorage.getItem("tokenv3");

const initialState = {
  isAuthenticated: false,
  token: tokenFromLocalStorage,
  error: null,
  userRole: "",
  loading: true,
  name: "",
  contact: "",
  userRole: "",
  id: "",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        userRole: action.payload.userRole,
        error: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        error: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        error: null,
      };
    case CURRENT_USER:
      return {
        ...state,
        loading: true,
      };
    case CURRENT_USER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        userRole: action.payload.userRole,
        name: action.payload.name,
        contact: action.payload.contact,
        userMainRole: action.payload.userMainRole,
        id: action.payload.id,
        loading: false,
        error: null,
      };
    case CURRENT_USER_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
