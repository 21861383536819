import React, { useEffect, useState } from 'react';
import { PlusOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Checkbox, Flex, Image, message, Upload } from 'antd';
import axios from 'axios';

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
        return Upload.LIST_IGNORE; // Prevent upload
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must be smaller than 2MB!');
        return Upload.LIST_IGNORE; // Prevent upload
    }
    return true;
};

const UpdateMessage = ({ authToken }) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([]);
    const [plans, setPlans] = useState([]);
    const [url, setUrl] = useState('');
    const [selectedPlans, setSelectedPlans] = useState([]);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList.slice(-1));

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const fetchPlansData = async () => {
        try {
            const config = {
                headers: {
                    Authorization: `Custom ${authToken}`,
                },
            };
            const response = await axios.get("/admin-dashboard/plans", config);

            const options = response?.data?.map((plan) => ({
                planId: plan._id,
                value: plan.name,
                label: plan.name,
            }));

            setPlans(options);
        } catch (error) {
            console.error("Error fetching plan data:", error);
            message.error("Error fetching plan data", 3);
        }
    };

    const handleUploadFile = async (options) => {
        const { onSuccess, onError, file, onProgress } = options;

        try {
            // Fetch presigned URL
            const fileType = file.name.split(".").pop();
            const response = await axios.get("/s3Url", {
                params: { fileType, iswebasset: true },
            });

            const { data } = response;

            // Upload to S3 using PUT
            const uploadResponse = await fetch(data.url, {
                method: "PUT",
                body: file,
                headers: {
                    "Content-Type": fileType,
                },
            });

            if (uploadResponse.status === 200) {
                // Construct the URLs after successful upload
                const imageUrl = `https://cdn.flabs.in/webassets/${data.name}.${fileType}`;
                setUrl(imageUrl);
                onSuccess({ url: imageUrl }, file);
                message.success(`Image uploaded successfully`);
            } else {
                throw new Error('Upload failed');
            }
        } catch (error) {
            console.error("Upload Error:", error);
            message.error(`Upload failed: ${error.message}`);
            onError(error);
        }
    };

    const handleUpdateMessage = async () => {
        try {
            const response = await axios.post("/admin-dashboard/updateMessage", {
                plans: selectedPlans,
                imageUrl: url,
            }, {
                headers: {
                    Authorization: `Custom ${authToken}`,
                },
            });

            if (response.status === 200) {
                message.success("Message send successfully");
                setSelectedPlans([]);
                setUrl('');
                setFileList([]);
            }
        } catch (error) {
            console.error("Error sending message:", error);
            message.error("Error sending message", 3);
        }
    }

    useEffect(() => {
        fetchPlansData();
    }, []);

    return (
        <Flex vertical gap={20} style={{ marginTop: 20, maxWidth: 600 }}>
            <h3 style={{ fontSize: 16, fontWeight: 700 }}>Update Message</h3>
            <div>
                <Upload
                    customRequest={handleUploadFile}
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    beforeUpload={beforeUpload}
                    maxCount={1}
                    accept='.jpg,.jpeg,.png'
                >
                    {fileList.length >= 1 ? null : uploadButton}
                </Upload>
                {previewImage && (
                    <Image
                        wrapperStyle={{
                            display: 'none',
                        }}
                        preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) => setPreviewOpen(visible),
                            afterOpenChange: (visible) => !visible && setPreviewImage(''),
                        }}
                        src={previewImage}
                    />
                )}
            </div>
            <Flex vertical>
                <p>Select Plan</p>
                <Flex gap={25} align='center' style={{ marginTop: 10 }}>
                    <Checkbox.Group onChange={(value) => setSelectedPlans(value)} value={selectedPlans}>
                        {plans.map((plan) => (
                            <Checkbox key={plan.planId} value={plan.planId} style={{ marginBottom: 5 }}>
                                {plan.label}
                            </Checkbox>
                        ))}
                    </Checkbox.Group>
                </Flex>
            </Flex>
            <Flex style={{ width: "100%" }} justify='flex-end'>
                <Button
                    type="primary"
                    style={{ borderRadius: 2 }}
                    disabled={fileList.length === 0}
                    onClick={handleUpdateMessage}
                >
                    <SendOutlined /> Send
                </Button>
            </Flex>
        </Flex>
    );
};

export default UpdateMessage;
