import React from "react";
import Header from "../Base/Header";
import styles from "../Blogs/Blogs.module.css";
import {
    Tabs,
} from "antd";
import PendingStatus from "./PendingStatus";
import ApprovedStatus from "./ApprovedStatus";

const RazorpayIntegration = () => {

    const tabItems = [
        {
            key: "1",
            label: "Pending",
            children: <PendingStatus />,
        },
        {
            key: "2",
            label: "Approved",
            children: <ApprovedStatus />,
        },
    ];

    return (
        <>
            <Header />
            <section className={styles["blog-section-container"]}>
                <Tabs
                    defaultActiveKey="1"
                    items={tabItems}
                    indicatorSize={(origin) => origin + 20}
                    tabBarStyle={{
                        width: "220px",
                    }}
                    centered
                />
            </section>
        </>
    );
};

export default RazorpayIntegration;
