import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import MarkdownEditor from "@uiw/react-markdown-editor";
import Header from "../Base/Header";
import styles from "./Blogs.module.css";
import { Form, Button, message, Input, Select, Flex, Checkbox } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import axios from "axios";
import BackButton from "../../asssets/back-btn.svg";

const EditBlog = ({ authToken }) => {
  const [blogInfo, setBlogInfo] = useState(null);
  const [markdown, setMarkdown] = useState("");
  const [categories, setCategories] = useState();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  // Markdown default light theme
  document.documentElement.setAttribute("data-color-mode", "light");

  useEffect(() => {
    fetchBlogData();
    fetchCategories();
  }, []);

  useEffect(() => {
    setMarkdown(blogInfo?.content);
  }, [blogInfo]);

  const fetchBlogData = async () => {
    try {
      const pathArray = window.location.pathname.split("/");
      const slug = pathArray[pathArray.length - 1];

      const res = await axios.get(`/blog-post/posts-slug?slug=${slug}`);
      setBlogInfo(res.data);
      form.setFieldsValue({
        title: res?.data.title,
        slug: res?.data.slug,
        time_for_read: res?.data.time_for_read,
        keywords: res?.data.keywords,
        category: res?.data.category.slug,
        short_description: res?.data.short_description,
        thumbnail: res?.data.thumbnail,
        contact_us_popup: res?.data.contact_us_popup,
        faq: res?.data.faq,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCategories = async () => {
    try {
      const { data } = await axios.get(`/blog-category/all`);
      setCategories(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCategoryFromSlug = (slug) => {
    const data = categories?.find((item) => item.slug === slug);
    return {
      _id: data?._id,
      title: data?.title,
      slug: data?.slug,
    };
  };

  const categoryOptions = categories?.map((item) => {
    return {
      label: item?.title,
      value: item?.slug,
    };
  });

  const updateBlog = async (values) => {
    try {
      const config = {
        headers: {
          Authorization: `Custom ${authToken}`,
        },
      };
      await axios.post(
        "/admin-dashboard/updateBlog",
        {
          blogId: blogInfo._id,
          blogBody: {
            ...values,
            category: getCategoryFromSlug(values.category),
            content: markdown,
          },
        },
        config
      );
      message.success("Blog updated successfully!", [2]);

      setTimeout(() => {
        navigate("/blogs");
      }, 1000);
    } catch (error) {
      message.error(error);
    }
  };

  return (
    <>
      <Header />
      <section className={styles["blog-section-container"]}>
        <div className={styles["blog-header-container"]}>
          <Link className={styles["add-blog-back-btn"]} to="/blogs">
            <img src={BackButton} alt="back-btn" />
            Back
          </Link>
        </div>
        <Form form={form} onFinish={updateBlog} layout="vertical">
          <div className={styles["add-blog-form-row"]}>
            <Form.Item
              label="Title"
              name="title"
              className={styles["add-blog-form-item"]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Slug"
              name="slug"
              className={styles["add-blog-form-item"]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className={styles["add-blog-form-row"]}>
            <Form.Item
              label="Time for Read"
              name="time_for_read"
              className={styles["add-blog-form-item"]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Keywords"
              name="keywords"
              className={styles["add-blog-form-item"]}
            >
              <Input.TextArea autoSize={{ minRows: 2 }} />
            </Form.Item>
          </div>
          <div className={styles["add-blog-form-row"]}>
            <Form.Item
              label="Category"
              name="category"
              className={styles["add-blog-form-item"]}
            >
              <Select allowClear options={categoryOptions} />
            </Form.Item>
            <Form.Item
              label="Short Description"
              name="short_description"
              className={styles["add-blog-form-item"]}
            >
              <Input.TextArea autoSize={{ minRows: 2 }} />
            </Form.Item>
          </div>
          <div className={styles["add-blog-form-row"]}>
            <Form.Item
              label="Thumbnail"
              name="thumbnail"
              className={styles["add-blog-form-item"]}
              style={{
                marginBottom: "30px",
              }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Contact Us Popup"
              name="contact_us_popup"
              className={styles["add-blog-form-item"]}
              valuePropName="checked"
            >
              <Checkbox>Contact Us Popup</Checkbox>
            </Form.Item>
          </div>
          <div
            style={{
              maxWidth: "100%",
            }}
          >
            <MarkdownEditor
              value={markdown}
              enableScroll={true}
              overflow={true}
              height="300px"
              visible={true}
              onChange={(value, viewUpdate) => setMarkdown(value)}
            />
          </div>
          <p
            style={{
              marginTop: "40px",
              marginBottom: "30px",
              color: "#707070",
              fontSize: "14px",
            }}
          >
            FAQs
          </p>
          <Form.List name="faq">
            {(fields, { add, remove }) => (
              <div className={styles["add-faq"]}>
                {fields.map(({ key, name, ...restField }) => (
                  <Flex
                    key={key}
                    align="center"
                    gap={20}
                    style={{
                      width: "100%",
                    }}
                  >
                    <Form.Item
                      className={styles["add-blog-form-item"]}
                      {...restField}
                      name={[name, "question"]}
                      rules={[{ required: true, message: "Missing Question" }]}
                    >
                      <Input.TextArea
                        placeholder={`Question`}
                        autoSize={{ minRows: 2 }}
                      />
                    </Form.Item>
                    <Form.Item
                      className={styles["add-blog-form-item"]}
                      {...restField}
                      name={[name, "answer"]}
                      rules={[{ required: true, message: "Missing Answer" }]}
                    >
                      <Input.TextArea
                        placeholder={`Answer`}
                        autoSize={{ minRows: 2 }}
                      />
                    </Form.Item>
                    <MinusCircleOutlined
                      onClick={() => remove(name)}
                      style={{
                        color: "red",
                        fontSize: "16px",
                      }}
                    />
                  </Flex>
                ))}
                <Button
                  type="dashed"
                  style={{
                    width: "500px",
                  }}
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add FAQs
                </Button>
              </div>
            )}
          </Form.List>
          <div style={{ textAlign: "right" }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        </Form>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token,
  };
};

export default connect(mapStateToProps)(EditBlog);
