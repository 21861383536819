import React, { useEffect, useState } from "react";
import {
  Button,
  Flex,
  Modal,
  Divider,
  Select,
  Checkbox,
  InputNumber,
  Image,
  Upload,
  message,
  Typography,
} from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";

import { connect } from "react-redux";
import axios from "axios";
import { getLabNames } from "../Ticket/helper/apiService";

const { Text } = Typography;

const AddLabModal = ({
  currentLab,
  isModalOpen,
  setIsModalOpen,
  selectedLab,
  type = "dafault",
  handleGetLabLinks,
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState([]);
  const [formData, setFormData] = useState(type === "edit" ? selectedLab : {});
  const [search, setSearch] = useState("");
  const [labsOptions, setLabsOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedLab) {
      setFileList([
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: selectedLab?.logo,
        },
      ]);
      setFormData(selectedLab);
      setPreviewImage(selectedLab?.logo);
    }
  }, [selectedLab]);

  useEffect(() => {
    if (!isModalOpen) {
      setFileList([])
    }
    if (type === 'add') {
      setFormData({})
    }
  }, [isModalOpen])

  useEffect(() => {
    if (!search || search === " ") {
      getLabsNamesData();
    } else {
      const timeOut = setTimeout(() => {
        getLabsNamesData(search);
      }, 800);
      return () => clearTimeout(timeOut);
    }
  }, [search]);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = ({ fileList: newFileList }) =>
    setFileList(newFileList.slice(-1));

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return Upload.LIST_IGNORE; // Prevent upload
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
      return Upload.LIST_IGNORE; // Prevent upload
    }
    return true;
  };

  const handleEdit = () => {
    setLoading(true);
    axios
      .put("/customLogin/update", formData)
      .then((res) => {
        if (res.status === 200) {
          message.success("Lab Updated Successfully");
          handleGetLabLinks();
          setIsModalOpen(false);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const handleCreateLab = () => {
    setLoading(true)
    axios
      .post("/customLogin/create", formData)
      .then((res) => {
        if (res.status === 200) {
          message.success("Lab Created Successfully");
          handleGetLabLinks();
          setIsModalOpen(false);
          setFormData({});
          setFileList([]);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const getLabsNamesData = async (labSearch = search) => {
    try {
      const res = await getLabNames(labSearch);
      if (res) {
        const options = res?.data?.map((item) => {
          return {
            label: item.name,
            desc: (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text>{item?.name}</Text>
                <Text style={{ fontSize: 10 }}>
                  {"+" + (item?.countryCode || "91") + "-" + item?.contact}
                </Text>
              </div>
            ),
            value: item._id,
          };
        });
        setLabsOptions(options);
      }
    } catch (err) {
      console.log({ err });
    }
  };

  const handleUploadFile = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    try {
      // Fetch presigned URL
      const fileType = file.name.split(".").pop();
      const response = await axios.get("/s3Url", {
        params: { fileType, iswebasset: true },
      });

      const { data } = response;

      // Upload to S3 using PUT
      const uploadResponse = await fetch(data.url, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": fileType,
        },
      });

      if (uploadResponse.status === 200) {
        // Construct the URLs after successful upload
        const imageUrl = `https://cdn.flabs.in/webassets/${data.name}.${fileType}`;
        setFormData({ ...formData, logo: imageUrl });
        onSuccess({ url: imageUrl }, file);
        message.success(`Image uploaded successfully`);
      } else {
        throw new Error("Upload failed");
      }
    } catch (error) {
      console.error("Upload Error:", error);
      message.error(`Upload failed: ${error.message}`);
      onError(error);
    }
  };

  return (
    <>
      <Modal
        title={`${type === "edit" ? "Edit" : "Add"} Lab`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        width={440}
        styles={{
          content: {
            padding: "16px 0px",
            boxShadow: "0px 1px 0px 0px #F0F0F0 inset",
            borderRadius: 6,
          },
          header: {
            padding: "0px 16px",
          },
          footer: {
            padding: "0 16px",
          },
        }}
        footer={[
          <Flex justify="flex-end" gap={8}>
            <Button
              type="primary"
              loading={loading}
              onClick={
                type === "edit" ? () => handleEdit() : () => handleCreateLab()
              }
            >
              Save
            </Button>
          </Flex>,
        ]}
      >
        <Divider style={{ margin: "10px 0" }} />
        <Flex
          vertical
          gap={16}
          style={{
            padding: "0px 16px",
          }}
        >
          <Flex vertical gap={8}>
            <span style={{ fontSize: 16 }}>Lab Name</span>
            {type === "edit" ? (
              <span style={{ color: "#8C8C8C" }}>{formData?.lab?.name}</span>
            ) : (
              <Select
                onSearch={(e) => setSearch(e)}
                placeholder="Search by lab name or contact"
                onSelect={(e) => setFormData({ ...formData, lab: e })}
                showSearch
                suffixIcon={<SearchOutlined />}
                value={formData?.lab}
                options={labsOptions}
                optionRender={(option) => option.data.desc}
                filterOption={false}
              />
            )}
          </Flex>
          <Flex vertical gap={8}>
            <span style={{ fontSize: 16 }}>Logo Upload</span>
            <div>
              <Upload
                customRequest={handleUploadFile}
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                beforeUpload={beforeUpload}
                maxCount={1}
                accept=".jpg,.jpeg,.png"
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
              {previewImage && (
                <Image
                  wrapperStyle={{
                    display: "none",
                  }}
                  preview={{
                    visible: previewOpen,
                    onVisibleChange: (visible) => setPreviewOpen(visible),
                    afterOpenChange: (visible) =>
                      !visible && setPreviewImage(""),
                  }}
                  src={previewImage}
                />
              )}
            </div>
          </Flex>
          <Flex vertical gap={8}>
            <span style={{ fontSize: 16 }}>Logo Height</span>
            <InputNumber
              defaultValue={0}
              placeholder="Logo Height"
              style={{
                width: "100%",
              }}
              value={formData?.logoHeight}
              onChange={(e) => setFormData({ ...formData, logoHeight: e })}
            />
          </Flex>
          <Flex vertical gap={8}>
            <span style={{ fontSize: 16 }}>Header Logo Height</span>
            <InputNumber
              defaultValue={0}
              placeholder="Header Logo Height"
              style={{
                width: "100%",
              }}
              value={formData?.headerLogoHeight}
              onChange={(e) => setFormData({ ...formData, headerLogoHeight: e })}
            />
          </Flex>
          <div style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "10px",
            marginTop: 5
          }}>
            <Checkbox
              style={{ borderRadius: 6 }}
              checked={formData?.isPatientLogin}
              onChange={(e) =>
                setFormData({ ...formData, isPatientLogin: e.target.checked })
              }
            >
              Patient’s Login
            </Checkbox>
            <Checkbox
              style={{ borderRadius: 6 }}
              checked={formData?.isPatientLogo}
              onChange={(e) =>
                setFormData({ ...formData, isPatientLogo: e.target.checked })
              }
            >
              Patient Logo
            </Checkbox>
            <Checkbox
              style={{ borderRadius: 6 }}
              checked={formData?.isLabLogin}
              onChange={(e) =>
                setFormData({ ...formData, isLabLogin: e.target.checked })
              }
            >
              Login
            </Checkbox>
            <Checkbox
              style={{ borderRadius: 6 }}
              checked={formData?.isMainLabLogo}
              onChange={(e) =>
                setFormData({ ...formData, isMainLabLogo: e.target.checked })
              }
            >
              Main Lab Logo
            </Checkbox>
            <Checkbox
              style={{ borderRadius: 6 }}
              checked={formData?.isB2BCenterLogo}
              onChange={(e) =>
                setFormData({ ...formData, isB2BCenterLogo: e.target.checked })
              }
            >
              B2B Center Logo
            </Checkbox>
          </div>
        </Flex>
        <Divider style={{ margin: "15px 0" }} />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  let currentLab = state?.user?.current_lab;
  let sampleCollectors = state?.user?.sampleCollectors;
  return {
    currentLab,
  };
};
export default connect(mapStateToProps)(AddLabModal);
