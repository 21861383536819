import { DeleteOutlined, RightSquareOutlined, SearchOutlined } from '@ant-design/icons';
import { Checkbox, Divider, Select, Input, Button, List, Space, Typography } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { getLabNames } from '../Ticket/helper/apiService';
const { Text } = Typography;

const Source = ({ mockData, selectedSourceKeys, handleCheckboxChange, allSelected, handleSelectAll, handleSearch }) => {
    return (
        <div>
            <Checkbox
                checked={allSelected}
                onChange={handleSelectAll}
                style={{
                    paddingLeft: '10px',
                }}
            >
                All
            </Checkbox>
            <Divider style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }} />
            <Space.Compact style={{ width: '100%' }}>
                <Input
                    allowClear
                    addonBefore={<SearchOutlined />}
                    placeholder="Search test"
                    onChange={(e) => handleSearch('source', e.target.value)}
                    style={{ marginBottom: 10, width: '100%', paddingLeft: '10px', paddingRight: '10px' }}
                />
            </Space.Compact>
            <div style={{ maxHeight: '220px', overflowY: 'auto' }}>
                <List
                    dataSource={mockData}
                    renderItem={(item) => (
                        <List.Item
                            style={{
                                backgroundColor: selectedSourceKeys.includes(item.key) ? '#E6F7FF' : '',
                                borderBottom: 'none',
                                paddingLeft: '10px',
                            }}
                        >
                            <Checkbox
                                checked={selectedSourceKeys.includes(item.key)}
                                onChange={() => handleCheckboxChange('source', item.key)}
                            >
                                {item.title}
                            </Checkbox>
                        </List.Item>
                    )}
                />
            </div>
        </div>
    );
};

const Destination = ({ mockData, handleDeleteItem, handleSearch }) => {
    return (
        <div>
            <Space.Compact style={{ width: '100%' }}>
                <Input
                    allowClear
                    addonBefore={<SearchOutlined />}
                    placeholder="Search test"
                    onChange={(e) => handleSearch('destination', e.target.value)}
                    style={{ marginBottom: 10, width: '100%' }}
                />
            </Space.Compact>
            <div style={{ maxHeight: '250px', overflowY: 'auto', padding: '0 20px' }}>
                <List
                    style={{ borderBottom: 'none' }}
                    dataSource={mockData}
                    renderItem={(item) => (
                        <List.Item
                            style={{
                                borderBottom: 'none',
                            }}
                        >
                            <span>{item.title}</span>
                            <DeleteOutlined
                                onClick={() => handleDeleteItem(item.key)}
                                style={{ color: 'red', cursor: 'pointer', marginLeft: '10px' }}
                            />
                        </List.Item>
                    )}
                />
            </div>
        </div>
    );
};

const TransferComp = ({ text }) => {
    const [departmentList, setDepartmentList] = useState([]);
    const [labID, setLabID] = useState('');
    const [departmentId, setDepartmentId] = useState('');
    const [mockData, setMockData] = useState([]);
    const [targetKeys, setTargetKeys] = useState([]);
    const [allSelected, setAllSelected] = useState(false);
    const [searchTerm, setSearchTerm] = useState({ source: '', destination: '' });
    const [selectedSourceKeys, setSelectedSourceKeys] = useState([]);
    const [departmentTests, setDepartmentTests] = useState([]);
    const [filteredDepartmentTests, setFilteredDepartmentTests] = useState([]);
    const [search, setSearch] = useState("");
    const [labsOptions, setLabsOptions] = useState([]);

    useEffect(() => {
        if (!search || search === " ") {
            getLabsNamesData();
        } else {
            const timeOut = setTimeout(() => {
                getLabsNamesData(search);
            }, 800);
            return () => clearTimeout(timeOut)
        }
    }, [search])

    const getLabsNamesData = async (labSearch = search) => {
        try {
            const res = await getLabNames(labSearch);
            if (res) {
                const options = res?.data?.map((item) => {
                    return {
                        label: item.name,
                        desc:
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Text>{item?.name}</Text>
                                <Text style={{ fontSize: 10 }}>{"+" + (item?.countryCode || "91") + "-" + item?.contact}</Text>
                            </div>
                        , value: item._id
                    }
                });
                setLabsOptions(options);
            }
        } catch (err) {
            console.log({ err });
        }
    }

    useEffect(() => {
        getMock();
        if (labID) {
            fetchDepartment();
        }
        if (departmentId && labID) {
            fetchDepartmentTests()
        }
    }, [labID, departmentId]);

    const getMock = () => {
        const tempMockData = [];
        for (let i = 0; i < 20; i++) {
            const data = {
                key: i.toString(),
                title: `content${i + 1}`,
            };
            tempMockData.push(data);
        }
        setMockData(tempMockData);
    };

    const handleCheckboxChange = (dir, key) => {
        setSelectedSourceKeys((prevKeys) =>
            prevKeys.includes(key) ? prevKeys.filter((k) => k !== key) : [...prevKeys, key]
        );
    };

    const handleSelectAll = (e) => {
        const checked = e.target.checked;
        setAllSelected(checked);
        setSelectedSourceKeys(checked ? mockData.map((item) => item.key) : []);
    };

    const handleSearch = (dir, value) => {
        setSearchTerm((prevSearch) => ({ ...prevSearch, [dir]: value }));
    };

    const filteredMockData = mockData.filter((item) =>
        item.title.toLowerCase().includes(searchTerm.source.toLowerCase())
    );
    const filteredDestinationData = mockData.filter(
        (item) => targetKeys.includes(item.key) && item.title.toLowerCase().includes(searchTerm.destination.toLowerCase())
    );

    const handleTransfer = () => {
        setTargetKeys((prevKeys) => [...prevKeys, ...selectedSourceKeys]);
        setSelectedSourceKeys([]); // Clear selected items
    };

    const handleDeleteItem = (key) => {
        setTargetKeys((prevKeys) => prevKeys.filter((k) => k !== key));
    };

    const fetchDepartment = async () => {
        axios
            .get(`/department/getAll?labID=${labID}`)
            .then((res) => {
                console.log({ res })
                setDepartmentList(res?.data);
                setDepartmentId(res?.data?._id)
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const fetchDepartmentTests = async () => {
        axios
            .get(`/department-tests-position/getTestsPosition?lab=${labID}&department=${departmentId}`)
            .then((res) => {
                setDepartmentTests(res?.data);
                setFilteredDepartmentTests(res?.data);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const departmentOptions = departmentList.map((item) => ({
        value: item._id,
        label: item.name,
    }));

    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                    borderRadius: '2px',
                    border: '1px solid rgba(217, 217, 217, 0.40)',
                    background: '#FFF',
                    width: '48%',
                    padding: '16px 8px 4px 8px',
                }}
            >
                <span
                    style={{ color: 'rgba(0, 0, 0, 0.75)', fontSize: '20px', fontWeight: 500, lineHeight: '16px' }}
                >
                    Select Source
                </span>
                <span
                    style={{ color: 'rgba(0, 0, 0, 0.88)', fontSize: '14px', fontWeight: 400 }}
                >
                    This is where your {text} is located
                </span>
                <div style={{ padding: '16px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <div>
                        Source Lab
                        <span style={{ color: '#FF4D4F', marginLeft: '5px' }}>*</span>
                    </div>
                    <Select
                        onSearch={(e) => setSearch(e)}
                        placeholder="Search by lab name or contact"
                        onSelect={(e) => setLabID(e)}
                        showSearch
                        suffixIcon={<SearchOutlined />}
                        options={labsOptions}
                        optionRender={(option) => option.data.desc}
                        filterOption={false}
                    />
                    <div>
                        Source Department
                        <span style={{ color: '#FF4D4F', marginLeft: '5px' }}>*</span>
                    </div>
                    <Select
                        value={""}
                        showSearch
                        filterOption={(input, option) =>
                            (option?.label ?? '')
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }
                        style={{ width: '100%' }}
                        options={departmentOptions?.map((item) => {
                            return {
                                value: item._id,
                                label: item.name,
                            };
                        })}
                        onChange={(value, text) => {
                            console.log("first")
                        }}
                    />

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: '2px',
                            border: '1px solid #F0F0F0',
                            background: '#FFF',
                            padding: '8px',
                        }}
                    >
                        <Source
                            mockData={filteredMockData}
                            selectedSourceKeys={selectedSourceKeys}
                            handleCheckboxChange={handleCheckboxChange}
                            allSelected={allSelected}
                            handleSelectAll={handleSelectAll}
                            handleSearch={handleSearch}
                        />
                    </div>

                </div>
                <Divider style={{ marginBottom: '0px', marginTop: '0px' }} />
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', padding: '10px 20px' }}>
                    <Button
                        style={{ border: '1px solid #4678EE', color: '#4678EE' }}
                        onClick={handleTransfer}
                    >
                        Create New
                    </Button>
                    <Button style={{ border: '1px solid #D9D9D9', color: 'rgba(0, 0, 0, 0.85)' }}>Replace</Button>
                </div>
            </div>
            <div>
                <RightSquareOutlined
                    style={{
                        fontSize: '25px',
                        backgroundColor: '#F5F5F5',
                        borderRadius: '4px',
                        color: '#D9D9D9',
                    }} />
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                    borderRadius: '2px',
                    border: '1px solid rgba(217, 217, 217, 0.40)',
                    background: '#FFF',
                    width: '48%',
                    padding: '16px 8px 4px 8px',
                }}
            >
                <span
                    style={{ color: 'rgba(0, 0, 0, 0.75)', fontSize: '20px', fontWeight: 500, lineHeight: '16px' }}
                >
                    Select Destination
                </span>
                <span
                    style={{ color: 'rgba(0, 0, 0, 0.88)', fontSize: '14px', fontWeight: 400 }}
                >
                    This is where your {text} will be sent to.
                </span>
                <div style={{ padding: '16px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <div>
                        Destination  Lab
                        <span style={{ color: '#FF4D4F', marginLeft: '5px' }}>*</span>
                    </div>
                    <Select
                        onSearch={(e) => setSearch(e)}
                        placeholder="Search by lab name or contact"
                        onSelect={(e) => setLabID(e)}
                        showSearch
                        suffixIcon={<SearchOutlined />}
                        options={labsOptions}
                        optionRender={(option) => option.data.desc}
                        filterOption={false}
                    />
                    <div>
                        Transferred test
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: '2px',
                            border: '1px solid #F0F0F0',
                            background: '#FFF',
                            padding: '8px 12px'
                        }}
                    >
                        <Destination
                            mockData={filteredDestinationData}
                            handleDeleteItem={handleDeleteItem}
                            handleSearch={handleSearch}
                        />
                    </div>
                </div>
                <Divider style={{ marginBottom: '0px', marginTop: '0px' }} />
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', padding: '10px 20px' }}>
                    <Button type='primary'>Confirm</Button>
                </div>
            </div>
        </div >
    )
}

export default TransferComp;