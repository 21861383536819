import React, { useEffect, useMemo, useState } from "react";
import Header from "../Base/Header";
import { Flex } from "antd";
import DateRangePicker from "../Common/DateRangePicker";
import Stats from "./Stats";
import TransactionTable from "./TransactionTable";
import dayjs from "dayjs";
import axios from "axios";

const Transaction = () => {
  const [dateRange, setDateRange] = useState([
    dayjs(new Date().setHours(0, 0, 0)),
    dayjs(new Date().setHours(23, 59, 59)),
  ]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState({
    collectedAmount: 0,
    renew: 0,
    new: 0,
    others: 0,
  });

  useEffect(() => {
    handleGetLabLinks();
  }, [dateRange]);

  const handleGetLabLinks = () => {
    setLoading(true);
    axios
      .get(
        `/admin-dashboard/transactionDetails?gte=${dateRange[0]}&lte=${dateRange[1]}`,
      )
      .then((res) => {
        setData(res.data);
        setStats({
          collectedAmount: res.data.reduce((acc, item) => acc + item.amount, 0),
          renew: res.data
            .filter((item) => item.type === "Renew")
            .reduce((acc, item) => acc + item.amount, 0),
          new: res.data
            .filter((item) => item.type === "New")
            .reduce((acc, item) => acc + item.amount, 0),
          others: res.data
            .filter((item) => item.type === "Others")
            .reduce((acc, item) => acc + item.amount, 0),
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const tableData = useMemo(() => {
    return data.map((item, index) => {
      return {
        key: index,
        ...item,
      };
    });
  }, [data]);

  return (
    <>
      <Header />
      <Flex vertical gap={20} style={{ margin: " 40px 20px 40px 105px" }}>
        <DateRangePicker dataRange={dateRange} onDateChange={setDateRange} />
        <Stats stats={stats} />
        <TransactionTable tableData={tableData} loading={loading} />
      </Flex>
    </>
  );
};

export default Transaction;
