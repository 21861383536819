import React, { useEffect, useState } from "react";
import styles from "./Blogs.module.css";
import axios from "axios";
import {
  Input,
  Select,
  Table,
  Space,
  Button,
  message,
  Popconfirm,
  DatePicker,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { connect } from "react-redux";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

const { RangePicker } = DatePicker;

const BlogPost = ({ authToken }) => {
  const [loading, setLoading] = useState(false);
  const [pageState, setPageState] = useState(1);
  const [blogsData, setBlogsData] = useState([]);
  const [filteredBlogData, setFilteredBlogData] = useState([]);
  const [currentBlogsData, setCurrentBlogsData] = useState([]);
  const [blogCategories, setBlogCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalBlogs, setTotalBlogs] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const pageOffset = 9;
  const currentDate = dayjs();
  const firstDayOfMonth = dayjs(currentDate)
    .startOf("month")
    .set("hour", 0)
    .set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0);
  const lastDayOfMonth = dayjs(currentDate)
    .set("hour", 23)
    .set("minute", 59)
    .set("second", 59)
    .set("millisecond", 999);

  const [dateRange, setDateRange] = useState([firstDayOfMonth, lastDayOfMonth]);

  const dateFilter = async (date, dateString) => {
    const localDate = [
      dayjs(new Date(dateString[0]).setHours(0, 0, 0)),
      dayjs(new Date(dateString[1]).setHours(23, 59, 59)),
    ];
    setDateRange(localDate);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    fetchBlogsdata();
  }, [dateRange]);
  console.log("blogs data", currentBlogsData);

  useEffect(() => {
    let filteredData = currentBlogsData;

    if (selectedPlan !== null) {
      filteredData = filteredData.filter(
        (item) => getCategoryofBlog(item.category) === selectedPlan,
      );
    }

    if (searchQuery) {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      filteredData = filteredData.filter((item) =>
        item.title.toLowerCase().includes(lowerCaseSearchQuery),
      );
    }

    setFilteredBlogData(filteredData);
  }, [selectedPlan, searchQuery, currentBlogsData]);

  // TABLE COLUMNS
  const columns = [
    {
      title: "Sr No.",
      dataIndex: "srNo",
      key: "srNo",
      width: "9%",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "40%",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      render: (text) => {
        if (text)
          return new Date(text)
            .toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
            .replace(/\//g, "-");
        return null;
      },
    },
    {
      title: "Leads",
      dataIndex: "contactUsCount",
      key: "contactUsCount",
      render: (text) => {
        return text ? text : 0;
      },
      sorter: (a, b) => a.contactUsCount - b.contactUsCount,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Space size="small">
            <Link to={`/blogs/${record.slug}`}>
              <Button
                size="small"
                style={{
                  border: "1px solid #4678EE",
                  fontWeight: "500",
                  color: "#4678EE",
                }}
              >
                <EditOutlined />
                Edit
              </Button>
            </Link>
            <Popconfirm
              description="Are you sure to delete this task?"
              onConfirm={() => handleBlogPostDelete(record.key)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                danger
                size="small"
                style={{
                  fontWeight: "500",
                }}
              >
                <DeleteOutlined />
                Delete
              </Button>
            </Popconfirm>
          </Space>
        );
      },
      width: "12%",
    },
  ];

  const fetchBlogsdata = async (page = 1) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/blog-post/getAllAdminBlogs?lte=${dateRange[1]}&gte=${dateRange[0]}`,
        {
          headers: {
            Authorization: `Custom ${authToken}`,
          },
        },
      );
      console.log("res", res.data);
      setBlogsData(res?.data);
      setCurrentBlogsData(res?.data);
      setTotalBlogs(res?.data.count);
      setLoading(false);
    } catch (error) {
      message.error(error);
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const { data } = await axios.get(`/blog-category/all`);
      setBlogCategories(data);
    } catch (error) {
      console.log(error);
      message.error(error);
    }
  };

  const handleBlogPostDelete = async (id) => {
    try {
      const res = await axios.delete(`blog-post/delete/${id}`);
      if (res.status === 200) {
        message.success("Blog Deleted Successfully!");
      } else {
        message.error("Something went wrong!");
      }
      setTimeout(() => {
        fetchBlogsdata();
      }, "500");
    } catch (error) {
      console.log(error);
    }
  };

  const searchHandler = async (e) => {
    try {
      setLoading(true);
      const result = blogsData?.filter((item) => {
        return item.title.toLowerCase().includes(searchQuery.toLowerCase());
      });
      setCurrentBlogsData(result);
      setTotalBlogs(result.length);
      setLoading(false);
    } catch (error) {
      console.log(error);
      message.error(error);
    }
  };

  const getCategoryofBlog = (id) => {
    const blog = blogCategories?.find((category) => category._id === id);
    return blog?.title;
  };

  const blogsTableData = filteredBlogData.map((blog, ind) => {
    return {
      key: blog._id,
      srNo: ind + 1 + (pageState - 1) * pageOffset,
      title: blog.title,
      category: getCategoryofBlog(blog.category),
      date: blog.createdAt,
      slug: blog.slug,
      contactUsCount: blog.contactUsCount,
    };
  });

  const allCategories = blogCategories.map((category) => {
    return {
      id: category._id,
      value: category.title,
      label: category.title,
      slug: category.slug,
    };
  });

  const filterByCategoryHandler = async (val, page = 1) => {
    const findCategory = allCategories?.find((cat) => cat.value === val);
    const slug = findCategory?.slug;
    try {
      const url = `/blog-post/posts-catslug?slug=${slug}&page=${page}`;
      const res = await axios.get(url);
      setCurrentBlogsData(res?.data?.response);
      setTotalBlogs(res?.data?.count);
    } catch (error) {
      message.error(error);
    }
  };

  const categoryHandler = async (val) => {
    setSelectedPlan(val);
    if (!val) await fetchBlogsdata();
    else await filterByCategoryHandler(val, 1);
    setPageState(1);
  };

  const exportData = filteredBlogData?.map((data, index) => {
    return {
      "Sr No": index + 1,
      "Title": data?.title,
      "Category": getCategoryofBlog(data?.category),
      "Date&Time": `${dayjs(data?.createdAt,).format("DD-MM-YYYY")}`,
      "Leads": data?.contactUsCount,
    };
  });

  const exportToCSV = (csvData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      <div className={styles["blog-header-container"]}>
        <div className={styles["blog-search-and-select"]}>
          <div>
            <Input.Search
              allowClear={true}
              placeholder="Search by Blog Title"
              style={{
                width: 350,
              }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={searchHandler}
            />
          </div>
          <div>
            <Select
              allowClear={true}
              popupClassName="popup__menu"
              style={{ width: 200 }}
              options={allCategories}
              placeholder="Select Category"
              value={selectedPlan}
              onChange={(value) => setSelectedPlan(value)}
            />
          </div>
          <div>
            <RangePicker
              style={{ width: 240, marginLeft: 20 }}
              value={dateRange}
              onChange={dateFilter}
              allowClear={false}
              format="YYYY-MM-DD"
              presets={[
                {
                  label: "Today",
                  value: [dayjs().startOf("day"), dayjs().endOf("day")],
                },
                {
                  label: "Last 5 days",
                  value: [dayjs().add(-5, "d"), dayjs()],
                },
                {
                  label: "This Week",
                  value: [dayjs().startOf("week"), dayjs().endOf("week")],
                },
                {
                  label: "This Month",
                  value: [dayjs().startOf("month"), dayjs().endOf("month")],
                },
                {
                  label: "Last 30 Days",
                  value: [dayjs().add(-30, "d"), dayjs()],
                },
              ]}
            />
          </div>
          <div>
            <Button
              popupClassName="popup__menu"
              onClick={() =>
                exportToCSV(exportData, "Blog Post")
              }
              style={{ marginLeft: 20 }}
            >
              Export
            </Button>
          </div>
        </div>
        <Link to="/new-blog">
          <Button type="primary">
            <PlusOutlined />
            Add Blog
          </Button>
        </Link>
      </div>

      <div className={styles["blog-table-container"]}>
        <Table
          dataSource={blogsTableData}
          columns={columns}
          loading={loading}
          // pagination={{
          //   showQuickJumper: false,
          //   showSizeChanger: false,
          //   current: pageState,
          //   defaultCurrent: pageState,
          //   defaultPageSize: 9,
          //   total: totalBlogs,
          //   onChange: paginationChange,
          // }}
          pagination={false}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token,
  };
};

export default connect(mapStateToProps)(BlogPost);
