import { Button, Input } from "antd";
import React, { useState } from "react";
import Header from "../Base/Header";
import axios from "axios";
import { connect } from "react-redux";

const ManualPayment = ({ authToken }) => {
  const [obj, setObj] = useState({
    labId: "",
    paidAmount: "",
    month: "",
    planId: "",
  });
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const submit = async () => {
    setLoading(true);
    const request = {
      labId: obj.labId,
      paidAmount: Number(obj.paidAmount),
      month: Number(obj.month),
      plan: obj.planId,
    };
    const response = await axios.post("/lab-payment/manualPayment", request, {
      headers: {
        Authorization: `Custom ${authToken}`,
      },
    });
    setLoading(false);
    console.log(response);
    setUrl(response?.data?.link);
  };

  return (
    <div>
      <Header />
      <div
        style={{
          margin: "40px 40px 40px 105px",
        }}
      >
        <h1>Manual Payment</h1>
        <Input
          placeholder="Lab ID"
          style={{
            margin: "10px 0",
          }}
          onChange={(e) => setObj({ ...obj, labId: e.target.value })}
        />
        <Input
          placeholder="Paid Amount"
          style={{
            margin: "10px 0",
          }}
          onChange={(e) => setObj({ ...obj, paidAmount: e.target.value })}
        />
        <Input
          placeholder="Month"
          style={{
            margin: "10px 0",
          }}
          onChange={(e) => setObj({ ...obj, month: e.target.value })}
        />
        <Input
          placeholder="Plan ID"
          style={{
            margin: "10px 0",
          }}
          onChange={(e) => setObj({ ...obj, planId: e.target.value })}
        />
        <Button type="primary" onClick={submit} loading={loading}>
          Submit
        </Button>
        <div
          style={{
            margin: "20px 0",
          }}
        >
          {url}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token,
  };
};

export default connect(mapStateToProps)(ManualPayment);
