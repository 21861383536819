// apiService.js
import { StarFilled } from "@ant-design/icons";
import axios from "axios";
import dayjs from "dayjs";

function getTimeDifference(timestamp1, timestamp2) {
  // Parse the timestamps into Date objects
  const date1 = new Date(timestamp1);
  const date2 = new Date(timestamp2);

  // Calculate the difference in milliseconds
  const diffInMs = date2.getTime() - date1.getTime();

  // Convert milliseconds to seconds
  const diffInSeconds = diffInMs / 1000;

  // Determine the unit and calculate the difference
  if (diffInSeconds < 3600) {
    // Less than an hour, return in minutes
    const diffInMinutes = Math.round(diffInSeconds / 60);
    return `${diffInMinutes} minutes`;
  } else if (diffInSeconds < 86400) {
    // Less than a day, return in hours
    const diffInHours = Math.round(diffInSeconds / 3600);
    return `${diffInHours} hours`;
  } else {
    // More than a day, return in days
    const diffInDays = Math.round(diffInSeconds / 86400);
    return `${diffInDays} days`;
  }
}

// Login to the lab
export const loginToLab = async (data) => {
  try {
    const res = await axios.get(
      `/admin-dashboard/superAdminLogin?contact=${data}`,
    );

    if (res?.data?.token) {
      const environment =
        process.env.REACT_APP_ENVIRONMENT === "production"
          ? "https://labs.flabslis.com"
          : "https://labstaging.flabs.in";

      window.open(
        `${environment}/superAdminLogin/${res.data.token}/${res.data.browserCode}`,
        "_blank",
      );
    }
  } catch (error) {
    console.error("Error logging in:", error);
  }
};

export const createTicket = async ({ lab, supportPerson, query, urgent }) => {
  if (!lab || !supportPerson || !query) {
    return { err: "All fields are required" };
  }
  const res = await axios.post("supportTicket/admin-create", {
    lab,
    supportPerson,
    query,
    urgent,
  });
  return res;
};

// Get ticket data
export const getTicketData = async (
  setData,
  setLoading,
  status,
  gte,
  lte,
  selectedSupportPerson,
  selectedSalesPerson,
) => {
  setLoading(true);
  axios
    .get(
      `supportTicket/admin-getAllTickets?status=${status}&lte=${lte}&gte=${gte}&supportPerson=${selectedSupportPerson}&salesPerson=${selectedSalesPerson}`,
    )
    .then((res) => {
      const data = res.data.map((ticket) => {
        return {
          ...ticket,
          key: ticket._id,
          ticketID: ticket.ticketID,
          labDetails: {
            labName: ticket?.lab?.name,
            labPhone: `${
              ticket.countryCode.startsWith("+")
                ? ticket.countryCode
                : `+${ticket.countryCode}`
            }-${ticket?.lab?.labAdminID?.contact}`,
            contact: ticket.contact,
            ownerName: ticket?.lab?.labAdminID?.name,
            tag: ticket?.lab?.plan?.name,
          },
          query: ticket.query,
          requestedAt: ticket.createdAt,
          supportPerson:
            ticket.supportPerson === null ? "-" : ticket.supportPerson,
          salesPerson: ticket.salesPerson === null ? "-" : ticket.salesPerson,
          tags: ["nice", "developer"],
          action: {
            contact: ticket.contact,
            id: ticket._id,
            labAdminContact: ticket?.lab?.labAdminID?.contact,
          },
          closedAt: ticket.closeDate,
          tatRatingText: `${getTimeDifference(
            ticket.createdAt,
            ticket.closeDate,
          )} & ${ticket.rating}/5`,
          tatRating: (
            <p>
              {getTimeDifference(ticket.createdAt, ticket.closeDate)}
              <br />& <br />
              <StarFilled style={{ color: "#FFD700", marginRight: "5px" }} />
              {ticket.rating}/5
            </p>
          ),
          rating: ticket.rating,
          language: ticket?.language,
          status: ticket?.status,
          remarks: ticket?.remarks,
          urgent: ticket?.urgent,
        };
      });
      setData(data);
      setLoading(false);
    });
};

// Delete ticket
export const deleteTicket = async (id, setIsChanged) => {
  try {
    await axios.get(`/supportTicket/admin-deleteTicket?id=${id}`);
    setIsChanged((prev) => !prev);
  } catch (error) {
    console.error("Error deleting ticket:", error);
  } finally {
    // setLoading(false);
  }
};

//Close ticket
export const closeTicket = async (
  id,
  userId,
  setIsChanged,
  setIsStatusChanged,
) => {
  axios
    .get(`/supportTicket/admin-closeTicket?id=${id}&closedBy=${userId}`)
    .then((res) => {
      setIsChanged((prev) => !prev);
      setIsStatusChanged((prev) => !prev);
    });
};

// Get support person
export const getSupportPerson = async (setData) => {
  try {
    const response = await axios.get("/admin-dashboard/supportPerson");
    const options = response?.data?.map((name) => ({
      value: name,
      label: name,
    }));

    setData(options);
  } catch (error) {
    console.error("Error fetching plan data:", error);
  }
};

// Get sales person
export const getSalesPerson = async (setData) => {
  try {
    const response = await axios.get("/admin-dashboard/leadOwner");

    const options = response?.data?.map((plan) => ({
      value: plan,
      label: plan,
    }));

    setData(options);
  } catch (error) {
    console.error("Error fetching plan data:", error);
  }
};

// Get plans
export const getPlans = async (setData) => {
  try {
    const response = await axios.get("/admin-dashboard/plans");
    const options = response?.data?.map((plan) => ({
      value: plan?.name,
      label: plan?.name,
    }));

    setData(options);
  } catch (error) {
    console.error("Error fetching plan data:", error);
  }
};

export const getLabNames = async (search) => {
  try {
    const response = await axios.get(
      `/admin-dashboard/labSearch?search=${search}`,
    );
    return response;
  } catch (error) {
    console.error("Error fetching plan data:", error);
  }
};

// Update Sales Person
export const updateSalesPerson = async (data, setLoading, setIsChanged) => {
  setLoading(true);
  try {
    await axios.post("/supportTicket/update", data);
    setIsChanged((prev) => !prev);
  } catch (error) {
    console.error("Error updating sales person:", error);
  } finally {
    setLoading(false);
  }
};

// Update Support Person
export const updateSupportPerson = async (
  data,
  setLoading,
  setIsChanged,
  setIsStatusChanged,
) => {
  setLoading(true);
  try {
    await axios.post("/supportTicket/update", data);
    setIsChanged((prev) => !prev);
    if (
      Object.keys(data.data).includes("urgent") ||
      Object.keys(data.data).includes("status")
    ) {
      setIsStatusChanged((prev) => prev + 1);
    }
  } catch (error) {
    console.error("Error updating support person:", error);
  } finally {
    setLoading(false);
  }
};

// Generate download data
export const generateDownloadData = (data) => {
  const downloadData = data.map((ticket) => {
    return {
      "Ticket ID": ticket.ticketID,
      "Lab Name": ticket?.labDetails?.labName,
      "Lab Phone": ticket?.labDetails?.labPhone,
      "Owner Name": ticket?.labDetails?.ownerName,
      "Plan Name": ticket?.labDetails?.tag,
      Query: ticket.query,
      "Requested At": dayjs(ticket.requestedAt).format("DD-MM-YYYY hh:mm A"),
      "Support Person": ticket.supportPerson,
      "Sales Person": ticket.salesPerson,
      Status: ticket.status,
      Language: ticket.language,
    };
  });
  return downloadData;
};

// Generate download data for closed tickets
export const generateDownloadDataClosed = (data) => {
  const downloadData = data.map((ticket) => ({
    "Ticket ID": ticket.ticketID,
    "Lab Name": ticket?.labDetails?.labName,
    "Lab Phone": ticket?.labDetails?.labPhone,
    "Owner Name": ticket?.labDetails?.ownerName,
    "Plan Name": ticket?.labDetails?.tag,
    Query: ticket.query,
    "Requested At": dayjs(ticket.requestedAt).format("DD-MM-YYYY hh:mm A"),
    "Closed At": dayjs(ticket.closedAt).format("DD-MM-YYYY hh:mm A"),
    "TAT Rating": `${getTimeDifference(
      ticket.requestedAt,
      ticket.closedAt,
    )} & ${ticket.rating ?? "-"}`,
    "Support Person":
      ticket.supportPerson === null ? "-" : ticket.supportPerson,
    "Sales Person": ticket.salesPerson === null ? "-" : ticket.salesPerson,
    // "Closed By": ticket.closedBy?.name,
    // "Lab Created Date": ticket?.labDetails?.createdAt
    //   ? dayjs(ticket?.labDetails?.createdAt).format("DD-MM-YYYY hh:mm A")
    //   : "",
    // "Lab Last Payment": ticket?.labDetails?.labLastPayment
    //   ? dayjs(ticket?.labDetails?.labLastPayment).format("DD-MM-YYYY hh:mm A")
    //   : "",
  }));
  return downloadData;
};

// Generate download data for leaderboard
export const generateDownloadDataLeaderBoard = (data) => {
  const downloadData = data.map((ticket) => ({
    Rank: ticket.rank,
    "Support Person": ticket.supportPerson,
    Pending: ticket.totalPending,
    "In Progress": ticket.totalInProgress,
    "Bug/Requirement": ticket.totalBug,
    Closed: ticket.totalClosed,
  }));
  return downloadData;
};

// Get ticket status count
export const getLeaderBoard = async (gte, lte, setData, setLoading) => {
  setLoading(true);
  try {
    const res = await axios.get(
      `/supportTicket/getLeaderBoard?lte=${lte}&gte=${gte}`,
    );
    if (res.data) {
      setData(
        res.data.map((item, index) => ({
          ...item,
          rank: index + 1,
        })),
      );
    }
  } catch (error) {
    console.error("Error updating support person:", error);
  } finally {
    setLoading(false);
  }
};

export const getLanguage = async (setData) => {
  try {
    const res = await axios.get("/admin-dashboard/getLanguages");
    setData(
      res?.data?.map((language) => {
        return {
          value: language,
          label: language,
        };
      }),
    );
  } catch (error) {
    console.error("Error updating support person:", error);
  }
};

// Update Support Person
export const getAllTicketStatusCount = async (setData, setIsStatusChanged) => {
  try {
    const res = await axios.get("/supportTicket/getAllTicketStatusCount");
    setData(res.data[0]);
    setIsStatusChanged((prev) => !prev);
  } catch (error) {
    console.error("Error updating support person:", error);
  }
};
