import React, { useEffect, useState } from "react";
import Header from "../Base/Header";
import styles from "./Subscription.module.css";
import dayjs from "dayjs";
import { Button, Card } from "antd";
import { Input, Select, DatePicker, Table, Tag } from "antd";
import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

const { Search } = Input;
const { RangePicker } = DatePicker;

const Subscription = ({ authToken }) => {
  const [dateRange, setDateRange] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [churnData, setChurnData] = useState([]);
  const [currentChurnData, setCurrentChurnData] = useState([]);
  const [signups, setSignups] = useState(0);
  const [statusCount, setStatusCount] = useState({
    churn: 0,
    renewed: 0,
    new: 0,
  });

  useEffect(() => {
    if (searchQuery.length === 0) fetchLabsByDate();
  }, [searchQuery]);

  useEffect(() => {
    fetchLabsByDate();
    setSearchQuery("");
  }, [dateRange]);

  const fetchLabsByDate = async () => {
    try {
      setLoading(true);
      const response = await axios.get("admin-dashboard/getChurnData", {
        params: {
          ...(dateRange.length === 2 && {
            lte: dateRange[1],
            gte: dateRange[0],
          }),
        },
        headers: {
          Authorization: `Custom ${authToken}`,
        },
      });
      const result = await response.data;
      setChurnData(result?.data);
      setCurrentChurnData(result?.data);
      setSignups(result?.signUp);

      const counts = {};
      result?.data.forEach((item) => {
        const currStatus = item.status.toLowerCase();
        if (counts[currStatus]) {
          counts[currStatus] += 1;
        } else {
          counts[currStatus] = 1;
        }
      });

      setStatusCount((prevCounts) => {
        return {
          ...prevCounts,
          ...counts,
        };
      });

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const convertDate = (date) => {
    const newDate = new Date(date);
    return newDate
      .toISOString()
      .replace(/T.*/, "")
      .split("-")
      .reverse()
      .join("-");
  };

  const columns = [
    {
      title: "Sr No.",
      dataIndex: "key",
      width: 80,
    },
    {
      title: "Lab Name",
      dataIndex: "labName",
      width: 240,
    },
    {
      title: "Contact",
      dataIndex: "contact",
      width: 160,
    },
    {
      title: "Last Subscription",
      dataIndex: "lastSubscription",
      sorter: (a, b) => {
        const dateA =
          a.lastSubscription === "NA"
            ? null
            : moment(
                a.lastSubscription.match(/\d{2}-\d{2}-\d{4}/)?.[0],
                "DD-MM-YYYY",
              );
        const dateB =
          b.lastSubscription === "NA"
            ? null
            : moment(
                b.lastSubscription.match(/\d{2}-\d{2}-\d{4}/)?.[0],
                "DD-MM-YYYY",
              );

        if (dateA === null && dateB === null) {
          return 0;
        } else if (dateA === null) {
          return 1;
        } else if (dateB === null) {
          return -1;
        }

        return dateA - dateB;
      },
      width: 200,
    },
    {
      title: "New Subscription",
      dataIndex: "newSubscription",
      sorter: (a, b) => {
        const dateA =
          a.newSubscription === "NA"
            ? null
            : moment(
                a.newSubscription.match(/\d{2}-\d{2}-\d{4}/)?.[0],
                "DD-MM-YYYY",
              );
        const dateB =
          b.newSubscription === "NA"
            ? null
            : moment(
                b.newSubscription.match(/\d{2}-\d{2}-\d{4}/)?.[0],
                "DD-MM-YYYY",
              );

        if (dateA === null && dateB === null) {
          return 0;
        } else if (dateA === null) {
          return 1;
        } else if (dateB === null) {
          return -1;
        }

        return dateA - dateB;
      },
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 80,
      align: "center",
      render: (_, { status }) => {
        let color;
        if (status.toLowerCase() === "churn") color = "red";
        else if (status.toLowerCase() === "renewed") color = "gold";
        else if (status.toLowerCase() === "new") color = "cyan";
        else color = "volcano";

        return (
          <Tag color={color} key={status}>
            {status}
          </Tag>
        );
      },
    },
  ];

  const churnTableData = currentChurnData?.map((item, i) => {
    return {
      key: i + 1,
      labName: item?.name,
      contact: item?.contact,
      lastSubscription:
        item.old_sub !== null
          ? item.old_sub?.plan.name +
            " Plan\n" +
            item.old_sub.months +
            " months\n" +
            convertDate(item.old_sub?.endDate)
          : "NA",
      newSubscription:
        item.new_sub !== null
          ? item.new_sub?.plan.name +
            " Plan\n" +
            item.new_sub.months +
            " months\n" +
            convertDate(item.new_sub?.endDate)
          : "NA",
      status: item.status,
    };
  });

  const handleSearch = (e) => {
    try {
      setSearchQuery(e.target.value);

      setLoading(true);
      const result = currentChurnData?.filter((item) => {
        return (
          item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.contact.toString().includes(searchQuery)
        );
      });
      setCurrentChurnData(result);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatus = (val) => {
    setStatus(val);
    try {
      setLoading(true);
      if (!val) {
        setCurrentChurnData(churnData);
      } else {
        const result = churnData?.filter(
          (item) => item.status?.toLowerCase() === val,
        );
        setCurrentChurnData(result);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const dateFilter = async (date, dateString) => {
    const localDate = [
      dayjs(new Date(dateString[0]).setHours(0, 0, 0)),
      dayjs(new Date(dateString[1]).setHours(23, 59, 59)),
    ];
    setDateRange(localDate);
    console.log(date, dateString);
  };

  const exportData = churnTableData?.map((item) => {
    return {
      "Sr No.": item.key,
      "Lab Name": item.labName,
      Contact: item.contact,
      "Last Subscription": item.lastSubscription,
      "New Subscription": item.newSubscription,
      Status: item.status,
    };
  });

  const exportToCSV = (csvData, fileName) => {
    console.log(csvData);
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const subscriptionLabsData = [
    {
      name: "Sign-ups",
      quantity: signups,
      color: "#2C89E5",
    },
    {
      name: "New Labs",
      quantity: statusCount.new,
      color: "#13C2C2",
    },
    {
      name: "Renewed Labs",
      quantity: statusCount.renewed,
      color: "#FA8C16",
    },
    {
      name: "Churn Labs",
      quantity: statusCount.churn,
      color: "#F5222D",
    },
  ];

  const statusOptions = [
    {
      value: "churn",
      label: "Churn",
    },
    {
      value: "renewed",
      label: "Renewed",
    },
    {
      value: "new",
      label: "New",
    },
  ];

  return (
    <>
      <Header />
      <section className={styles["subscription-section-container"]}>
        <div className={styles["subscription-section-date"]}>
          <span>
            <RangePicker
              value={dateRange}
              onChange={dateFilter}
              allowClear={false}
              format="YYYY-MM-DD"
              presets={[
                {
                  label: "Today",
                  value: [dayjs().startOf("day"), dayjs().endOf("day")],
                },
                {
                  label: "Last 5 days",
                  value: [dayjs().add(-5, "d"), dayjs()],
                },
                {
                  label: "This Week",
                  value: [dayjs().startOf("week"), dayjs().endOf("week")],
                },
                {
                  label: "This Month",
                  value: [dayjs().startOf("month"), dayjs().endOf("month")],
                },
                {
                  label: "Last 30 Days",
                  value: [dayjs().add(-30, "d"), dayjs()],
                },
              ]}
            />
          </span>
        </div>

        <div className={styles["subscription-data"]}>
          {subscriptionLabsData.map((item, i) => (
            <Card
              className={styles["subscription-data-card"]}
              style={{ border: "1px solid #dadada" }}
              key={i}
            >
              <p>{item.name}</p>
              <span
                style={{
                  color: `${item.color}`,
                  fontSize: "40px",
                  fontWeight: "600",
                }}
              >
                {item.quantity}
              </span>
            </Card>
          ))}
        </div>

        <div className={styles["subscription-table-container"]}>
          <div className={styles["subscription-table-header"]}>
            <Search
              allowClear={true}
              placeholder="Search by Lab Name or Phone Number"
              style={{
                width: 400,
              }}
              value={searchQuery}
              onChange={handleSearch}
            />
            <span>
              <span
                style={{
                  marginRight: "10px",
                }}
              >
                <Button onClick={() => exportToCSV(exportData, "Churn Data")}>
                  Export
                </Button>
              </span>
              <Select
                allowClear={true}
                placeholder="Status"
                optionFilterProp="children"
                style={{ width: 200 }}
                onChange={handleStatus}
                options={statusOptions}
              />
            </span>
          </div>

          <Table
            style={{ whiteSpace: "pre" }}
            columns={columns}
            dataSource={churnTableData}
            loading={loading}
            pagination={false}
          />
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token,
  };
};

export default connect(mapStateToProps)(Subscription);
